<div class="tip-wrapper width-215">
    <clr-tooltip *ngIf="!isNone">
        <div clrTooltipTrigger class="level-border">
            <div  [className]="getClass()">
                <div class="inner">
                    {{vulnerabilitySummary?.severity | slice:0:1}}
                </div>
            </div>
        </div>
        <clr-tooltip-content [clrPosition]="'left'" [clrSize]="'md'" *clrIfOpen>
            <span class="font-weight-600">{{'SCANNER.VULNERABILITY_SEVERITY' | translate }}</span>
            <span class="font-weight-600 margin-left-5">{{tipTitle}}</span>
        </clr-tooltip-content>
    </clr-tooltip>
    <clr-tooltip>
        <div clrTooltipTrigger class="tip-block">
            <div *ngIf="!isNone" class="circle-block">
                <div class="black-point-container  margin-left-10"></div>
                <span class="margin-left-10 font-weight-800 font-size-14">{{total}}</span>
                <span class="margin-left-5 font-size-14">{{'SCANNER.TOTAL' | translate}}</span>
                <div class="black-point-container  margin-left-10">-</div>
                <span class="margin-left-10 font-weight-800 font-size-12">{{fixableCount}}</span>
                <span class="margin-left-5 font-size-12">{{'SCANNER.FIXABLE' | translate}}</span>
            </div>
            <div *ngIf="isNone" class="pl-1 margin-left-5 tip-wrapper bar-block-none shadow-none width-150">{{'VULNERABILITY.NO_VULNERABILITY' | translate }}</div>
        </div>
        <clr-tooltip-content class="w-800" [clrPosition]="'right'" [clrSize]="'lg'" *clrIfOpen>
            <div class="bar-tooltip-font-larger">
                <div *ngIf="!isNone" class="level-border clr-display-inline-block margin-right-5">
                    <div  [className]="getClass()">
                        <div class="inner" [ngClass]="{'is-white': !isThemeLight(), 'is-dark': isThemeLight()}">
                            {{vulnerabilitySummary?.severity | slice:0:1}}
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="isCritical">
                    <span>{{'VULNERABILITY.OVERALL_SEVERITY' | translate }} <span class="font-weight-600">{{'VULNERABILITY.SEVERITY.CRITICAL' | translate | titlecase }}</span></span>
                </ng-container>
                <ng-container *ngIf="isHigh">
                    <span>{{'VULNERABILITY.OVERALL_SEVERITY' | translate }} <span class="font-weight-600">{{'VULNERABILITY.SEVERITY.HIGH' | translate | titlecase }}</span></span>
                </ng-container>
                <ng-container *ngIf="isMedium">
                    <span>{{'VULNERABILITY.OVERALL_SEVERITY' | translate }} <span class="font-weight-600">{{'VULNERABILITY.SEVERITY.MEDIUM' | translate | titlecase}}</span></span>
                </ng-container>
                <ng-container *ngIf="isLow">
                    <span>{{'VULNERABILITY.OVERALL_SEVERITY' | translate }} <span class="font-weight-600">{{'VULNERABILITY.SEVERITY.LOW' | translate | titlecase }}</span></span>
                </ng-container>
                <ng-container *ngIf="isUnknown">
                    <span>{{'VULNERABILITY.OVERALL_SEVERITY' | translate }} <span class="font-weight-600">{{'VULNERABILITY.SEVERITY.UNKNOWN' | translate | titlecase }}</span></span>
                </ng-container>
                <ng-container *ngIf="isNegligible">
                    <span>{{'VULNERABILITY.OVERALL_SEVERITY' | translate }} <span class="font-weight-600">{{'VULNERABILITY.SEVERITY.NEGLIGIBLE' | translate | titlecase }}</span></span>
                </ng-container>
                <ng-container *ngIf="isNone">
                    <clr-icon shape="check-circle" class="is-success" size="32"></clr-icon>
                    <span>{{'VULNERABILITY.NO_VULNERABILITY' | translate }}</span>
                </ng-container>
            </div>
            <hr>
            <div class="bar-summary bar-tooltip-fon" *ngIf="!isNone">
                <histogram-chart [isWhiteBackground]="!isThemeLight()" [metadata]="passMetadataToChart()"></histogram-chart>
            </div>
            <div *ngIf="scanner">
                <span class="bar-scanning-time">{{'SCANNER.SCANNED_BY' | translate }}</span>
                <span class="margin-left-5">{{scanner?.name}}</span>
            </div>
            <div>
                <span class="bar-scanning-time">{{'SCANNER.DURATION' | translate }}</span>
                <span class="margin-left-5">{{duration()}}</span>
            </div>
            <div>
                <span class="bar-scanning-time">{{'VULNERABILITY.CHART.SCANNING_TIME' | translate}} </span>
                <span>{{completeTimestamp | date:'short'}}</span>
            </div>
        </clr-tooltip-content>
    </clr-tooltip>
    <clr-tooltip class="margin-left-5" *ngIf="isLimitedSuccess()">
        <div clrTooltipTrigger>
            <clr-icon shape="exclamation-triangle" size="20" class="is-warning"></clr-icon>
        </div>
        <clr-tooltip-content  [clrSize]="'lg'" *clrIfOpen>
            <div class="font-weight-600">
                <span class="bar-scanning-time">{{'VULNERABILITY.CHART.SCANNING_PERCENT' | translate}} </span>
                <span>{{completePercent}}</span>
            </div>
            <div>
                <span>{{'VULNERABILITY.CHART.SCANNING_PERCENT_EXPLAIN' | translate}}</span>
            </div>
        </clr-tooltip-content>
    </clr-tooltip>
</div>
