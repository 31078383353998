<form #authConfigFrom="ngForm" class="clr-form clr-form-horizontal">
    <div class="clr-form-control">
        <label for="authMode" class="clr-control-label">
            {{'CONFIG.AUTH_MODE' | translate}}
                <clr-tooltip>
                    <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                    <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                        <span>{{'CONFIG.TOOLTIP.AUTH_MODE' | translate}}</span>
                    </clr-tooltip-content>
                </clr-tooltip></label>
        <div class="clr-control-container">
            <div class="clr-select-wrapper">
                <select class="clr-select" id="authMode" name="authMode" (change)="handleOnChange($event)"
                    [disabled]="disabled(currentConfig.auth_mode)" [(ngModel)]="currentConfig.auth_mode.value">
                    <option value="db_auth">{{'CONFIG.AUTH_MODE_DB' | translate }}</option>
                    <option value="ldap_auth">{{'CONFIG.AUTH_MODE_LDAP' | translate }}</option>
                    <option value="uaa_auth">{{'CONFIG.AUTH_MODE_UAA' | translate }}</option>
                    <option *ngIf="showHttpAuth" value="http_auth">{{'CONFIG.AUTH_MODE_HTTP' | translate }}</option>
                    <option value="oidc_auth">{{'CONFIG.AUTH_MODE_OIDC' | translate }}</option>
                </select>
            </div>
            <a href="https://openid.net/connect/" *ngIf="currentConfig?.auth_mode?.value==='oidc_auth'" target="_blank"
                class="more-info-link">{{ 'BUTTON.MORE_INFO' | translate }}</a>
        </div>
    </div>

    <section *ngIf="showUAA">
        <clr-input-container>
            <label for="uaa-endpoint" class="required">{{'CONFIG.UAA.ENDPOINT' | translate}}</label>
            <input type="text" id="uaa-endpoint" clrInput name="uaa-endpoint" size="35"
                [(ngModel)]="currentConfig.uaa_endpoint.value" [disabled]="!currentConfig.uaa_verify_cert.editable">
        </clr-input-container>
        <clr-input-container>
            <label for="uaa-id" class="required">{{'CONFIG.UAA.CLIENT_ID' | translate}}</label>
            <input clrInput type="text" id="uaa-id" name="uaa-client-id" size="35"
                [(ngModel)]="currentConfig.uaa_client_id.value" [disabled]="!currentConfig.uaa_verify_cert.editable">
        </clr-input-container>
        <clr-input-container>
            <label for="uaa-secret" class="required">{{'CONFIG.UAA.CLIENT_SECRET' | translate}}</label>
            <input clrInput id="uaa-secret" name="uaa-client-secret" type="password" size="35"
                [(ngModel)]="currentConfig.uaa_client_secret.value"
                [disabled]="!currentConfig.uaa_verify_cert.editable">
        </clr-input-container>
        <clr-checkbox-container>
            <label for="uaa-cert">{{'CONFIG.UAA.VERIFY_CERT' | translate}}</label>
            <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="uaa-cert" id="uaa-cert"
                    [(ngModel)]="currentConfig.uaa_verify_cert.value"
                    [disabled]="!currentConfig.uaa_verify_cert.editable" />
            </clr-checkbox-wrapper>
        </clr-checkbox-container>
    </section>

    <section *ngIf="showLdap">
        <clr-input-container>
            <label for="ldapUrl" class="required">{{'CONFIG.LDAP.URL' | translate}}
            </label>
            <input clrInput name="ldapUrl" type="text" #ldapUrlInput="ngModel"
                [(ngModel)]="currentConfig.ldap_url.value" required id="ldapUrl" size="40"
                [disabled]="disabled(currentConfig.ldap_url)" />
            <clr-control-error>{{'TOOLTIP.ITEM_REQUIRED' | translate}}</clr-control-error>
        </clr-input-container>
        <clr-input-container>
            <label for="ldapSearchDN" >{{'CONFIG.LDAP.SEARCH_DN' | translate}}
                <clr-tooltip>
                    <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                    <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                        <span>{{'CONFIG.TOOLTIP.LDAP_SEARCH_DN' | translate}}</span>
                    </clr-tooltip-content>
                </clr-tooltip>
            </label>
            <input clrInput name="ldapSearchDN" type="text" #ldapSearchDNInput="ngModel"
                [(ngModel)]="currentConfig.ldap_search_dn.value" id="ldapSearchDN" size="40"
                [disabled]="disabled(currentConfig.ldap_search_dn)" />
        </clr-input-container>

        <clr-input-container>
            <label for="ldapSearchPwd">{{'CONFIG.LDAP.SEARCH_PWD' | translate}}</label>
            <input clrInput name="ldapSearchPwd" type="password" #ldapSearchPwdInput="ngModel"
                [(ngModel)]="currentConfig.ldap_search_password.value" id="ldapSearchPwd" size="40"
                [disabled]="disabled(currentConfig.ldap_search_password)" />
        </clr-input-container>

        <clr-input-container>
            <label for="ldapBaseDN" class="required">{{'CONFIG.LDAP.BASE_DN' | translate}}
                <clr-tooltip>
                    <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                    <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                        <span>{{'CONFIG.TOOLTIP.LDAP_BASE_DN' | translate}}</span>
                    </clr-tooltip-content>
                </clr-tooltip>
            </label>
            <input clrInput name="ldapBaseDN" type="text" #ldapBaseDNInput="ngModel"
                [(ngModel)]="currentConfig.ldap_base_dn.value" required id="ldapBaseDN" size="40"
                [disabled]="disabled(currentConfig.ldap_base_dn)" />
            <clr-control-error>{{'TOOLTIP.ITEM_REQUIRED' | translate}}</clr-control-error>
        </clr-input-container>

        <clr-input-container>
            <label for="ldapFilter">{{'CONFIG.LDAP.FILTER' | translate}}</label>
            <input clrInput name="ldapFilter" type="text" #ldapFilterInput="ngModel"
                [(ngModel)]="currentConfig.ldap_filter.value" id="ldapFilter" size="40"
                [disabled]="disabled(currentConfig.ldap_filter)" />
        </clr-input-container>

        <clr-input-container>
            <label for="ldapUid" class="required">{{'CONFIG.LDAP.UID' | translate}}
                <clr-tooltip>
                    <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                    <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                        <span>{{'CONFIG.TOOLTIP.LDAP_UID' | translate}}</span>
                    </clr-tooltip-content>
                </clr-tooltip>
            </label>
            <input clrInput name="ldapUid" type="text" #ldapUidInput="ngModel"
                [(ngModel)]="currentConfig.ldap_uid.value" required id="ldapUid" size="40"
                [disabled]="disabled(currentConfig.ldap_uid)" />
            <clr-control-error>{{'TOOLTIP.ITEM_REQUIRED' | translate}}</clr-control-error>
        </clr-input-container>

        <clr-select-container>
            <label for="ldapScope">{{'CONFIG.LDAP.SCOPE' | translate}}
                <clr-tooltip>
                    <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                    <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                        <span>{{'CONFIG.TOOLTIP.LDAP_SCOPE' | translate}}</span>
                    </clr-tooltip-content>
                </clr-tooltip>
            </label>
            <select clrSelect id="ldapScope" name="ldapScope" [(ngModel)]="currentConfig.ldap_scope.value"
                [disabled]="disabled(currentConfig.ldap_scope)">
                <option value="0">{{'CONFIG.SCOPE_BASE' | translate }}</option>
                <option value="1">{{'CONFIG.SCOPE_ONE_LEVEL' | translate }}</option>
                <option value="2">{{'CONFIG.SCOPE_SUBTREE' | translate }}</option>
            </select>
        </clr-select-container>

        <clr-input-container>
            <label for="ldapGroupBaseDN">{{'CONFIG.LDAP.LDAP_GROUP_BASE_DN' | translate}}
                <clr-tooltip>
                    <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                    <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                        <span>{{'CONFIG.LDAP.LDAP_GROUP_BASE_DN_INFO' | translate}}</span>
                    </clr-tooltip-content>
                </clr-tooltip>
            </label>
            <input clrInput name="ldapGroupBaseDN" type="text" #ldapGroupDNInput="ngModel"
                [(ngModel)]="currentConfig.ldap_group_base_dn.value" id="ldapGroupBaseDN" size="40"
                [disabled]="disabled(currentConfig.ldap_group_base_dn)" />
        </clr-input-container>

        <clr-input-container>
            <label for="ldapGroupFilter">{{'CONFIG.LDAP.LDAP_GROUP_FILTER' | translate}}
                <clr-tooltip>
                    <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                    <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                        <span>{{'CONFIG.LDAP.LDAP_GROUP_FILTER_INFO' | translate}}</span>
                    </clr-tooltip-content>
                </clr-tooltip>
            </label>
            <input clrInput name="ldapGroupFilter" type="text" #ldapGroupFilterInput="ngModel"
                [(ngModel)]="currentConfig.ldap_group_search_filter.value" id="ldapGroupFilter" size="40"
                [disabled]="disabled(currentConfig.ldap_group_search_filter)" />
        </clr-input-container>

        <clr-input-container>
            <label for="ldapGroupGID">{{'CONFIG.LDAP.LDAP_GROUP_GID' | translate}}
                <clr-tooltip>
                    <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                    <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                        <span>{{'CONFIG.LDAP.LDAP_GROUP_GID_INFO' | translate}}</span>
                    </clr-tooltip-content>
                </clr-tooltip>
            </label>
            <input clrInput name="ldapGroupGID" type="text" #ldapGroupDNInput="ngModel"
                [(ngModel)]="currentConfig.ldap_group_attribute_name.value" id="ldapGroupGID" size="40"
                [disabled]="disabled(currentConfig.ldap_group_attribute_name)" />
        </clr-input-container>

        <!-- This is for ldap group admin dn -->
        <clr-input-container>
            <label for="ldapGroupAdminDN">{{'CONFIG.LDAP.LDAP_GROUP_ADMIN_DN' | translate}}
                <clr-tooltip>
                    <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                    <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                        <span>{{'CONFIG.LDAP.LDAP_GROUP_ADMIN_DN_INFO' | translate}}</span>
                    </clr-tooltip-content>
                </clr-tooltip>
            </label>
            <input clrInput name="ldapGroupAdminDN" type="text" #ldapGroupFilterInput="ngModel"
                [(ngModel)]="currentConfig.ldap_group_admin_dn.value" id="ldapGroupAdminDN" size="40"
                [disabled]="disabled(currentConfig.ldap_group_admin_dn)" />
        </clr-input-container>

        <!-- End of ldap group admin dn -->
        <clr-input-container>
            <label for="ldapGroupAdminDN">{{'CONFIG.LDAP.LDAP_GROUP_MEMBERSHIP' | translate}}
                <clr-tooltip>
                    <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                    <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                        <span>{{'CONFIG.LDAP.LDAP_GROUP_MEMBERSHIP_INFO' | translate}}</span>
                    </clr-tooltip-content>
                </clr-tooltip>
            </label>
            <input clrInput name="ldapGroupMembership" type="text" #ldapGroupFilterInput="ngModel"
                [(ngModel)]="currentConfig.ldap_group_membership_attribute.value" id="ldapGroupMembership" size="40"
                [disabled]="disabled(currentConfig.ldap_group_membership_attribute)" />
        </clr-input-container>
        <clr-select-container>
            <label for="ldapGroupScope">{{'CONFIG.LDAP.SCOPE' | translate}}
                <clr-tooltip>
                    <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                    <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                        <span>{{'CONFIG.LDAP.GROUP_SCOPE_INFO' | translate}}</span>
                    </clr-tooltip-content>
                </clr-tooltip>
            </label>
            <select clrSelect id="ldapGroupScope" name="ldapGroupScope"
                [(ngModel)]="currentConfig.ldap_group_search_scope.value"
                [disabled]="disabled(currentConfig.ldap_group_search_scope)">
                <option value="0">{{'CONFIG.SCOPE_BASE' | translate }}</option>
                <option value="1">{{'CONFIG.SCOPE_ONE_LEVEL' | translate }}</option>
                <option value="2">{{'CONFIG.SCOPE_SUBTREE' | translate }}</option>
            </select>
        </clr-select-container>
    </section>
    <clr-checkbox-container *ngIf="showSelfReg">
        <label for="selfReg">{{'CONFIG.SELF_REGISTRATION' | translate}}
                <clr-tooltip>
                        <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                        <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                            <span *ngIf="checkable; else elseBlock" class="tooltip-content">{{'CONFIG.TOOLTIP.SELF_REGISTRATION_ENABLE'
                                                            | translate}}</span>
                            <ng-template #elseBlock>
                                <span
                                    class="tooltip-content">{{'CONFIG.TOOLTIP.SELF_REGISTRATION_DISABLE' | translate}}</span>
                            </ng-template>
                        </clr-tooltip-content>
                    </clr-tooltip>
        </label>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="selfReg" id="selfReg"
                [(ngModel)]="currentConfig.self_registration.value"
                [disabled]="disabled(currentConfig.self_registration)" />
        </clr-checkbox-wrapper>
    </clr-checkbox-container>

    <section *ngIf="showLdap">

        <clr-checkbox-container>
            <label for="ldapVerifyCert">{{'CONFIG.LDAP.VERIFY_CERT' | translate}}
                    <clr-tooltip>
                            <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                            <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                                <span>{{'CONFIG.TOOLTIP.VERIFY_CERT' | translate}}</span>
                            </clr-tooltip-content>
                        </clr-tooltip>
            </label>
            <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="ldapVerifyCert" id="ldapVerifyCert"
                    [ngModel]="currentConfig.ldap_verify_cert.value" [disabled]="disabled(currentConfig.ldap_scope)"
                    (ngModelChange)="setVerifyCertValue($event)" />
            </clr-checkbox-wrapper>
        </clr-checkbox-container>

    </section>
    <section *ngIf="showHttpAuth">
        <clr-input-container>
            <label for="http_authproxy_endpoint" class="required">{{'CONFIG.HTTP_AUTH.ENDPOINT' | translate}}</label>
            <input clrInput type="text" pattern="^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(.*?)*$"
                #httpAuthproxyEndpointInput="ngModel" required id="http_authproxy_endpoint"
                name="http_authproxy_endpoint" size="35" [(ngModel)]="currentConfig.http_authproxy_endpoint.value"
                [disabled]="!currentConfig.http_authproxy_endpoint.editable" />
            <clr-control-error>{{'TOOLTIP.ENDPOINT_FORMAT' | translate}}</clr-control-error>
        </clr-input-container>
        <clr-input-container>
            <label for="http_authproxy_tokenreview_endpoint"
                class="required">{{'CONFIG.HTTP_AUTH.TOKEN_REVIEW' | translate}}</label>
            <input clrInput type="text" #httpAuthproxyTokenReviewInput="ngModel"
                pattern="^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(.*?)*$" required
                id="http_authproxy_tokenreview_endpoint" name="http_authproxy_tokenreview_endpoint" size="35"
                [(ngModel)]="currentConfig.http_authproxy_tokenreview_endpoint.value"
                [disabled]="!currentConfig.http_authproxy_tokenreview_endpoint.editable" />
            <clr-control-error>{{'TOOLTIP.ENDPOINT_FORMAT' | translate}}</clr-control-error>
        </clr-input-container>
        <clr-checkbox-container clrInline>
            <label for="http_authproxy_verify_cert"
                class="required">{{'CONFIG.HTTP_AUTH.VERIFY_CERT' | translate}}</label>
            <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="http_authproxy_verify_cert" id="http_authproxy_verify_cert"
                    [(ngModel)]="currentConfig.http_authproxy_verify_cert.value"
                    [disabled]="!currentConfig.http_authproxy_verify_cert.editable" />
            </clr-checkbox-wrapper>
        </clr-checkbox-container>
        <clr-checkbox-container clrInline>
            <label for="http_authproxy_skip_search"
                class="required">{{'CONFIG.HTTP_AUTH.SKIP_SEARCH' | translate}}</label>
            <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="http_authproxy_skip_search" id="http_authproxy_skip_search"
                    [disabled]="!currentConfig.http_authproxy_skip_search.editable"
                    [(ngModel)]="currentConfig.http_authproxy_skip_search.value" />
            </clr-checkbox-wrapper>
        </clr-checkbox-container>
    </section>

    <section *ngIf="showOIDC">
        <clr-input-container>
            <label class="required" for="oidcName">{{'CONFIG.OIDC.OIDC_PROVIDER' | translate}}
                <clr-tooltip>
                    <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                    <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                        <span>{{'TOOLTIP.OIDC_NAME' | translate}}</span>
                    </clr-tooltip-content>
                </clr-tooltip>
            </label>
            <input clrInput name="oidcName" required type="text" #oidcNameInput="ngModel"
                [(ngModel)]="currentConfig.oidc_name.value" id="oidcName" size="40"
                [disabled]="disabled(currentConfig.oidc_name)" />
            <clr-control-error>{{'TOOLTIP.ITEM_REQUIRED' | translate}}</clr-control-error>
        </clr-input-container>
        <clr-input-container>
            <label class="required" for="oidcEndpoint">{{'CONFIG.OIDC.ENDPOINT' | translate}}
                <clr-tooltip>
                    <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                    <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                        <span>{{'TOOLTIP.OIDC_ENDPOINT' | translate}}</span>
                    </clr-tooltip-content>
                </clr-tooltip>
            </label>
            <input clrInput name="oidcEndpoint" type="text" #oidcEndpointInput="ngModel" required
            [(ngModel)]="currentConfig.oidc_endpoint.value" id="oidcEndpoint" size="40"
            [disabled]="disabled(currentConfig.oidc_endpoint)"
            pattern="^([hH][tT]{2}[pP][sS]:\/\/)(.*?)*$" />
            <clr-control-error>{{'TOOLTIP.OIDC_ENDPOIT_FORMAT' | translate}}</clr-control-error>
        </clr-input-container>
        <clr-input-container>
            <label for="oidcClientId" class="required">{{'CONFIG.OIDC.CLIENT_ID' | translate}}</label>
            <input clrInput name="oidcClientId" type="text" #oidcClientIdInput="ngModel" required
                [(ngModel)]="currentConfig.oidc_client_id.value" id="oidcClientId" size="40"
                [disabled]="disabled(currentConfig.oidc_client_id)" />
            <clr-control-error>{{'TOOLTIP.ITEM_REQUIRED' | translate}}</clr-control-error>
        </clr-input-container>
        <clr-input-container>
            <label for="oidcClientSecret" class="required">{{'CONFIG.OIDC.CLIENTSECRET' | translate}}</label>
            <input clrInput name="oidcClientSecret" type="password" #oidcClientSecretInput="ngModel" required
                [(ngModel)]="currentConfig.oidc_client_secret.value" id="oidcClientSecret" size="40"
                [disabled]="disabled(currentConfig.oidc_client_secret)" />
            <clr-control-error>{{'TOOLTIP.ITEM_REQUIRED' | translate}}</clr-control-error>
        </clr-input-container>
        <clr-input-container>
            <label  for="oidcGroupClaim">{{'CONFIG.OIDC.GROUP_CLAIM_NAME' | translate}}
                <clr-tooltip>
                    <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                    <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                        <span>{{'TOOLTIP.OIDC_GROUP_CLAIM' | translate}}</span>
                    </clr-tooltip-content>
                </clr-tooltip>
            </label>
            <input clrInput name="oidcGroupClaim" type="text" #oidcClientSecretInput="ngModel"
                [(ngModel)]="currentConfig.oidc_groups_claim.value" id="oidcGroupClaim" size="40"
                 [disabled]="disabled(currentConfig.oidc_groups_claim)" />
        </clr-input-container>

        <clr-input-container>
                <label class="required" for="oidcScope">{{'CONFIG.OIDC.SCOPE' | translate}}
                    <clr-tooltip>
                        <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                        <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                            <span>{{'TOOLTIP.OIDC_SCOPE' | translate}}</span>
                        </clr-tooltip-content>
                    </clr-tooltip>
                </label>
                <input clrInput name="oidcScope" type="text" #oidcScopeInput="ngModel"
                [(ngModel)]="currentConfig.oidc_scope.value" id="oidcScope" size="40" required
                [disabled]="disabled(currentConfig.oidc_scope)" pattern="^(\w+,){0,}openid(,\w+){0,}$" />
                <clr-control-error>{{'TOOLTIP.SCOPE_REQUIRED' | translate}}</clr-control-error>
        </clr-input-container>
        <clr-checkbox-container>
                <label for="oidc_verify_cert">{{'CONFIG.OIDC.OIDC_VERIFYCERT' | translate}}
                        <clr-tooltip>
                                <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                                <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                                    <span>{{'TOOLTIP.OIDC_VERIFYCERT' | translate}}</span>
                                </clr-tooltip-content>
                            </clr-tooltip>
                </label>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox name="oidc_verify_cert" id="oidc_verify_cert"
                    [disabled]="disabled(currentConfig.oidc_verify_cert)"
                    [(ngModel)]="currentConfig.oidc_verify_cert.value" />
                </clr-checkbox-wrapper>
        </clr-checkbox-container>
        <clr-checkbox-container>
            <label for="oidcAutoOnboard">{{'CONFIG.OIDC.OIDC_AUTOONBOARD' | translate}}
                    <clr-tooltip>
                            <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                            <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                                <span>{{'TOOLTIP.OIDC_AUTOONBOARD' | translate}}</span>
                            </clr-tooltip-content>
                        </clr-tooltip>
            </label>
            <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="oidcAutoOnboard" id="oidcAutoOnboard"
                [disabled]="disabled(currentConfig.oidc_auto_onboard)"
                (change)="changeAutoOnBoard()"
                [(ngModel)]="currentConfig.oidc_auto_onboard.value" />
            </clr-checkbox-wrapper>
        </clr-checkbox-container>         
        <clr-input-container>
            <label  for="oidcUserClaim">{{'CONFIG.OIDC.USER_CLAIM' | translate}}
                <clr-tooltip>
                    <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                    <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                        <span>{{'TOOLTIP.OIDC_USER_CLAIM' | translate}}</span>
                    </clr-tooltip-content>
                </clr-tooltip>
            </label>
            <input autocomplete="off" clrInput name="oidcUserClaim" type="text" #oidcUserClaimInput="ngModel"
                [(ngModel)]="currentConfig.oidc_user_claim.value" id="oidcUserClaim" size="40"
                [disabled]="!currentConfig.oidc_auto_onboard.value || disabled(currentConfig.oidc_user_claim)" pattern="^[a-zA-Z0-9_-]*$">
        </clr-input-container>
        <div class="oidc-tip">{{ 'CONFIG.OIDC.OIDC_REDIREC_URL' | translate}}
            <span>{{redirectUrl}}/c/oidc/callback</span>
        </div>
    </section>
</form>
<div>
    <button type="button" id="config_auth_save" class="btn btn-primary" (click)="save()"
        [disabled]="!isValid() || !hasChanges()">{{'BUTTON.SAVE' | translate}}</button>
    <button type="button" class="btn btn-outline" (click)="cancel()"
        [disabled]="!isValid() || !hasChanges()">{{'BUTTON.CANCEL' | translate}}</button>
    <button type="button" id="ping-test" class="btn btn-outline" (click)="pingTestServer()" *ngIf="showTestingServerBtn"
        [disabled]="!isConfigValidForTesting()">{{(showLdap?'BUTTON.TEST_LDAP':'BUTTON.TEST_OIDC') | translate}}</button>
    <span id="forTestingLDAP" class="spinner spinner-inline" [hidden]="hideTestingSpinner"></span>
</div>
