import {Component, Input, ViewChild} from '@angular/core';
import { NgForm } from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ProjectService} from '../../services/project.service';
import {ErrorHandler} from '../../utils/error-handler/error-handler';
import {Project} from '../project-policy-config/project';

export class ProjectPublicToggleFormData {
    ProjectId: number;
    Name: string;
    Public: boolean;
    Reason: string;

    constructor() {
        this.Name = "";
        this.Public = false;
        this.Reason = "";
    }

    initByProject(pro: Project) {
        this.ProjectId = pro.project_id;
        this.Name = pro.name;
        this.Public = pro.metadata.public === 'true' ? true : false;
        this.Reason = "";
    }
}

@Component({
    selector: 'hbr-project-public-toggle-modal',
    templateUrl: './project-public-toggle-modal.component.html',
    styleUrls: ['./project-public-toggle-modal.component.scss']
})
export class ProjectPublicToggleModal {

    opened: boolean;
    onGoing: boolean = false;
    formData: ProjectPublicToggleFormData = new ProjectPublicToggleFormData();

    @ViewChild("form", {static: true}) form: NgForm;

    constructor(
        private projectService: ProjectService,
        private translate: TranslateService,
        private errorHandler: ErrorHandler,
    ) {
    }

    public open(project: Project) {
        this.formData.initByProject(project);
        this.opened = true;
        this.form.reset();
    }

    get isValid() {
        return this.formData.Name && this.formData.Reason;
    }

    submit() {
        this.onGoing = true;
        this.projectService.requestTogglePublic(
            this.formData.ProjectId,
            !!this.formData.Public,
            this.formData.Reason,
        )
        .subscribe(() => {
            this.opened = false;
            this.onGoing = false;
            this.translate.get('PROJECT_CONFIG.TOGGLE_PUBLIC_MODAL.SUCCESS_MSG').subscribe((res: string) => {
                this.errorHandler.info(res);
            });
        }, error => {
            this.opened = false;
            this.onGoing = false;
            this.errorHandler.error(error);
        });
    }

}
