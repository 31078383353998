<div class="{{sizeClass}}">
    <div class="esxc-gauge-container esxc-gauge">
        <div class="esxc-gauge-circle-bg"></div>
        <div #barOne [style.transform]="'rotate(' + posOne + 'deg)'" [style.background-color]="_colorOne" [style.transition]="transition" class="esxc-gauge-circle-fill esxc-gauge-bar-one"></div>
        <div #barTwo [style.transform]="'rotate(' + posTwo + 'deg)'" [style.background-color]="_colorTwo" [style.transition]="transition" class="esxc-gauge-circle-fill esxc-gauge-bar-two"></div>
        <div class="esxc-gauge-circle-inner esxc-gauge-circle-bgc-dark" [ngStyle]="{'background-color': backgroundColor}">
            <div class="esxc-gauge-circle-caption">
                <span class="esxc-value">{{used}}</span>
            </div>
        </div>
    </div>
    <div class="esxc-limit">
        <span class="esxc-value">{{threasHold}}</span>
        <span class="esxc-unit">GB</span>
        <span class="esxc-label">{{'STATISTICS.LIMIT' | translate}}</span>
    </div>
    <div class="esxc-title">{{title | translate}}</div>
</div>