<clr-modal [(clrModalOpen)]="addMemberOpened" [clrModalStaticBackdrop]="staticBackdrop" [clrModalClosable]="closable">
    <h3 class="modal-title">{{'MEMBER.NEW_MEMBER' | translate}}</h3>
    <div class="modal-body">
        <label>{{ 'MEMBER.NEW_USER_INFO' | translate}}</label>
        <form #memberForm="ngForm" class="clr-form clr-form-horizontal">
            <div class="clr-form-control">
                <label class="required clr-control-label">{{'MEMBER.NAME' | translate}}</label>
                <div class="clr-control-container" [class.clr-error]="!isMemberNameValid">
                    <div class="clr-input-wrapper" (mouseleave)="leaveInput()">
                        <input class="clr-input" type="text" id="member_name"  [(ngModel)]="member.entity_name"
                               name="member_name"
                               size="20"
                               #memberName="ngModel"
                               required
                               (keyup)="handleValidation()" autocomplete="off">
                        <clr-icon class="clr-validate-icon" shape="exclamation-circle"></clr-icon>
                        <span class="spinner spinner-inline" [hidden]="!checkOnGoing"></span>
                        <div class="selectBox"  [style.display]="selectUserName.length ? 'block' : 'none'" >
                            <ul>
                                <li *ngFor="let name of selectUserName" (click)="selectedName(name)">{{name}}</li>
                            </ul>
                        </div>
                    </div>
                    <clr-control-error *ngIf="!isMemberNameValid" class="tooltip-content">
                        {{ memberTooltip | translate }}
                    </clr-control-error>
                </div>
            </div>
            <div class="clr-form-control">
                <label class="clr-control-label">{{'MEMBER.ROLE' | translate}}</label>
                <div class="clr-control-container">
                    <clr-radio-wrapper>
                        <input clrRadio type="radio" name="member_role" id="checkrads_project_admin" [value]=1 [(ngModel)]="member.role_id">
                        <label for="checkrads_project_admin">{{'MEMBER.PROJECT_ADMIN' | translate}}</label>
                    </clr-radio-wrapper>
                    <clr-radio-wrapper>
                        <input clrRadio type="radio" name="member_role" id="checkrads_project_maintainer" [value]=4 [(ngModel)]="member.role_id">
                        <label for="checkrads_project_maintainer">{{'MEMBER.PROJECT_MAINTAINER' | translate}}</label>
                    </clr-radio-wrapper>
                    <clr-radio-wrapper>
                        <input clrRadio type="radio" name="member_role" id="checkrads_developer" [value]=2 [(ngModel)]="member.role_id">
                        <label for="checkrads_developer">{{'MEMBER.DEVELOPER' | translate}}</label>
                    </clr-radio-wrapper>
                    <clr-radio-wrapper>
                        <input clrRadio type="radio" name="member_role" id="checkrads_guest" [value]=3 [(ngModel)]="member.role_id">
                        <label for="checkrads_guest">{{'MEMBER.GUEST' | translate}}</label>
                    </clr-radio-wrapper>
                    <clr-radio-wrapper>
                        <input clrRadio type="radio" name="member_role" id="checkrads_limited_guest" [value]=5 [(ngModel)]="member.role_id">
                        <label for="checkrads_limited_guest">{{'MEMBER.LIMITED_GUEST' | translate}}</label>
                    </clr-radio-wrapper>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="onCancel()">{{'BUTTON.CANCEL' | translate}}</button>
        <button type="button" class="btn btn-primary" [disabled]="!isValid" (click)="onSubmit()">{{'BUTTON.OK' | translate}}</button>
    </div>
</clr-modal>
