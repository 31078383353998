<clr-header class="header-5 header"  [ngStyle]='{"background-color": customStyle?.headerBgColor?customStyle?.headerBgColor:"#004a70" }'>
    <div class="branding">
        <a href="javascript:void(0)" class="nav-link" (click)="homeAction()">
            <!-- <clr-icon shape="vm-bug"  *ngIf="!customStyle?.headerLogo"></clr-icon> -->
            <img [attr.src]="'images/'+customStyle?.headerLogo" *ngIf="customStyle?.headerLogo;else elseBlock" class="headerLogo">
            <ng-template #elseBlock><img [src]="'images/harbor-logo.svg'" class="harbor-logo" /></ng-template>
            <span class="title">{{customProjectName?.name? customProjectName?.name:(appTitle | translate)}}</span>
        </a>
    </div>
    <div class="header-nav">
        <a href="{{admiralLink}}" class="nav-link" *ngIf="isIntegrationMode"><span class="nav-text">{{'APP_TITLE.MGMT' | translate}}</span></a>
        <a href="javascript:void(0)" (click)="registryAction()" routerLink="/harbor" class="active nav-link" *ngIf="isIntegrationMode"><span class="nav-text">{{'APP_TITLE.REG' | translate}}</span></a>
    </div>
    <global-search></global-search>
    <div class="header-actions">
        <clr-dropdown class="dropdown-lang dropdown bottom-left" *ngIf="!isIntegrationMode">
            <button class="nav-icon nav-icon-width" clrDropdownToggle>
                <clr-icon shape="world" class="icon-left"></clr-icon>
                <span class="currentLang">{{currentLang}}</span>
                <clr-icon shape="caret down"></clr-icon>
            </button>
            <clr-dropdown-menu *clrIfOpen>
                <a href="javascript:void(0)" clrDropdownItem (click)='switchLanguage("en-us")' [class.lang-selected]='matchLang("en-us")'>English</a>
                <a href="javascript:void(0)" clrDropdownItem (click)='switchLanguage("zh-cn")' [class.lang-selected]='matchLang("zh-cn")'>中文简体</a>
                <a href="javascript:void(0)" clrDropdownItem (click)='switchLanguage("zh-tw")' [class.lang-selected]='matchLang("zh-tw")'>中文繁體</a>
                <a href="javascript:void(0)" clrDropdownItem (click)='switchLanguage("es-es")' [class.lang-selected]='matchLang("es-es")'>Español</a>
                <a href="javascript:void(0)" clrDropdownItem (click)='switchLanguage("fr-fr")' [class.lang-selected]='matchLang("fr-fr")'>Français</a>
                <a href="javascript:void(0)" clrDropdownItem (click)='switchLanguage("pt-br")' [class.lang-selected]='matchLang("pt-br")'>Português do Brasil</a>
                <a href="javascript:void(0)" clrDropdownItem (click)='switchLanguage("tr-tr")' [class.lang-selected]='matchLang("tr-tr")'>Türkçe</a>
            </clr-dropdown-menu>
        </clr-dropdown>
        <div class="nav-divider"></div>
        <clr-dropdown class="dropdown" *ngIf="isSessionValid">
            <button class="nav-text" clrDropdownToggle>
                <clr-icon shape="user" class="is-inverse user-icon" size="24"></clr-icon>
                <span>{{accountName}}</span>
                <clr-icon shape="caret down"></clr-icon>
            </button>
            <clr-dropdown-menu [clrPosition]="'bottom-right'" *clrIfOpen>
                <a href="javascript:void(0)" clrDropdownItem (click)="openAccountSettingsModal()">{{'ACCOUNT_SETTINGS.PROFILE' | translate}}</a>
                <a *ngIf="canChangePassword" href="javascript:void(0)" clrDropdownItem (click)="openChangePwdModal()">{{'ACCOUNT_SETTINGS.CHANGE_PWD' | translate}}</a>
                <a href="javascript:void(0)" clrDropdownItem (click)="openAboutDialog()">{{'ACCOUNT_SETTINGS.ABOUT' | translate}}</a>
                <div class="dropdown-divider"></div>
                <a href="javascript:void(0)" clrDropdownItem (click)="logOut()">{{'ACCOUNT_SETTINGS.LOGOUT' | translate}}</a>
            </clr-dropdown-menu>
        </clr-dropdown>
        <a href="javascript:void(0)" id="aboutMenu" class="nav-link nav-text nav-about-link" (click)="openAboutDialog()" *ngIf="!isSessionValid">{{'ACCOUNT_SETTINGS.ABOUT' | translate}}</a>
    </div>
</clr-header>
