<clr-modal [(clrModalOpen)]="opened" [clrModalStaticBackdrop]="true" [clrModalClosable]="false">
    <h3 class="modal-title">{{'RESET_PWD.TITLE' | translate}}</h3>
    <label class="modal-title reset-modal-title-override">{{'RESET_PWD.CAPTION2' | translate}}</label>
    <inline-alert class="modal-title"></inline-alert>
    <div class="modal-body body-format">
        <form #resetPwdForm="ngForm" class="form">
            <section class="form-block">
                <div class="form-group">
                    <label for="newPassword" class="form-group-label-override">{{'CHANGE_PWD.NEW_PWD' | translate}}</label>
                    <label for="newPassword" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md tooltip-bottom-left"
                        [class.invalid]='!getValidationState("newPassword")'>
                        <input [disabled]="resetOk" type="password" id="newPassword" placeholder='{{"PLACEHOLDER.NEW_PWD" | translate}}' required
                            pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,128}$" name="newPassword" [(ngModel)]="password"
                            #newPassInput="ngModel" size="25" (input)='handleValidation("newPassword", false)' (blur)='handleValidation("newPassword", true)'>
                        <span class="tooltip-content">
                            {{'TOOLTIP.PASSWORD' | translate}}
                        </span>
                    </label>
                </div>
                <div class="form-group">
                    <label for="reNewPassword" class="form-group-label-override">{{'CHANGE_PWD.CONFIRM_PWD' | translate}}</label>
                    <label for="reNewPassword" aria-haspopup="true" role="tooltip" class="tooltip tooltip-validation tooltip-md tooltip-top-left"
                        [class.invalid]='!getValidationState("reNewPassword")'>
                        <input [disabled]="resetOk" type="password" id="reNewPassword" placeholder='{{"PLACEHOLDER.CONFIRM_PWD" | translate}}' required
                            pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,128}$" name="reNewPassword" [(ngModel)]="confirmPwd"
                            #reNewPassInput size="25" (input)='handleValidation("reNewPassword", false)' (blur)='handleValidation("reNewPassword", true)'>
                        <span class="tooltip-content">
                            {{'TOOLTIP.CONFIRM_PWD' | translate}}
                        </span>
                    </label>
                </div>
            </section>
        </form>
    </div>
    <div class="modal-footer">
        <span class="spinner spinner-inline loading-top" [hidden]="showProgress === false"></span>
        <button type="button" class="btn" [class.btn-outline]="!resetOk" [class.btn-primary]="resetOk" (click)="close()">{{btnCancelCaption | translate}}</button>
        <button *ngIf="!resetOk" type="button" class="btn btn-primary" [disabled]="!isValid || showProgress" (click)="send()">{{'BUTTON.OK' | translate}}</button>
    </div>
</clr-modal>
