<div>
    <form #labelForm="ngForm" [hidden]="!formShow" class="label-form">
        <section>
            <label>
                <label for="name">{{'LABEL.LABEL_NAME' | translate}}</label>
                <label class="clr-control-container" [class.clr-error]="isLabelNameExist">
                    <input clrInput type="text" id="name" name="name" required size="20" autocomplete="off"
                           [(ngModel)]="labelModel.name" #name="ngModel" (keyup)="existValid(labelModel.name)">
                    <clr-icon class="clr-validate-icon" shape="exclamation-circle"></clr-icon>
                    <clr-control-error class="position-ab" *ngIf="isLabelNameExist">
                        {{'LABEL.NAME_ALREADY_EXISTS' | translate }}
                    </clr-control-error>
                </label>
            </label>
            <label>
                <label for="color">{{'LABEL.COLOR' | translate}}</label>
                <clr-dropdown [clrCloseMenuOnItemClick]="false" clrDropdownTrigger>
                    <clr-icon shape="caret down" class="btn btn-outline btnColor "></clr-icon>
                    <clr-dropdown-menu *clrIfOpen>
                        <label class="dropdown-item" (click)="labelModel.color=i.color" *ngFor="let i of labelColor"
                            [class.borderSty]="i.color == '#FFFFFF'"   [ngStyle]="{'background-color': i.color, 'color': i.textColor }">Aa</label>
                    </clr-dropdown-menu>
                </clr-dropdown>
                <input clrInput type="text" id="color" size="8" name="color" disabled [(ngModel)]="labelModel.color" #color="ngModel">
            </label>
            <label>
                <label for="description">{{'LABEL.DESCRIPTION' | translate}}</label>
                <input clrInput type="text" id="description" name="description" size="30" [(ngModel)]="labelModel.description"
                       #description="ngModel">
            </label>
            <label>
                <button type="button" class="btn  btn-outline" (click)="onCancel()" [disabled]="inProgress">{{
                    'BUTTON.CANCEL' | translate }}
                </button>
                <button type="submit" class="btn  btn-primary" (click)="onSubmit()" [disabled]="!isValid">{{
                    'BUTTON.OK' | translate }}
                </button>
            </label>
        </section>
    </form>
</div>
