<form #mailConfigFrom="ngForm" class="clr-form clr-form-horizontal">
    <section class="form-block">
        <clr-input-container>
            <label for="mailServer" class="required">{{'CONFIG.MAIL_SERVER' | translate}}</label>
            <input clrInput name="mailServer" type="text" #mailServerInput="ngModel"
                [(ngModel)]="currentConfig.email_host.value" required id="mailServer" size="40"
                [disabled]="disabled(currentConfig.email_host)" />
            <clr-control-error>{{'TOOLTIP.ITEM_REQUIRED' | translate}}</clr-control-error>
        </clr-input-container>
        <clr-input-container>
            <label for="emailPort" class="required">{{'CONFIG.MAIL_SERVER_PORT' | translate}}</label>
            <input clrInput name="emailPort" type="text" #emailPortInput="ngModel"
                [(ngModel)]="currentConfig.email_port.value" required port id="emailPort" size="40"
                [disabled]="disabled(currentConfig.email_port)" />
            <clr-control-error>{{'TOOLTIP.PORT_REQUIRED' | translate}}</clr-control-error>
        </clr-input-container>
        <clr-input-container>
            <label for="emailUsername">{{'CONFIG.MAIL_USERNAME' | translate}}</label>
            <input clrInput name="emailUsername" type="text" #emailUsernameInput="ngModel"
                [(ngModel)]="currentConfig.email_username.value" id="emailUsername" size="40"
                [disabled]="disabled(currentConfig.email_username)" />
        </clr-input-container>
        <clr-input-container>
            <label for="emailPassword">{{'CONFIG.MAIL_PASSWORD' | translate}}</label>
            <input clrInput name="emailPassword" type="password" #emailPasswordInput="ngModel"
                [(ngModel)]="currentConfig.email_password.value" id="emailPassword" size="40"
                [disabled]="disabled(currentConfig.email_password)" />
        </clr-input-container>
        <clr-input-container>
            <label for="emailFrom" class="required">{{'CONFIG.MAIL_FROM' | translate}}</label>
            <input clrInput name="emailFrom" type="text" #emailFromInput="ngModel"
                [(ngModel)]="currentConfig.email_from.value" required id="emailFrom" size="40"
                [disabled]="disabled(currentConfig.email_from)" />
            <clr-control-error>{{'TOOLTIP.ITEM_REQUIRED' | translate}}</clr-control-error>
        </clr-input-container>
        <div class="clr-form-control">
            <label class="clr-control-label" for="emailSSL">{{'CONFIG.MAIL_SSL' | translate}}
                    <clr-tooltip>
                            <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                            <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                                <span>{{'CONFIG.SSL_TOOLTIP' | translate}}</span>
                            </clr-tooltip-content>
                        </clr-tooltip>
            </label>
            <div class="clr-control-container">
                <div class="clr-checkbox-wrapper" id="emailSSL-wrapper">
                    <input type="checkbox" name="emailSSL" id="emailSSL" [(ngModel)]="currentConfig.email_ssl.value"
                        [disabled]="disabled(currentConfig.email_ssl)" />
                    <label class="clr-control-label" for="emailSSL">

                    </label>
                </div>
            </div>
        </div>
        <div class="clr-form-control">
            <label class="clr-control-label" for="emailInsecure">{{'CONFIG.MAIL_INSECURE' | translate}}
                    <clr-tooltip>
                            <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                            <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
                                <span>{{'CONFIG.INSECURE_TOOLTIP' | translate}}</span>
                            </clr-tooltip-content>
                        </clr-tooltip>
            </label>
            <div class="clr-control-container">
                <div class="clr-checkbox-wrapper" id="emailInsecure-wrapper">
                    <input type="checkbox" name="emailInsecure" id="emailInsecure"
                        [ngModel]="!currentConfig.email_insecure.value"
                        [disabled]="disabled(currentConfig.email_insecure)"
                        (ngModelChange)="setInsecureValue($event)" />
                        <label class="clr-control-label" for="emailInsecure"></label>
                </div>
            </div>
        </div>
    </section>
</form>
<div>
    <button type="button" id="config_email_save" class="btn btn-primary" (click)="save()"
        [disabled]="!isValid() || !hasChanges()">{{'BUTTON.SAVE'
        | translate}}</button>
    <button type="button" class="btn btn-outline" (click)="cancel()" [disabled]="!isValid() || !hasChanges()">{{'BUTTON.CANCEL'
        | translate}}</button>
    <button type="button" id="ping-test" class="btn btn-outline" (click)="testMailServer()" [disabled]="!isMailConfigValid()">{{'BUTTON.TEST_MAIL'
        | translate}}</button>
    <span id="forTestingMail" class="spinner spinner-inline" [hidden]="hideMailTestingSpinner"></span>
</div>