<clr-alert *ngFor="let message of backendMessages" [clrAlertType]="message.type" [clrAlertAppLevel]="true" (clrAlertClosedChange)="onCloseBackendMessage(message)" class="alert-backend">
    <clr-alert-item>
        <span class="alert-text" [innerHTML]="message.message"></span>
    </clr-alert-item>
</clr-alert>
<div [class.alert-app-level]="!isAppLevel" [hidden]="!globalMessageOpened">
    <clr-alert  [clrAlertType]="globalMessage.type" [clrAlertClosable]="!needAuth" [clrAlertAppLevel]="isAppLevel" [(clrAlertClosed)]="!globalMessageOpened" (clrAlertClosedChange)="onClose()">
        <div class="alert-item">
            <span class="alert-text">{{message}}</span>
            <div class="alert-actions alert-style" *ngIf="needAuth">
                <button class="btn alert-action" (click)="signIn()">{{ 'BUTTON.LOG_IN' | translate }}</button>
            </div>
        </div>
    </clr-alert>
</div>
<div *ngIf="globalMessageOpened && needAuth" class="mask-layer"></div>
