<clr-main-container>
    <div class="clr-row scanner-info" *ngIf="showScannerInfo && isSystemAdmin ">
        <div class="clr-col-2"></div>
        <div class="clr-col text-center">
            <clr-icon shape="info-standard" size="20"></clr-icon>
            <span class="ml-05">{{'SCANNER.HELP_INFO_1' | translate }}
                <a target="_blank" href="{{scannerDocUrl}}">{{'SCANNER.HELP_INFO_2' | translate }}</a>
            </span>
        </div>
        <div class="clr-col-2 right">
            <a class="all-scanners" href="#"
                routerLink="/harbor/interrogation-services/scanners">{{'SCANNER.ALL_SCANNERS' | translate }}</a>
            <clr-icon (click)="closeInfo()" class="close-icon" shape="times" size="24"></clr-icon>
        </div>
    </div>
    <global-message [isAppLevel]="true"></global-message>
    <navigator (showAccountSettingsModal)="openModal($event)" (showDialogModalAction)="openModal($event)"></navigator>
    <div class="content-container">
        <div class="content-area" [class.container-override]="showSearch"
            [class.content-area-override]="!shouldOverrideContent"
            [class.start-content-padding]="shouldOverrideContent">
            <global-message [isAppLevel]="false"></global-message>
            <!-- Only appear when searching -->
            <search-result></search-result>
            <router-outlet></router-outlet>
        </div>
        <clr-vertical-nav [clrVerticalNavCollapsible]="true" *ngIf="isUserExisting">
            <div>
                <a clrVerticalNavLink routerLinkActive="active" routerLink="/harbor/projects">
                    <clr-icon shape="organization" clrVerticalNavIcon></clr-icon>
                    {{'SIDE_NAV.PROJECTS' | translate}}
                </a>
                <a clrVerticalNavLink routerLinkActive="active" routerLink="/harbor/logs">
                    <clr-icon shape="list" clrVerticalNavIcon></clr-icon>
                    {{'SIDE_NAV.LOGS' | translate}}
                </a>
                <clr-vertical-nav-group *ngIf="isSystemAdmin" routerLinkActive="active">
                    <clr-icon shape="administrator" clrVerticalNavIcon></clr-icon>
                    {{'SIDE_NAV.SYSTEM_MGMT.NAME' | translate}}
                    <a routerLink="#" hidden aria-hidden="true"></a>
                    <clr-vertical-nav-group-children *clrIfExpanded="true">
                        <a clrVerticalNavLink routerLink="/harbor/users" routerLinkActive="active">
                            <clr-icon shape="users" clrVerticalNavIcon></clr-icon>
                            {{'SIDE_NAV.SYSTEM_MGMT.USER' | translate}}
                        </a>
                        <a *ngIf='isLdapMode || isHttpAuthMode || isOidcMode' clrVerticalNavLink
                            routerLink="/harbor/groups" routerLinkActive="active">
                            <clr-icon shape="users" clrVerticalNavIcon></clr-icon>
                            {{'SIDE_NAV.SYSTEM_MGMT.GROUP' | translate}}
                        </a>
                        <a clrVerticalNavLink routerLink="/harbor/registries" routerLinkActive="active">
                            <clr-icon shape="block" clrVerticalNavIcon></clr-icon>
                            {{'SIDE_NAV.SYSTEM_MGMT.REGISTRY' | translate}}
                        </a>
                        <a clrVerticalNavLink routerLink="/harbor/replications" routerLinkActive="active">
                            <clr-icon shape="cloud-traffic" clrVerticalNavIcon></clr-icon>
                            {{'SIDE_NAV.SYSTEM_MGMT.REPLICATION' | translate}}
                        </a>
                        <a clrVerticalNavLink routerLink="/harbor/distribution/instances" routerLinkActive="active">
                            <clr-icon shape="share"></clr-icon>
                            {{'SIDE_NAV.DISTRIBUTIONS.NAME' | translate}}
                        </a>
                        <a *ngIf="!withAdmiral" clrVerticalNavLink routerLink="/harbor/labels"
                            routerLinkActive="active">
                            <clr-icon shape="tag" clrVerticalNavIcon></clr-icon>
                            {{'CONFIG.LABEL' | translate }}
                        </a>
                        <a clrVerticalNavLink routerLink="/harbor/project-quotas" routerLinkActive="active">
                            <clr-icon shape="resource-pool" clrVerticalNavIcon></clr-icon>
                            {{'CONFIG.PROJECT_QUOTAS' | translate }}
                        </a>
                        <a clrVerticalNavLink routerLink="/harbor/interrogation-services" routerLinkActive="active">
                            <clr-icon shape="shield" clrVerticalNavIcon></clr-icon>
                            {{'SIDE_NAV.SYSTEM_MGMT.INTERROGATION_SERVICES' | translate}}
                        </a>
                        <a clrVerticalNavLink *ngIf="hasAdminRole" routerLink="/harbor/gc" routerLinkActive="active">
                            <clr-icon shape="trash" clrVerticalNavIcon></clr-icon>
                            {{'SIDE_NAV.SYSTEM_MGMT.GARBAGE_COLLECTION' | translate}}
                        </a>
                        <a clrVerticalNavLink routerLinkActive="active" routerLink="/harbor/configs">
                            <clr-icon shape="cog" clrVerticalNavIcon></clr-icon>
                            {{'SIDE_NAV.SYSTEM_MGMT.CONFIG' | translate}}
                        </a>
                    </clr-vertical-nav-group-children>
                </clr-vertical-nav-group>
            </div>
            <div class="mb-1">
                <ng-container *ngFor="let theme of themeArray;let i=index">
                    <ng-container *ngIf="theme.showStyle === styleMode">
                        <a clrVerticalNavLink (click)="themeChanged(theme)">
                            <clr-icon clrVerticalNavIcon size="20" *ngIf="styleMode ==='DARK'" shape="sun"
                                      class="is-solid"></clr-icon>
                            <clr-icon clrVerticalNavIcon size="20" *ngIf="styleMode ==='LIGHT'" shape="moon"
                                      class="is-solid"></clr-icon>
                            {{ theme.text | translate }}
                        </a>
                    </ng-container>
                </ng-container> 
                <a clrVerticalNavLink routerLinkActive="active" target="_blank" href="https://g.126.fm/02850du">
                    <clr-icon shape="network-globe" clrVerticalNavIcon></clr-icon>
                    {{'SIDE_NAV.USER_MANUAL' | translate}}
                </a>
                <a clrVerticalNavLink routerLinkActive="active"  href="javascript:void(0)" (click)="linkToDocs()">
                    <clr-icon shape="network-globe" clrVerticalNavIcon></clr-icon>
                    {{'SIDE_NAV.HARBOR_API_MANAGEMENT' | translate}}
                </a>
            </div>
        </clr-vertical-nav>
        <hbr-operation-model *ngIf="isUserExisting"></hbr-operation-model>
    </div>
</clr-main-container>
<account-settings-modal></account-settings-modal>
<password-setting></password-setting>
<confiramtion-dialog></confiramtion-dialog>
<about-dialog></about-dialog>
