<clr-modal [(clrModalOpen)]="opened" [clrModalStaticBackdrop]="true" [clrModalClosable]="false">
    <h3 class="modal-title">{{'RESET_PWD.TITLE' | translate}}</h3>
    <inline-alert class="modal-title" (confirmEvt)="confirmCancel($event)"></inline-alert>
    <div class="modal-body body-format">
        <form #resetPwdForm="ngForm" clrForm>
            <clr-input-container>
                <label>{{'CHANGE_PWD.NEW_PWD' | translate}}</label>
                <input clrInput type="password" id="newPassword" placeholder='{{"PLACEHOLDER.NEW_PWD" | translate}}' required pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,128}$"
                    name="newPassword" [(ngModel)]="password" #newPassInput="ngModel" size="25" (input)='handleValidation("newPassword", false)'
                    (blur)='handleValidation("newPassword", true)'>
                <clr-control-error *ngIf="!getValidationState('newPassword')">
                    {{'TOOLTIP.PASSWORD' | translate}}
                </clr-control-error>
            </clr-input-container>
            <clr-input-container>
                <label>{{'CHANGE_PWD.CONFIRM_PWD' | translate}}</label>
                <input clrInput type="password" id="reNewPassword" placeholder='{{"PLACEHOLDER.CONFIRM_PWD" | translate}}' required pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,128}$"
                    name="reNewPassword" [(ngModel)]="confirmPwd" #reNewPassInput size="25" (input)='handleValidation("reNewPassword", false)'
                    (blur)='handleValidation("reNewPassword", true)'>
                <clr-control-error *ngIf="!getValidationState('reNewPassword')">
                    {{'TOOLTIP.CONFIRM_PWD' | translate}}
                </clr-control-error>
            </clr-input-container>
        </form>
    </div>
    <div class="modal-footer">
        <span class="spinner spinner-inline loading-top" [hidden]="showProgress === false"></span>
        <button type="button" class="btn btn-outline" (click)="close()">{{'BUTTON.CANCEL' | translate}}</button>
        <button type="button" class="btn btn-primary" [disabled]="!isValid || showProgress" (click)="send()">{{'BUTTON.OK' | translate}}</button>
    </div>
</clr-modal>
