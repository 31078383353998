// Copyright Project Harbor Authors
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//    http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import { ConfirmationState, ConfirmationTargets } from '../../entities/shared.const';

export class ConfirmationAcknowledgement {
    constructor(state: ConfirmationState, data: any, source: ConfirmationTargets) {
        this.state = state;
        this.data = data;
        this.source = source;
    }

    state: ConfirmationState = ConfirmationState.NA;
    data: any = {};
    source: ConfirmationTargets = ConfirmationTargets.EMPTY;
}
