<div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <h2 class="custom-h2">{{'SIDE_NAV.SYSTEM_MGMT.USER' | translate}}</h2>
        <div class="action-panel-pos rightPos">
            <hbr-filter [withDivider]="true" class="filter-pos" filterPlaceholder='{{"USER.FILTER_PLACEHOLDER" | translate}}' (filterEvt)="doFilter($event)" [currentValue]="currentTerm"></hbr-filter>
            <span class="refresh-btn">
        <clr-icon shape="refresh" [hidden]="inProgress" ng-disabled="inProgress" (click)="refresh()"></clr-icon>
        <span class="spinner spinner-inline" [hidden]="inProgress === false"></span>
            </span>
        </div>
        <div>
            <clr-datagrid (clrDgRefresh)="load($event)" [clrDgLoading]="inProgress"  [(clrDgSelected)]="selectedRow">
                <clr-dg-action-bar>
                    <button type="button" class="btn  btn-secondary" id="add-new-user" (click)="addNewUser()" [disabled]="!canCreateUser"><clr-icon shape="plus" size="16"></clr-icon>&nbsp;{{'USER.ADD_ACTION' | translate}}</button>
                    <button type="button" class="btn  btn-secondary" id="set-admin" [disabled]="!ifSameRole" (click)="changeAdminRole()" ><clr-icon shape="wrench" size="16"></clr-icon>&nbsp;{{ISADMNISTRATOR  | translate}}</button>
                    <clr-dropdown  [clrCloseMenuOnItemClick]="false" class="btn  btn-link" clrDropdownTrigger>
                        <span id='member-action'>{{'BUTTON.ACTIONS' | translate}}<clr-icon shape="caret down"></clr-icon></span>
                        <clr-dropdown-menu *clrIfOpen>
                            <clr-dropdown>
                                <button type="button" class="btn  btn-secondary" id="changePwd" [hidden]="!canCreateUser" [disabled]="!(selectedRow.length==1)" (click)="openChangePwdModal()" ><clr-icon shape="edit" size="16"></clr-icon>&nbsp;{{'RESET_PWD.TITLE'  | translate}}</button>
                                <div class="dropdown-divider"></div>
                                <button id="deleteUser" type="button" class="btn  btn-secondary"
                                    (click)="deleteUsers(selectedRow)"
                                    [disabled]="!selectedRow.length || onlySelf || !canCreateUser">
                                    <clr-icon shape="times" size="16"></clr-icon>&nbsp;{{'USER.DEL_ACTION' | translate}}
                                </button>
                            </clr-dropdown>
                        </clr-dropdown-menu>
                    </clr-dropdown>
                </clr-dg-action-bar>
                <clr-dg-column>{{'USER.COLUMN_NAME' | translate}}</clr-dg-column>
                <clr-dg-column>{{'USER.COLUMN_ADMIN' | translate}}</clr-dg-column>
                <clr-dg-column>{{'USER.COLUMN_EMAIL' | translate}}</clr-dg-column>
                <clr-dg-column>{{'USER.COLUMN_REG_NAME' | translate}}</clr-dg-column>
                <clr-dg-row *ngFor="let user of users" [clrDgItem]="user">
                    <clr-dg-cell>{{user.username}}</clr-dg-cell>
                    <clr-dg-cell>{{isSystemAdmin(user)}}</clr-dg-cell>
                    <clr-dg-cell>{{user.email}}</clr-dg-cell>
                    <clr-dg-cell>{{user.creation_time | date: 'short'}}</clr-dg-cell>
                </clr-dg-row>
                <clr-dg-footer>
                    <span *ngIf="totalCount>0">{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} {{'USER.OF' | translate }}</span>
                    {{pagination.totalItems}} {{'USER.ITEMS' | translate }}
                    <clr-dg-pagination #pagination [clrDgPageSize]="pageSize" [(clrDgPage)]="currentPage" [clrDgTotalItems]="totalCount">
                    </clr-dg-pagination>
                </clr-dg-footer>
            </clr-datagrid>
        </div>
        <new-user-modal (addNew)="addUserToList($event)"></new-user-modal>
        <change-password></change-password>
    </div>
</div>
