<div>
    <h2 class="custom-h2" sub-header-title> {{'SIDE_NAV.LOGS' | translate}}</h2>
    <div class="row flex-items-xs-between flex-items-xs-bottom">
        <div></div>
        <div class="action-head-pos">
            <div class="select filter-tag clr-select-wrapper" [hidden]="!isOpenFilterTag">
                <select id="selectKey" (change)="selectFilterKey($event)">
                    <option value="username">{{"AUDIT_LOG.USERNAME" | translate | lowercase}}</option>
                    <option value="resource">{{"AUDIT_LOG.RESOURCE" | translate | lowercase}}</option>
                    <option value="resource_type">{{"AUDIT_LOG.RESOURCE_TYPE" | translate | lowercase}}</option>
                    <option value="operation">{{"AUDIT_LOG.OPERATION" | translate | lowercase}}</option>
                </select>
            </div>
            <hbr-filter [withDivider]="true" filterPlaceholder='{{"AUDIT_LOG.FILTER_PLACEHOLDER" | translate}}' (filterEvt)="doFilter($event)"
                (openFlag)="openFilter($event)" [currentValue]="currentTerm"></hbr-filter>
            <span (click)="refresh()" class="refresh-btn">
                <clr-icon shape="refresh" [hidden]="inProgress" ng-disabled="inProgress"></clr-icon>
                <span class="spinner spinner-inline" [hidden]="!inProgress"></span>
            </span>
        </div>
    </div>
    <div>
        <clr-datagrid (clrDgRefresh)="load()" [clrDgLoading]="loading">
            <clr-dg-column>{{'AUDIT_LOG.USERNAME' | translate}}</clr-dg-column>
            <clr-dg-column>{{'AUDIT_LOG.RESOURCE' | translate}}</clr-dg-column>
            <clr-dg-column>{{'AUDIT_LOG.RESOURCE_TYPE' | translate}}</clr-dg-column>
            <clr-dg-column>{{'AUDIT_LOG.OPERATION' | translate}}</clr-dg-column>
            <clr-dg-column>{{'AUDIT_LOG.TIMESTAMP' | translate}}</clr-dg-column>
            <clr-dg-placeholder>{{ 'AUDIT_LOG.NOT_FOUND' | translate }}</clr-dg-placeholder>
            <clr-dg-row *ngFor="let l of recentLogs">
                <clr-dg-cell>{{l.username}}</clr-dg-cell>
                <clr-dg-cell>{{l.resource}}</clr-dg-cell>
                <clr-dg-cell>{{l.resource_type}}</clr-dg-cell>
                <clr-dg-cell>{{l.operation}}</clr-dg-cell>
                <clr-dg-cell>{{l.op_time | date: 'short'}}</clr-dg-cell>
            </clr-dg-row>
            <clr-dg-footer>
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} {{'AUDIT_LOG.OF' | translate}} {{pagination.totalItems}} {{'AUDIT_LOG.ITEMS'
                | translate}}
                <clr-dg-pagination #pagination [(clrDgPage)]="currentPage" [clrDgPageSize]="pageSize" [clrDgTotalItems]="totalCount"></clr-dg-pagination>
            </clr-dg-footer>
        </clr-datagrid>
    </div>
</div>