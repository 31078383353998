<h2 class="custom-h2">{{'SIDE_NAV.SYSTEM_MGMT.REPLICATION' | translate}}</h2>
<div class="content-top">
    <hbr-replication
    [withReplicationJob]='true'
    [isSystemAdmin]="isSystemAdmin"
    [withAdmiral]="withAdmiral"
    (goToRegistry)="goRegistry()"
    (redirect)="customRedirect($event)"
    [hasCreateReplicationPermission]="true"
    [hasUpdateReplicationPermission]="true"
    [hasDeleteReplicationPermission]="true"
    [hasExecuteReplicationPermission]="true"></hbr-replication>
</div>