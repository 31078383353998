<div>
  <div class="breadcrumb">
    <a (click)="gotoProjectList()"> {{ 'SIDE_NAV.PROJECTS'| translate}} </a>
    &lt;
    <a (click)="gotoChartList()">{{ projectName }}</a>
    &lt;
    <a (click)="gotoChartVersion()">{{ 'HELM_CHART.CHARTVERSIONS'| translate}}</a>
  </div>
  <hbr-chart-detail [projectId]="projectId" [project]="project" [chartName]="chartName" [chartVersion]="chartVersion"
    [roleName]="roleName"></hbr-chart-detail>
</div>
