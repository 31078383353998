<div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <h2 class="custom-h2 config-title">{{'CONFIG.TITLE' | translate }}<span class="spinner spinner-inline ml-1 v-mid" [hidden]="inProgress === false"></span></h2>
        <clr-tabs>
                <clr-tab>
                    <button id="config-auth" clrTabLink>{{'CONFIG.AUTH' | translate}}</button>
                    <clr-tab-content id="authentication" *clrIfActive>
                        <config-auth [allConfig]="allConfig"  (refreshAllconfig)="refreshAllconfig()"></config-auth>
                    </clr-tab-content>
                </clr-tab>
                <clr-tab>
                    <button id="config-email" clrTabLink>{{'CONFIG.EMAIL' | translate }}</button>
                    <clr-tab-content id="email" *clrIfActive>
                        <config-email [mailConfig]="allConfig" (refreshAllconfig)="refreshAllconfig()"></config-email>
                    </clr-tab-content>
                </clr-tab>
                <clr-tab>
                    <button id="config-system" clrTabLink>{{'CONFIG.SYSTEM' | translate }}</button>
                    <clr-tab-content id="system_settings" *clrIfActive>
                        <system-settings [(systemSettings)]="allConfig" [hasAdminRole]="hasAdminRole" (reloadSystemConfig)="handleAppConfig($event)" (readOnlyChange)="handleReadyOnlyChange($event)" [hasCAFile]="hasCAFile"></system-settings>
                    </clr-tab-content>
                </clr-tab>
        </clr-tabs>
    </div>
</div>
