<clr-modal [(clrModalOpen)]="isOpen" [clrModalStaticBackdrop]="staticBackdrop" [clrModalClosable]="closable">
  <h3 *ngIf="isEdit" class="modal-title">{{'WEBHOOK.EDIT_WEBHOOK' | translate}}</h3>
  <h3 *ngIf="!isEdit" class="modal-title">{{'WEBHOOK.ADD_WEBHOOK' | translate}}</h3>
  <div class="modal-body">
    <div>{{'WEBHOOK.EDIT_WEBHOOK_DESC' | translate}}</div>
    <add-webhook-form [metadata]="metadata" [projectId]="projectId"
      [isOpen]="isOpen"
      (close)="closeModal()"
      (notify)="notifySuccess()"
    ></add-webhook-form>
  </div>
</clr-modal>