/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Artifact } from '../models/artifact';
import { Tag } from '../models/tag';
import { Label } from '../models/label';
@Injectable({
  providedIn: 'root',
})
class ArtifactService extends __BaseService {
  static readonly listArtifactsPath = '/projects/{project_name}/repositories/{repository_name}/artifacts';
  static readonly CopyArtifactPath = '/projects/{project_name}/repositories/{repository_name}/artifacts';
  static readonly getArtifactPath = '/projects/{project_name}/repositories/{repository_name}/artifacts/{reference}';
  static readonly deleteArtifactPath = '/projects/{project_name}/repositories/{repository_name}/artifacts/{reference}';
  static readonly createTagPath = '/projects/{project_name}/repositories/{repository_name}/artifacts/{reference}/tags';
  static readonly listTagsPath = '/projects/{project_name}/repositories/{repository_name}/artifacts/{reference}/tags';
  static readonly deleteTagPath = '/projects/{project_name}/repositories/{repository_name}/artifacts/{reference}/tags/{tag_name}';
  static readonly getAdditionPath = '/projects/{project_name}/repositories/{repository_name}/artifacts/{reference}/additions/{addition}';
  static readonly addLabelPath = '/projects/{project_name}/repositories/{repository_name}/artifacts/{reference}/labels';
  static readonly removeLabelPath = '/projects/{project_name}/repositories/{repository_name}/artifacts/{reference}/labels/{label_id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List artifacts under the specific project and repository. Except the basic properties, the other supported queries in "q" includes "tags=*" to list only tagged artifacts, "tags=nil" to list only untagged artifacts, "tags=~v" to list artifacts whose tag fuzzy matches "v", "tags=v" to list artifact whose tag exactly matches "v", "labels=(id1, id2)" to list artifacts that both labels with id1 and id2 are added to
   * @param params The `ArtifactService.ListArtifactsParams` containing the following parameters:
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `project_name`: The name of the project
   *
   * - `with_tag`: Specify whether the tags are included inside the returning artifacts
   *
   * - `with_signature`: Specify whether the signature is included inside the tags of the returning artifacts. Only works when setting "with_tag=true"
   *
   * - `with_scan_overview`: Specify whether the scan overview is included inside the returning artifacts
   *
   * - `with_label`: Specify whether the labels are included inside the returning artifacts
   *
   * - `with_immutable_status`: Specify whether the immutable status is included inside the tags of the returning artifacts. Only works when setting "with_tag=true"
   *
   * - `q`: Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
   *
   * - `page_size`: The size of per page
   *
   * - `page`: The page number
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  listArtifactsResponse(params: ArtifactService.ListArtifactsParams): __Observable<__StrictHttpResponse<Array<Artifact>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.withTag != null) __params = __params.set('with_tag', params.withTag.toString());
    if (params.withSignature != null) __params = __params.set('with_signature', params.withSignature.toString());
    if (params.withScanOverview != null) __params = __params.set('with_scan_overview', params.withScanOverview.toString());
    if (params.withLabel != null) __params = __params.set('with_label', params.withLabel.toString());
    if (params.withImmutableStatus != null) __params = __params.set('with_immutable_status', params.withImmutableStatus.toString());
    if (params.q != null) __params = __params.set('q', params.q.toString());
    if (params.pageSize != null) __params = __params.set('page_size', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/projects/${params.projectName}/repositories/${params.repositoryName}/artifacts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Artifact>>;
      })
    );
  }
  /**
   * List artifacts under the specific project and repository. Except the basic properties, the other supported queries in "q" includes "tags=*" to list only tagged artifacts, "tags=nil" to list only untagged artifacts, "tags=~v" to list artifacts whose tag fuzzy matches "v", "tags=v" to list artifact whose tag exactly matches "v", "labels=(id1, id2)" to list artifacts that both labels with id1 and id2 are added to
   * @param params The `ArtifactService.ListArtifactsParams` containing the following parameters:
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `project_name`: The name of the project
   *
   * - `with_tag`: Specify whether the tags are included inside the returning artifacts
   *
   * - `with_signature`: Specify whether the signature is included inside the tags of the returning artifacts. Only works when setting "with_tag=true"
   *
   * - `with_scan_overview`: Specify whether the scan overview is included inside the returning artifacts
   *
   * - `with_label`: Specify whether the labels are included inside the returning artifacts
   *
   * - `with_immutable_status`: Specify whether the immutable status is included inside the tags of the returning artifacts. Only works when setting "with_tag=true"
   *
   * - `q`: Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
   *
   * - `page_size`: The size of per page
   *
   * - `page`: The page number
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  listArtifacts(params: ArtifactService.ListArtifactsParams): __Observable<Array<Artifact>> {
    return this.listArtifactsResponse(params).pipe(
      __map(_r => _r.body as Array<Artifact>)
    );
  }

  /**
   * Copy the artifact specified in the "from" parameter to the repository.
   * @param params The `ArtifactService.CopyArtifactParams` containing the following parameters:
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `project_name`: The name of the project
   *
   * - `from`: The artifact from which the new artifact is copied from, the format should be "project/repository:tag" or "project/repository@digest".
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  CopyArtifactResponse(params: ArtifactService.CopyArtifactParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.from != null) __params = __params.set('from', params.from.toString());
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/projects/${params.projectName}/repositories/${params.repositoryName}/artifacts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Copy the artifact specified in the "from" parameter to the repository.
   * @param params The `ArtifactService.CopyArtifactParams` containing the following parameters:
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `project_name`: The name of the project
   *
   * - `from`: The artifact from which the new artifact is copied from, the format should be "project/repository:tag" or "project/repository@digest".
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  CopyArtifact(params: ArtifactService.CopyArtifactParams): __Observable<null> {
    return this.CopyArtifactResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the artifact specified by the reference under the project and repository. The reference can be digest or tag.
   * @param params The `ArtifactService.GetArtifactParams` containing the following parameters:
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `reference`: The reference of the artifact, can be digest or tag
   *
   * - `project_name`: The name of the project
   *
   * - `with_tag`: Specify whether the tags are inclued inside the returning artifacts
   *
   * - `with_signature`: Specify whether the signature is inclued inside the returning artifacts
   *
   * - `with_scan_overview`: Specify whether the scan overview is inclued inside the returning artifacts
   *
   * - `with_label`: Specify whether the labels are inclued inside the returning artifacts
   *
   * - `with_immutable_status`: Specify whether the immutable status is inclued inside the tags of the returning artifacts. Only works when setting "with_tag=true"
   *
   * - `page_size`: The size of per page
   *
   * - `page`: The page number
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  getArtifactResponse(params: ArtifactService.GetArtifactParams): __Observable<__StrictHttpResponse<Artifact>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.withTag != null) __params = __params.set('with_tag', params.withTag.toString());
    if (params.withSignature != null) __params = __params.set('with_signature', params.withSignature.toString());
    if (params.withScanOverview != null) __params = __params.set('with_scan_overview', params.withScanOverview.toString());
    if (params.withLabel != null) __params = __params.set('with_label', params.withLabel.toString());
    if (params.withImmutableStatus != null) __params = __params.set('with_immutable_status', params.withImmutableStatus.toString());
    if (params.pageSize != null) __params = __params.set('page_size', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/projects/${params.projectName}/repositories/${params.repositoryName}/artifacts/${params.reference}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Artifact>;
      })
    );
  }
  /**
   * Get the artifact specified by the reference under the project and repository. The reference can be digest or tag.
   * @param params The `ArtifactService.GetArtifactParams` containing the following parameters:
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `reference`: The reference of the artifact, can be digest or tag
   *
   * - `project_name`: The name of the project
   *
   * - `with_tag`: Specify whether the tags are inclued inside the returning artifacts
   *
   * - `with_signature`: Specify whether the signature is inclued inside the returning artifacts
   *
   * - `with_scan_overview`: Specify whether the scan overview is inclued inside the returning artifacts
   *
   * - `with_label`: Specify whether the labels are inclued inside the returning artifacts
   *
   * - `with_immutable_status`: Specify whether the immutable status is inclued inside the tags of the returning artifacts. Only works when setting "with_tag=true"
   *
   * - `page_size`: The size of per page
   *
   * - `page`: The page number
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  getArtifact(params: ArtifactService.GetArtifactParams): __Observable<Artifact> {
    return this.getArtifactResponse(params).pipe(
      __map(_r => _r.body as Artifact)
    );
  }

  /**
   * Delete the artifact specified by the reference under the project and repository. The reference can be digest or tag
   * @param params The `ArtifactService.DeleteArtifactParams` containing the following parameters:
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `reference`: The reference of the artifact, can be digest or tag
   *
   * - `project_name`: The name of the project
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  deleteArtifactResponse(params: ArtifactService.DeleteArtifactParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/projects/${params.projectName}/repositories/${params.repositoryName}/artifacts/${params.reference}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete the artifact specified by the reference under the project and repository. The reference can be digest or tag
   * @param params The `ArtifactService.DeleteArtifactParams` containing the following parameters:
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `reference`: The reference of the artifact, can be digest or tag
   *
   * - `project_name`: The name of the project
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  deleteArtifact(params: ArtifactService.DeleteArtifactParams): __Observable<null> {
    return this.deleteArtifactResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Create a tag for the specified artifact
   * @param params The `ArtifactService.CreateTagParams` containing the following parameters:
   *
   * - `tag`: The JSON object of tag.
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `reference`: The reference of the artifact, can be digest or tag
   *
   * - `project_name`: The name of the project
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  createTagResponse(params: ArtifactService.CreateTagParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.tag;



    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/projects/${params.projectName}/repositories/${params.repositoryName}/artifacts/${params.reference}/tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Create a tag for the specified artifact
   * @param params The `ArtifactService.CreateTagParams` containing the following parameters:
   *
   * - `tag`: The JSON object of tag.
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `reference`: The reference of the artifact, can be digest or tag
   *
   * - `project_name`: The name of the project
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  createTag(params: ArtifactService.CreateTagParams): __Observable<null> {
    return this.createTagResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List tags of the specific artifact
   * @param params The `ArtifactService.ListTagsParams` containing the following parameters:
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `reference`: The reference of the artifact, can be digest or tag
   *
   * - `project_name`: The name of the project
   *
   * - `with_signature`: Specify whether the signature is included inside the returning tags
   *
   * - `with_immutable_status`: Specify whether the immutable status is included inside the returning tags
   *
   * - `q`: Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
   *
   * - `page_size`: The size of per page
   *
   * - `page`: The page number
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  listTagsResponse(params: ArtifactService.ListTagsParams): __Observable<__StrictHttpResponse<Array<Tag>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.withSignature != null) __params = __params.set('with_signature', params.withSignature.toString());
    if (params.withImmutableStatus != null) __params = __params.set('with_immutable_status', params.withImmutableStatus.toString());
    if (params.q != null) __params = __params.set('q', params.q.toString());
    if (params.pageSize != null) __params = __params.set('page_size', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/projects/${params.projectName}/repositories/${params.repositoryName}/artifacts/${params.reference}/tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Tag>>;
      })
    );
  }
  /**
   * List tags of the specific artifact
   * @param params The `ArtifactService.ListTagsParams` containing the following parameters:
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `reference`: The reference of the artifact, can be digest or tag
   *
   * - `project_name`: The name of the project
   *
   * - `with_signature`: Specify whether the signature is included inside the returning tags
   *
   * - `with_immutable_status`: Specify whether the immutable status is included inside the returning tags
   *
   * - `q`: Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
   *
   * - `page_size`: The size of per page
   *
   * - `page`: The page number
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  listTags(params: ArtifactService.ListTagsParams): __Observable<Array<Tag>> {
    return this.listTagsResponse(params).pipe(
      __map(_r => _r.body as Array<Tag>)
    );
  }

  /**
   * Delete the tag of the specified artifact
   * @param params The `ArtifactService.DeleteTagParams` containing the following parameters:
   *
   * - `tag_name`: The name of the tag
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `reference`: The reference of the artifact, can be digest or tag
   *
   * - `project_name`: The name of the project
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  deleteTagResponse(params: ArtifactService.DeleteTagParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/projects/${params.projectName}/repositories/${params.repositoryName}/artifacts/${params.reference}/tags/${params.tagName}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete the tag of the specified artifact
   * @param params The `ArtifactService.DeleteTagParams` containing the following parameters:
   *
   * - `tag_name`: The name of the tag
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `reference`: The reference of the artifact, can be digest or tag
   *
   * - `project_name`: The name of the project
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  deleteTag(params: ArtifactService.DeleteTagParams): __Observable<null> {
    return this.deleteTagResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the addition of the artifact specified by the reference under the project and repository.
   * @param params The `ArtifactService.GetAdditionParams` containing the following parameters:
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `reference`: The reference of the artifact, can be digest or tag
   *
   * - `project_name`: The name of the project
   *
   * - `addition`: The type of addition.
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  getAdditionResponse(params: ArtifactService.GetAdditionParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/projects/${params.projectName}/repositories/${params.repositoryName}/artifacts/${params.reference}/additions/${params.addition}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Get the addition of the artifact specified by the reference under the project and repository.
   * @param params The `ArtifactService.GetAdditionParams` containing the following parameters:
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `reference`: The reference of the artifact, can be digest or tag
   *
   * - `project_name`: The name of the project
   *
   * - `addition`: The type of addition.
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  getAddition(params: ArtifactService.GetAdditionParams): __Observable<string> {
    return this.getAdditionResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Add label to the specified artiact.
   * @param params The `ArtifactService.AddLabelParams` containing the following parameters:
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `reference`: The reference of the artifact, can be digest or tag
   *
   * - `project_name`: The name of the project
   *
   * - `label`: The label that added to the artifact. Only the ID property is needed.
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  addLabelResponse(params: ArtifactService.AddLabelParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    __body = params.label;
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/projects/${params.projectName}/repositories/${params.repositoryName}/artifacts/${params.reference}/labels`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Add label to the specified artiact.
   * @param params The `ArtifactService.AddLabelParams` containing the following parameters:
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `reference`: The reference of the artifact, can be digest or tag
   *
   * - `project_name`: The name of the project
   *
   * - `label`: The label that added to the artifact. Only the ID property is needed.
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  addLabel(params: ArtifactService.AddLabelParams): __Observable<null> {
    return this.addLabelResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Remove the label from the specified artiact.
   * @param params The `ArtifactService.RemoveLabelParams` containing the following parameters:
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `reference`: The reference of the artifact, can be digest or tag
   *
   * - `project_name`: The name of the project
   *
   * - `label_id`: The ID of the label that removed from the artifact.
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  removeLabelResponse(params: ArtifactService.RemoveLabelParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/projects/${params.projectName}/repositories/${params.repositoryName}/artifacts/${params.reference}/labels/${params.labelId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Remove the label from the specified artiact.
   * @param params The `ArtifactService.RemoveLabelParams` containing the following parameters:
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `reference`: The reference of the artifact, can be digest or tag
   *
   * - `project_name`: The name of the project
   *
   * - `label_id`: The ID of the label that removed from the artifact.
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  removeLabel(params: ArtifactService.RemoveLabelParams): __Observable<null> {
    return this.removeLabelResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ArtifactService {

  /**
   * Parameters for listArtifacts
   */
  export interface ListArtifactsParams {

    /**
     * The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
     */
    repositoryName: string;

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * Specify whether the tags are included inside the returning artifacts
     */
    withTag?: boolean;

    /**
     * Specify whether the signature is included inside the tags of the returning artifacts. Only works when setting "with_tag=true"
     */
    withSignature?: boolean;

    /**
     * Specify whether the scan overview is included inside the returning artifacts
     */
    withScanOverview?: boolean;

    /**
     * Specify whether the labels are included inside the returning artifacts
     */
    withLabel?: boolean;

    /**
     * Specify whether the immutable status is included inside the tags of the returning artifacts. Only works when setting "with_tag=true"
     */
    withImmutableStatus?: boolean;

    /**
     * Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
     */
    q?: string;

    /**
     * The size of per page
     */
    pageSize?: number;

    /**
     * The page number
     */
    page?: number;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for CopyArtifact
   */
  export interface CopyArtifactParams {

    /**
     * The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
     */
    repositoryName: string;

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * The artifact from which the new artifact is copied from, the format should be "project/repository:tag" or "project/repository@digest".
     */
    from: string;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for getArtifact
   */
  export interface GetArtifactParams {

    /**
     * The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
     */
    repositoryName: string;

    /**
     * The reference of the artifact, can be digest or tag
     */
    reference: string;

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * Specify whether the tags are inclued inside the returning artifacts
     */
    withTag?: boolean;

    /**
     * Specify whether the signature is inclued inside the returning artifacts
     */
    withSignature?: boolean;

    /**
     * Specify whether the scan overview is inclued inside the returning artifacts
     */
    withScanOverview?: boolean;

    /**
     * Specify whether the labels are inclued inside the returning artifacts
     */
    withLabel?: boolean;

    /**
     * Specify whether the immutable status is inclued inside the tags of the returning artifacts. Only works when setting "with_tag=true"
     */
    withImmutableStatus?: boolean;

    /**
     * The size of per page
     */
    pageSize?: number;

    /**
     * The page number
     */
    page?: number;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for deleteArtifact
   */
  export interface DeleteArtifactParams {

    /**
     * The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
     */
    repositoryName: string;

    /**
     * The reference of the artifact, can be digest or tag
     */
    reference: string;

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for createTag
   */
  export interface CreateTagParams {

    /**
     * The JSON object of tag.
     */
    tag: Tag;

    /**
     * The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
     */
    repositoryName: string;

    /**
     * The reference of the artifact, can be digest or tag
     */
    reference: string;

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for listTags
   */
  export interface ListTagsParams {

    /**
     * The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
     */
    repositoryName: string;

    /**
     * The reference of the artifact, can be digest or tag
     */
    reference: string;

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * Specify whether the signature is included inside the returning tags
     */
    withSignature?: boolean;

    /**
     * Specify whether the immutable status is included inside the returning tags
     */
    withImmutableStatus?: boolean;

    /**
     * Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
     */
    q?: string;

    /**
     * The size of per page
     */
    pageSize?: number;

    /**
     * The page number
     */
    page?: number;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for deleteTag
   */
  export interface DeleteTagParams {

    /**
     * The name of the tag
     */
    tagName: string;

    /**
     * The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
     */
    repositoryName: string;

    /**
     * The reference of the artifact, can be digest or tag
     */
    reference: string;

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for getAddition
   */
  export interface GetAdditionParams {

    /**
     * The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
     */
    repositoryName: string;

    /**
     * The reference of the artifact, can be digest or tag
     */
    reference: string;

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * The type of addition.
     */
    addition: 'build_history' | 'values.yaml' | 'readme.md' | 'dependencies' | 'vulnerabilities';

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for addLabel
   */
  export interface AddLabelParams {

    /**
     * The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
     */
    repositoryName: string;

    /**
     * The reference of the artifact, can be digest or tag
     */
    reference: string;

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * The label that added to the artifact. Only the ID property is needed.
     */
    label: Label;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for removeLabel
   */
  export interface RemoveLabelParams {

    /**
     * The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
     */
    repositoryName: string;

    /**
     * The reference of the artifact, can be digest or tag
     */
    reference: string;

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * The ID of the label that removed from the artifact.
     */
    labelId: number;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }
}

export { ArtifactService }
