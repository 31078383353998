<section class="overview-section">
  <div class="title-wrapper">
    <div class="title-block arrow-block" *ngIf="withAdmiral">
      <clr-icon class="rotate-90 arrow-back" shape="arrow" size="36" (click)="goBack()"></clr-icon>
    </div>
    <div class="title-block">
      <h2 sub-header-title class="custom-h2" *ngIf="!artifactDigest">{{showCurrentTitle}}</h2>
      <h2 sub-header-title class="custom-h2" *ngIf="artifactDigest">{{artifactDigest | slice:0:15}}</h2>
    </div>
  </div>
</section>

<section class="detail-section">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <span class="spinner spinner-inline" [hidden]="inProgress === false"></span>
    <ul id="configTabs" class="nav" role="tablist">
      <li role="presentation" class="nav-item"  *ngIf="!artifactDigest">
        <button id="repo-info" class="btn btn-link nav-link" aria-controls="info" [class.active]='isCurrentTabLink("repo-info")'
          type="button" (click)='tabLinkClick("repo-info")'>{{'REPOSITORY.INFO' | translate}}</button>
      </li>
      <li role="presentation" class="nav-item">
        <button id="repo-image" class="btn btn-link nav-link active" aria-controls="image" [class.active]='isCurrentTabLink("repo-image")'
          type="button" (click)='tabLinkClick("repo-image")'>{{'REPOSITORY.ARTIFACTS' | translate}}</button>
      </li>
    </ul>
    <section id="info" role="tabpanel" aria-labelledby="repo-info" [hidden]='!isCurrentTabContent("info")'>
      <form #repoInfoForm="ngForm">
        <div id="info-edit-button">
          <button class="btn " [disabled]="editing || !hasProjectAdminRole " (click)="editInfo()">
            <clr-icon shape="pencil" size="16"></clr-icon>&nbsp;{{'BUTTON.EDIT' | translate}}
          </button>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 1024" preserveAspectRatio="xMinYMin" class="markdown">
            <path d="M950.154 192H73.846C33.127 192 0 225.12699999999995 0 265.846v492.308C0 798.875 33.127 832 73.846 832h876.308c40.721 0 73.846-33.125 73.846-73.846V265.846C1024 225.12699999999995 990.875 192 950.154 192zM576 703.875L448 704V512l-96 123.077L256 512v192H128V320h128l96 128 96-128 128-0.125V703.875zM767.091 735.875L608 512h96V320h128v192h96L767.091 735.875z" />
          </svg>
          <span>{{ 'REPOSITORY.MARKDOWN' | translate }}</span>
        </div>
        <div id="no-editing" *ngIf="!editing">
          <div *ngIf="!hasInfo()" class="no-info-div">
            <p>{{'REPOSITORY.NO_INFO' | translate }}<p>
          </div>
          <div *ngIf="hasInfo()" class="info-div">
            <div class="info-pre" [innerHTML]="imageInfo | markdown"></div>
          </div>
        </div>
        <div *ngIf="editing">
          <textarea id="info-edit-textarea" class="clr-textarea w-100" rows="5" name="info-edit-textarea"
            [(ngModel)]="imageInfo"></textarea>
        </div>
        <div class="" *ngIf="editing">
          <button id="edit-save" class="btn btn-primary" [disabled]="!hasChanges()" (click)="saveInfo()">{{'BUTTON.SAVE' | translate}}</button>
          <button id="edit-cancel" class="btn" (click)="cancelInfo()">{{'BUTTON.CANCEL' | translate}}</button>
        </div>
        <confirmation-dialog #confirmationDialog (confirmAction)="confirmCancel($event)"></confirmation-dialog>
      </form>
    </section>
    <section id="image" role="tabpanel" aria-labelledby="repo-image" [hidden]='!isCurrentTabContent("image")'>
      <div id="images-container">
        <artifact-list-tab ngProjectAs="clr-dg-row-detail"
          class="sub-grid-custom" [repoName]="repoName" artifact [registryUrl]="registryUrl" [withNotary]="withNotary" [withAdmiral]="withAdmiral" [hasSignedIn]="hasSignedIn"
          [isGuest]="isGuest" [projectId]="projectId" [memberRoleID]="memberRoleID"></artifact-list-tab>
      </div>
    </section>
  </div>
</section>
