<div class="clr-row pt-1 fw8">
    <div class="clr-col">
        <label class="label-left font-size-54">{{'IMMUTABLE_TAG.IMMUTABLE_RULES' | translate}}</label><span class="badge badge-3 ml-5">{{rules?.length ? rules?.length : 0}}/15</span>
        <span *ngIf="loadingRule" class="spinner spinner-inline ml-2">Loading...</span>
    </div>
</div>
<div class="clr-row pt-1">
    <div class="clr-col">
        <ul *ngIf="rules?.length > 0" class="list-unstyled">
            <li class="rule" *ngFor="let rule of rules;let i = index;">
                <div class="clr-row">
                    <div class="clr-col-2 flex-150">
                        <div class="dropdown" [ngClass]="{open:ruleIndex===i}">
                            <button (click)="openEditor(i)" id="{{'action'+i}}" class="dropdown-toggle btn btn-sm btn-link ">
                                {{'IMMUTABLE_TAG.ACTION' | translate}}
                                <clr-icon shape="caret down"></clr-icon>
                            </button>
                            <div class="dropdown-menu">
                                <button *ngIf="!rule?.disabled" type="button" id="{{'disable-btn'+i}}" class="dropdown-item" (click)="toggleDisable(rule,true)">{{'IMMUTABLE_TAG.DISABLE' | translate}}</button>
                                <button *ngIf="rule?.disabled" type="button" class="dropdown-item" (click)="toggleDisable(rule,false)">{{'IMMUTABLE_TAG.ENABLE' | translate}}</button>
                                <button type="button" id="{{'edit-btn'+i}}" class="dropdown-item" (click)="editRuleByIndex(i)">{{'IMMUTABLE_TAG.EDIT' | translate}}</button>
                                <button type="button" class="dropdown-item" id="{{'delete-btn'+i}}" (click)="deleteRule(rule.id)">{{'IMMUTABLE_TAG.DELETE' | translate}}</button>
                            </div>
                        </div>
                    </div>
                    <div class="clr-col">
                        <span>
                           <clr-icon *ngIf="!rule?.disabled" class="color-green color-white-dark" shape="success-standard"></clr-icon>
                           <clr-icon id="{{'disable-icon'+i}}" *ngIf="rule?.disabled" class="color-red" shape="error-standard"></clr-icon>
                        </span>
                        <span class="rule-name ml-5">
                            <span>{{'IMMUTABLE_TAG.IN_REPOSITORIES' | translate}}</span>
                        <span>{{getI18nKey(rule?.scope_selectors?.repository[0]?.decoration)|translate}}</span>
                        <span>{{formatPattern(rule?.scope_selectors?.repository[0]?.pattern)}}</span>
                        <span>,</span>
                        <span>{{'IMMUTABLE_TAG.LOWER_TAGS' | translate}}</span> 
                        <span>{{getI18nKey(rule?.tag_selectors[0]?.decoration)|translate}}</span>
                        <span id="{{'tag-selectors-patten'+i}}">{{formatPattern(rule?.tag_selectors[0]?.pattern)}}</span>
                        <ng-container *ngIf="rule?.tag_selectors[1]?.pattern && rule?.tag_selectors[1]?.pattern">
                            <span class="color-97">{{'IMMUTABLE_TAG.AND' | translate}}</span>
                            <span>{{'IMMUTABLE_TAG.LOWER_LABELS' | translate}}</span>
                            <span>{{getI18nKey(rule?.tag_selectors[1]?.decoration)|translate}}</span>
                            <span>{{rule?.tag_selectors[1]?.pattern}}</span>
                        </ng-container>
                        </span>
                    </div>
                </div>
            </li>
        </ul>
        <div class="v-center clr-row" [ngClass]="{'pt-1':rules?.length > 0}">
            <div class="clr-col-2 flex-150"></div>
            <div class="add-rule-btn">
                <button [disabled]="rules?.length >= 15" id="add-rule" class="btn btn-primary " (click)="openAddRule()">{{'IMMUTABLE_TAG.ADD_RULE' | translate}}</button>
            </div>
        </div>
    </div>
</div>

<app-add-rule #addRule [rules]="rules" [projectId]="projectId" (clickAdd)="clickAdd($event)"></app-add-rule>
<div class="backdrop-transparent" (click)="ruleIndex = -1" *ngIf="ruleIndex !== -1"></div>
