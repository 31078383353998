<h4>{{'REPOSITORY.TAGS' | translate}}</h4>

<clr-datagrid [clrDgLoading]="loading" (clrDgRefresh)="getCurrentArtifactTags($event)"  [(clrDgSelected)]="selectedRow">
    <clr-dg-action-bar>
        <button *ngIf="!isProxyCacheProject" id="new-tag" type="button" [disabled]="!hasCreateTagPermission" class="btn btn-secondary" (click)="addTag()">
            <clr-icon shape="plus" size="16"></clr-icon>&nbsp;{{'TAG.ADD_TAG' | translate}}
        </button>
        <button id="delete-tag" type="button" class="btn btn-secondary" [disabled]="!(selectedRow.length>=1&& !hasImmutableOnTag() && hasDeleteTagPermission)" (click)="removeTag()">
            <clr-icon shape="trash" size="16"></clr-icon>&nbsp;{{'TAG.REMOVE_TAG' | translate}}
        </button>
        <span class="refresh-btn" (click)="refresh()">
            <clr-icon shape="refresh"></clr-icon>
        </span>
        <form #tagForm="ngForm" [hidden]="!newTagformShow" class="label-form stack-block-label">
            <section>
                <label>
                    <label for="name">{{'TAG.NAME' | translate}}</label>
                    <label class="clr-control-container" [class.clr-error]="isTagNameExist || name.hasError('pattern')">
                        <input clrInput type="text" id="name" name="name" required size="20" autocomplete="off"
                            [(ngModel)]="newTagName.name" #name="ngModel" pattern="^[\w][\w.-]{0,127}$" (keyup)="existValid(newTagName.name)">
                        <span class="spinner spinner-inline spinner-tag" [hidden]="!tagNameCheckOnGoing"></span>

                        <clr-control-error class="position-ab white-space-nowrap" *ngIf="isTagNameExist">
                            {{'TAG.NAME_ALREADY_EXISTS' | translate }}
                        </clr-control-error>
                        <clr-control-error class="position-ab white-space-nowrap" *ngIf="name.hasError('pattern')">
                            {{'RETAG.TIP_TAG' | translate }}
                        </clr-control-error>
                    </label>
                </label>
                <label class="ml-1">
                    <button type="button" class="btn btn-sm btn-outline" (click)="cancelAddTag()">{{
                                        'BUTTON.CANCEL' | translate }}
                    </button>
                    <button id="add-ok" type="submit" class="btn btn-sm btn-primary" (click)="saveAddTag()"
                        [disabled]="isTagNameExist || tagNameCheckOnGoing || !newTagName.name ||tagForm.invalid">{{
                                        'BUTTON.OK' | translate }}
                    </button>
                </label>
            </section>
        </form>
    </clr-dg-action-bar>
    <clr-dg-column [clrDgField]="'name'">{{'TAG.NAME' | translate}}</clr-dg-column>
    <clr-dg-column *ngIf="hasPullCommand()">{{'REPOSITORY.PULL_COMMAND' | translate}}</clr-dg-column>
    <clr-dg-column *ngIf="withNotary">{{'REPOSITORY.SIGNED' | translate}}</clr-dg-column>
    <clr-dg-column>{{'TAG.PULL_TIME' | translate}}</clr-dg-column>
    <clr-dg-column>{{'TAG.PUSH_TIME' | translate}}</clr-dg-column>

    <clr-dg-row *ngFor="let tag of currentTags" [clrDgItem]="tag">
        <clr-dg-cell>
            <div class="cell white-normal" [class.immutable]="tag.immutable">
                <span href="javascript:void(0)" class="max-width-100" title="{{tag.name}}">{{tag.name}}</span>
                <span *ngIf="tag.immutable" class="label label-info ml-8">{{'REPOSITORY.IMMUTABLE' | translate}}</span>
            </div>
        </clr-dg-cell>
        <clr-dg-cell>
            <hbr-copy-input [title]="getPullCommand(tag)"  *ngIf="getPullCommand(tag)"  [iconMode]="true" [defaultValue]="getPullCommand(tag)"></hbr-copy-input>
        </clr-dg-cell>
        <clr-dg-cell  *ngIf="withNotary" [ngSwitch]="tag.signed">
            <div class="cell">
                <clr-icon shape="check-circle" *ngSwitchCase="true" size="20" class="color-green"></clr-icon>
                <clr-icon shape="times-circle" *ngSwitchCase="false" size="16" class="color-red"></clr-icon>
                <a href="javascript:void(0)" *ngSwitchDefault role="tooltip" aria-haspopup="true" class="tooltip tooltip-top-right">
                    <clr-icon shape="help" class="color-gray" size="16"></clr-icon>
                    <span class="tooltip-content">{{'REPOSITORY.NOTARY_IS_UNDETERMINED' | translate}}</span>
                </a>
            </div>
        </clr-dg-cell>
        <clr-dg-cell>{{tag.pull_time !== availableTime? (tag.pull_time | date: 'short') : ""}}</clr-dg-cell>
        <clr-dg-cell>{{tag.push_time | date: 'short'}}</clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
        <span *ngIf="totalCount">{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
        {{'TAG.OF' | translate}} {{totalCount}} {{'TAG.ITEMS' | translate}}</span>
        <clr-dg-pagination #pagination [clrDgTotalItems]="totalCount" [(clrDgPage)]="currentPage" [clrDgPageSize]="pageSize"></clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>

<confirmation-dialog class="hidden-tag" #confirmationDialog (confirmAction)="confirmDeletion($event)">
</confirmation-dialog>
