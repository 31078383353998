import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tag-feature-integration',
  templateUrl: './tag-feature-integration.component.html',
  styleUrls: ['./tag-feature-integration.component.scss']
})
export class TagFeatureIntegrationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
