<div class="row">
    <div class="col-md-12">
        <p>{{summary.description}}</p>
    </div>
</div>
<div class="row content-wrapper">
    <div class="col-md-8 md-container pl-1">
        <div *ngIf="readme" class="md-div" [innerHTML]="readme | markdown"></div>
        <div *ngIf="!readme">{{'HELM_CHART.NO_README' | translate}}</div>
    </div>
    <div class="col-md-4 summary-container mt-1">
        <div class="col-md-12 content-group">
            <div>
                <label>{{'HELM_CHART.OVERVIEW' | translate }}</label>
            </div>
            <table class="table">
                <tbody>
                    <tr>
                        <td class="left">{{'HELM_CHART.HOME' | translate }}</td>
                        <td class="left text-wrapper">
                            <a href="{{summary.home}}">{{summary.home}}</a>
                        </td>
                    </tr>
                    <tr *ngFor="let src of summary.sources; let i = index">
                        <td class="left" *ngIf="i === 0">{{'HELM_CHART.SRC_REPO' | translate }}</td>
                        <td class="left" *ngIf="i !== 0"></td>
                        <td class="left text-wrapper">
                            <a href="{{src}}">{{src}}</a>
                        </td>
                    </tr>
                    <tr>
                        <td class="left">{{'HELM_CHART.CREATED' | translate }}</td>
                        <td class="left">{{summary.created | date}}</td>
                    </tr>
                    <tr *ngFor="let maintainer of summary.maintainers; let i = index">
                        <td class="left" *ngIf="i === 0">{{'HELM_CHART.MAINTAINERS' | translate }}</td>
                        <td class="left" *ngIf="i !== 0"></td>
                        <td class="left">
                            <a href="mailto:{{maintainer.email}}">{{maintainer.name}}</a>
                        </td>
                    </tr>
                    <tr>
                        <td class="left">{{'HELM_CHART.APP_VERSION' | translate }}</td>
                        <td class="left">{{ summary.appVersion }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-12 content-group">
            <div>
                <label>{{'HELM_CHART.COMMAND' | translate }}</label>
            </div>
            <table class="table">
                <tbody>
                    <tr>
                        <td class="left cmd-title">{{'HELM_CHART.ADD_REPO' | translate }}</td>
                        <td class="left cmd-content">
                            <input class="cmd-content clr-input" type="text" [(ngModel)]="addCMD" #addCMDInput readonly />
                        </td>
                        <td class="left">
                            <span>
                                <clr-icon shape="copy" size="24" [class.is-success]="isCopied('add')" [ngxClipboard]="addCMDInput"
                                    (cbOnSuccess)="onCopySuccess($event, 'add')"></clr-icon>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td class="left cmd-title">{{'HELM_CHART.INSTALL_CHART' | translate }}</td>
                        <td class="left">
                            <input class="cmd-content clr-input" type="text" [(ngModel)]="installCMD" #installCMDInput readonly />
                        </td>
                        <td class="left">
                            <span>
                                <clr-icon shape="copy" size="24" [class.is-success]="isCopied('install')"
                                    [ngxClipboard]="installCMDInput" (cbOnSuccess)="onCopySuccess($event, 'install')"></clr-icon>
                            </span>
                        </td>
                    </tr>
                    <tr *ngIf="prov_ready">
                        <td class="left cmd-title">{{'HELM_CHART.VERIFY_CHART' | translate }}</td>
                        <td class="left">
                            <input class="cmd-content clr-input" type="text" [(ngModel)]="verifyCMD" #verifyCMDInput readonly />
                        </td>
                        <td class="left">
                            <span>
                                <clr-icon shape="copy" size="24" [class.is-success]="isCopied('verify')" [ngxClipboard]="verifyCMDInput"
                                    (cbOnSuccess)="onCopySuccess($event, 'verify')"></clr-icon>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="col-md-12 content-group">
            <div>
                <label>{{'HELM_CHART.SECURITY' | translate }}</label>
            </div>
            <table class="table">
                <tbody>
                    <tr>
                        <td class="left">{{'HELM_CHART.PROV_FILE' | translate }}</td>
                        <div *ngIf="prov_ready;then signedContent else unsignedContent"></div>
                        <ng-template #signedContent>
                            <td class="left">
                                <span class="content-icon">
                                    <clr-icon shape="shield-check" class="is-success"></clr-icon>
                                </span>&nbsp;<a href="javascript:void(0)" (click)="downloadChart()">{{'HELM_CHART.READY'
                                    | translate }}</a>
                            </td>
                        </ng-template>
                        <ng-template #unsignedContent>
                            <td class="left">
                                <span class="content-icon">
                                    <clr-icon shape="shield-x" class="is-error"></clr-icon>
                                </span>&nbsp;{{'HELM_CHART.NOT_READY' | translate }}</td>
                        </ng-template>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="col-md-12 content-group">
            <div>
                <label>{{'HELM_CHART.LABELS' | translate }}</label>
            </div>
            <table class="table">
                <tbody>
                    <tr>
                        <td class="left">{{'HELM_CHART.LABELS' | translate }}</td>
                        <td class="left">{{labels?.length}}</td>
                    </tr>
                    <tr *ngIf="labels?.length > 0">
                        <td></td>
                        <td class="left">
                            <hbr-label-piece *ngFor="let label of labels" [label]="label" [labelWidth]="90">
                            </hbr-label-piece>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>
