<clr-modal [(clrModalOpen)]="opened" [clrModalStaticBackdrop]="true" [clrModalClosable]="false">
    <h3 class="modal-title">{{'CHANGE_PWD.TITLE' | translate}}</h3>
    <div class="modal-body body-format">
        <inline-alert (confirmEvt)="confirmCancel($event)"></inline-alert>
        <form #changepwdForm="ngForm" clrForm>
            <!-- <clr-input-container>
                <label class="required">{{'CHANGE_PWD.CURRENT_PWD' | translate}}</label>
                <input clrInput type="password" id="oldPassword" required name="oldPassword" [(ngModel)]="oldPwd" #oldPassInput="ngModel"
                    size="40">
                <clr-control-error *ngIf="oldPassInput.invalid && (oldPassInput.dirty || oldPassInput.touched)">
                    {{'TOOLTIP.CURRENT_PWD' | translate}}
                </clr-control-error>
            </clr-input-container> -->
            <clr-input-container>
                <label class="required">{{'CHANGE_PWD.NEW_PWD' | translate}}</label>
                <input clrInput type="password" id="newPassword" required pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,128}$" name="newPassword"
                    [(ngModel)]="newPwd" #newPassInput="ngModel" size="40" (input)='handleValidation("newPassword", false)' (blur)='handleValidation("newPassword", true)'>
                <clr-control-helper>{{'CHANGE_PWD.PASS_TIPS' | translate}}</clr-control-helper>
                <clr-control-error *ngIf="!getValidationState('newPassword')">
                    {{'TOOLTIP.PASSWORD' | translate}}
                </clr-control-error>
            </clr-input-container>
            <clr-input-container>
                <label class="required">{{'CHANGE_PWD.CONFIRM_PWD' | translate}}</label>
                <input clrInput type="password" id="reNewPassword" required pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,128}$" name="reNewPassword"
                    [(ngModel)]="reNewPwd" #reNewPassInput="ngModel" size="40" (input)='handleValidation("reNewPassword", false)'
                    (blur)='handleValidation("reNewPassword", true)'>
                <clr-control-error *ngIf='!getValidationState("reNewPassword")'>
                    {{'TOOLTIP.CONFIRM_PWD' | translate}}
                </clr-control-error>
            </clr-input-container>
        </form>
    </div>
    <div class="modal-footer">
        <span class="spinner spinner-inline loading-top" [hidden]="showProgress === false"></span>
        <button type="button" class="btn btn-outline" id="cancel-btn" (click)="close()">{{'BUTTON.CANCEL' | translate}}</button>
        <button type="button" class="btn btn-primary" id="ok-btn" [disabled]="!isValid || showProgress" (click)="doOk()">{{'BUTTON.OK' | translate}}</button>
    </div>
</clr-modal>
