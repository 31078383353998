<div class="summary summary-dark display-flex" *ngIf="summaryInformation">
  <div class="summary-left">
    <div class="display-flex project-detail pt-05" *ngIf="summaryInformation?.registry">
      <h5 class="mt-0 width-7-5">{{'PROJECT.PROXY_CACHE_ENDPOINT' | translate}}</h5>
      <ul class="list-unstyled">
        <li id="endpoint">{{summaryInformation?.registry?.name}}-{{summaryInformation?.registry?.url}}</li>
      </ul>
    </div>
    <div class="display-flex project-detail pt-05">
      <h5 class="mt-0 width-7-5">{{'SUMMARY.PROJECT_REPOSITORY' | translate}}</h5>
      <ul class="list-unstyled">
        <li>{{summaryInformation?.repo_count}}</li>
      </ul>
    </div>
    <div class="display-flex project-detail pt-05" *ngIf="withHelmChart">
      <h5 class="mt-0 width-7-5">{{'SUMMARY.PROJECT_HELM_CHART' | translate}}</h5>
      <ul class="list-unstyled">
        <li>{{summaryInformation?.chart_count}}</li>
      </ul>
    </div>
    <div *ngIf="showProjectMemberInfo" class="display-flex project-detail pt-05">
      <h5 class="mt-0 width-7-5">{{'SUMMARY.PROJECT_MEMBER' | translate}}</h5>
      <ul class="list-unstyled">
        <li>{{ summaryInformation?.project_admin_count }} {{'SUMMARY.ADMIN' | translate}}</li>
        <li>{{ summaryInformation?.maintainer_count }} {{'SUMMARY.MAINTAINER' | translate}}</li>
        <li>{{ summaryInformation?.developer_count }} {{'SUMMARY.DEVELOPER' | translate}}</li>
        <li>{{ summaryInformation?.guest_count }} {{'SUMMARY.GUEST' | translate}}</li>
        <li>{{ summaryInformation?.limited_guest_count }} {{'SUMMARY.LIMITED_GUEST' | translate}}</li>
      </ul>
    </div>
  </div>
  <div *ngIf="showQuotaInfo && summaryInformation?.quota" class="summary-right pt-05">
    <div class="display-flex  project-detail">
      <h5 class="mt-0">{{'SUMMARY.PROJECT_QUOTAS' | translate}}</h5>
      <div class="ml-1">
        <div class="display-flex quotas-progress">
          <label class="mr-1">{{'SUMMARY.STORAGE_CONSUMPTION' | translate}}</label>
          <label class="progress-label">
              {{ summaryInformation?.quota?.hard?.storage !== -1 ?(getIntegerAndUnit(summaryInformation?.quota?.hard?.storage, summaryInformation?.quota?.used?.storage).partNumberUsed
                + getIntegerAndUnit(summaryInformation?.quota?.hard?.storage, summaryInformation?.quota?.used?.storage).partCharacterUsed) : getSuitableUnit(summaryInformation?.quota?.used?.storage)}}

            <!-- {{ getSuitableUnit(summaryInformation?.quota?.used?.storage) }}  -->
            {{ 'QUOTA.OF' | translate }}
            {{ summaryInformation?.quota?.hard?.storage ===-1? ('QUOTA.UNLIMITED' | translate) : getIntegerAndUnit(summaryInformation?.quota?.hard?.storage, summaryInformation?.quota?.used?.storage).partNumberHard }}
             {{ summaryInformation?.quota?.hard?.storage ===-1? '':  getIntegerAndUnit(summaryInformation?.quota?.hard?.storage, summaryInformation?.quota?.used?.storage).partCharacterHard }}
          </label>
        </div>

        <div>
          <div class="progress-block progress-min-width progress-div">
            <div class="progress success"
            [class.danger]="summaryInformation?.quota?.hard?.storage!==-1?summaryInformation?.quota?.used?.storage/summaryInformation?.quota?.hard?.storage>quotaDangerCoefficient:false"
            [class.warning]="summaryInformation?.quota?.hard?.storage!==-1?summaryInformation?.quota?.used?.storage/summaryInformation?.quota?.hard?.storage<=quotaDangerCoefficient&&summaryInformation?.quota?.used?.storage/summaryInformation?.quota?.hard?.storage>=quotaWarningCoefficient:false">
              <progress
                value="{{summaryInformation?.quota?.hard?.storage===-1? 0 : summaryInformation?.quota?.used?.storage}}"
                max="{{summaryInformation?.quota?.hard?.storage}}" data-displayval="100%"></progress>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
