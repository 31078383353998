<span *ngIf="onGettingUpdatedTimeStr || onGoing" class="mt-1 ml-1 spinner spinner-inline"></span>
<ng-container *ngIf="!(onGettingUpdatedTimeStr || onGoing)">
    <section class="form-block">
     <div *ngIf="updatedTimeStr" class="mt-1 display-flex">
         <label  class="update-time">{{ 'CONFIG.SCANNING.DB_REFRESH_TIME' | translate }}</label>
         <span>{{ updatedTimeStr }} </span>
     </div>
    <div class="button-group">
        <cron-selection [disabled]="!hasDefaultScanner" [labelCurrent]="getLabelCurrent" [labelEdit]='getLabelCurrent'
                        [originCron]='originCron' (inputvalue)="saveSchedule($event)"></cron-selection>
    </div>
</section>
    <div class="clr-row">
    <div class="clr-col-2 flex-200">
        <div class="btn-scan-right btn-scan margin-top-16px">
            <button id="scan-now" class="btn btn-primary  btn-scan" (click)="scanNow()"
                    [disabled]="!scanAvailable">
                <span *ngIf="scanAvailable">{{ 'CONFIG.SCANNING.SCAN_NOW' | translate }}</span>
                <span *ngIf="!scanAvailable">{{ 'CONFIG.SCANNING.SCAN' | translate }}</span>
            </button>
            <span [hidden]="!isOnScanning()" class="spinner spinner-inline margin-left-5 v-mid"></span>
        </div>
    </div>
    <div class="clr-col" *ngIf="scanningMetrics && scanningMetrics.total">
        <div class="total" [style.width]="totalWidth+'px'">
            <span class="float-left" *ngIf="!scanningMetrics?.isScheduled">{{ 'CONFIG.SCANNING.MANUAL' | translate }}</span>
            <span class="float-left" *ngIf="scanningMetrics?.isScheduled">{{ 'CONFIG.SCANNING.SCHEDULED' | translate }}</span>
            <span>{{ 'SCANNER.TOTAL' | translate }}</span>
            <span class="margin-left-5">{{scanningMetrics?.total}}</span>
        </div>
        <div class="container" id="scan-result-container" [style.width]="totalWidth+'px'">
            <div class="error h-100" [style.width]="errorWidth()"></div>
            <div class="finished h-100" [style.width]="finishedWidth()"></div>
            <div class="in-progress h-100" [style.width]="runningWidth()"></div>
        </div>
        <div class="state-container" [style.width]="totalWidth+'px'">
            <div class="clr-row m-0" *ngFor="let item of scanningMetrics?.metrics | keyvalue">
                <div class="state">
                    <span class="badge" [ngClass]="{ error: isError(item?.key),
                                                    finished: isFinished(item?.key),
                                                   'in-progress': isInProgress(item?.key)}">{{item?.value}}</span>
                </div>
                <div class="value">{{getI18nKey(item?.key)|translate}}</div>
            </div>
        </div>
    </div>
</div>
</ng-container>


