<clr-modal [(clrModalOpen)]="opened" [clrModalStaticBackdrop]="staticBackdrop" [clrModalClosable]="false">
    <h3 class="modal-title">{{'PROFILE.TITLE' | translate}}</h3>
    <div class="modal-body body-format">
        <inline-alert (confirmEvt)="confirmYes($event)" (closeEvt)="confirmNo($event)"></inline-alert>
        <form #accountSettingsFrom="ngForm" class="clr-form clr-form-horizontal">
            <div class="clr-form-control">
                <label for="account_settings_username" aria-haspopup="true"
                    class="clr-control-label">{{'PROFILE.USER_NAME' | translate}}</label>
                <div class="clr-control-container display-flex">
                    <input class="clr-input" type="text" name="account_settings_username" [(ngModel)]="account.username"
                        disabled id="account_settings_username" size="30">
                    <div *ngIf="canRename" class="rename-tool">
                        <button id="rename-btn" [disabled]="RenameOnGoing" (click)="onRename()" class="btn btn-outline ">
                            {{'PROFILE.ADMIN_RENAME_BUTTON' | translate}}
                        </button>
                        <clr-tooltip>
                            <clr-icon clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                            <clr-tooltip-content clrPosition="bottom-left" clrSize="md" *clrIfOpen>
                                <span> {{'PROFILE.ADMIN_RENAME_TIP' | translate}}</span>
                            </clr-tooltip-content>
                        </clr-tooltip>
                    </div>
                </div>
            </div>
            <div class="clr-form-control">
                <label for="account_settings_email"
                    class=" clr-control-label">{{'PROFILE.EMAIL' | translate}}</label>
                <div class="clr-control-container" [class.clr-error]="!getValidationState('account_settings_email')">
                    <div class="clr-input-wrapper">
                        <input name="account_settings_email" type="text" #eamilInput="ngModel" class="clr-input"
                            [(ngModel)]="account.email"  email id="account_settings_email" size="30" disabled
                            (input)='handleValidation("account_settings_email", false)'
                            (blur)='handleValidation("account_settings_email", true)'>
                        <clr-icon class="clr-validate-icon" shape="exclamation-circle"></clr-icon>
                        <span class="spinner spinner-inline" [hidden]="!checkProgress"></span>
                    </div>
                    <clr-control-error *ngIf="!getValidationState('account_settings_email')">
                        {{emailTooltip | translate}}
                    </clr-control-error>
                </div>
            </div>
            <clr-input-container>
                <label >{{'PROFILE.FULL_NAME' | translate}}</label>
                <input clrInput type="text" name="account_settings_full_name" #fullNameInput="ngModel"
                    [(ngModel)]="account.realname" maxLengthExt="20" disabled
                    id="account_settings_full_name" size="30"
                    (input)='handleValidation("account_settings_full_name", false)'
                    (blur)='handleValidation("account_settings_full_name", true)'>
                <clr-control-error *ngIf="!getValidationState('account_settings_full_name')">
                    {{'TOOLTIP.FULL_NAME' | translate}}
                </clr-control-error>
            </clr-input-container>
            <clr-input-container>
                <label>{{'PROFILE.COMMENT' | translate}}</label>
                <input clrInput type="text" #commentInput="ngModel" maxlength="30" size="30" disabled
                    name="account_settings_comments" [(ngModel)]="account.comment" id="account_settings_comments">
                <clr-control-error *ngIf="commentInput.invalid && (commentInput.dirty || commentInput.touched)">
                    {{'TOOLTIP.COMMENT' | translate}}
                </clr-control-error>
            </clr-input-container>

                <div class="clr-form-control cli-secret" *ngIf="account.oidc_user_meta">
                <label class="clr-control-label">{{'PROFILE.CLI_PASSWORD' | translate}}
                    <clr-tooltip>
                        <clr-icon clrTooltipTrigger shape="info-circle" size="20"></clr-icon>
                        <clr-tooltip-content clrPosition="top-right" clrSize="md" *clrIfOpen>
                            <span> {{'PROFILE.CLI_PASSWORD_TIP' | translate}}</span>
                        </clr-tooltip-content>
                    </clr-tooltip>
                </label>
                <input id="cli_password" class="clr-input input-cli" type="password" name="cli_password" disabled
                    [ngModel]="'account.oidc_user_meta.secret'" size="33">

                <button (click)="generateCli(account.user_id)" id="generate-cli-btn"
                    class="btn btn-outline  btn-padding-less" *ngIf="showGenerateCli">
                    {{'PROFILE.ADMIN_CIL_SECRET_BUTTON' | translate}}
                </button>
                <button (click)="showSecretDetail=true" id="reset-cli-btn" class="btn btn-outline  btn-padding-less"
                    *ngIf="showGenerateCli">
                    {{'PROFILE.ADMIN_CIL_SECRET_RESET_BUTTON' | translate}}
                </button>
                <div class="rename-tool reset-cli">
                    <hbr-copy-input #copyInput (onCopySuccess)="onSuccess($event)" (onCopyError)="onError($event)"
                        iconMode="true" [defaultValue]="account.oidc_user_meta.secret"></hbr-copy-input>
                </div>
                <div (click)="showGenerateCliFn()" *ngIf="!showGenerateCli" id="hidden-generate-cli"
                    class="hidden-generate-cli">···</div>
                    
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <span class="spinner spinner-inline loading-top" [hidden]="showProgress === false"></span>
        <button type="button" id="cancel-btn" class="btn btn-outline" (click)="close()">{{'BUTTON.CANCEL' | translate}}</button>

        <button type="button" id="submit-btn" class="btn btn-primary" [disabled]="!isValid || showProgress"
            (click)="submit()">{{'BUTTON.OK' | translate}}</button>
    </div>
</clr-modal>
<clr-modal [(clrModalOpen)]="showSecretDetail" [clrModalSize]="'sm'" [clrModalStaticBackdrop]="staticBackdrop"
[clrModalClosable]="false">

    <h3 class="modal-title">{{'PROFILE.ADMIN_CIL_SECRET_RESET_BUTTON' | translate}}</h3>
    <div class="modal-body">
        <form #resetSecretFrom="ngForm" class="clr-form reset-cli-form clr-form-horizontal">
            <clr-input-container>
                <label>{{'PROFILE.NEW_SECRET' | translate}}</label>
                <input clrInput type="password" maxlength="30" size="30" pattern="^(?=.*\d)(?=.*[a-zA-Z]).{8,}$"
                    name="input_secret" [(ngModel)]="resetForms.input_secret" id="input-secret">
                <clr-control-error>
                    {{'TOOLTIP.NEW_SECRET' | translate}}
                </clr-control-error>
            </clr-input-container>
            <clr-input-container>
                <label>{{'PROFILE.CONFIRM_SECRET' | translate}}</label>
                <input clrInput type="password" maxlength="30" size="30"
                    [(ngModel)]="resetForms.confirm_secret" name="confirm_secret" id="confirm-secret">
            </clr-input-container>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="closeReset()">{{'BUTTON.CANCEL' | translate}}</button>
        <button type="button" class="btn btn-primary"  (click)="closeReset()">{{'BUTTON.CONFIRM' | translate}}</button>
    </div>
</clr-modal>
<confirmation-dialog #confirmationDialog (confirmAction)="confirmGenerate($event)"></confirmation-dialog>
