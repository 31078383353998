<clr-datagrid>
  <clr-dg-column>{{'HELM_CHART.NAME' | translate}}</clr-dg-column>
  <clr-dg-column>{{'HELM_CHART.VERSION' | translate}}</clr-dg-column>
  <clr-dg-column>{{'HELM_CHART.STATUS' | translate }}</clr-dg-column>
  <clr-dg-column>{{'HELM_CHART.MAINTAINERS' | translate }}</clr-dg-column>
  <clr-dg-column>{{'HELM_CHART.CREATED' | translate }}</clr-dg-column>
  <clr-dg-placeholder>{{'HELM_CHART.NO_VERSION_PLACEHOLDER' | translate }}</clr-dg-placeholder>
  <clr-dg-row *clrDgItems="let chart of charts" [clrDgItem]="chart">
      <clr-dg-cell>
          <a href="javascript:void(0)" (click)="gotoChartVersion(chart.Chart)">{{ chart.Name }}</a>
        </clr-dg-cell>
      <clr-dg-cell>{{ chart.Chart.version }}</clr-dg-cell>
      <clr-dg-cell>{{ getStatusString(chart.Chart) | translate }}</clr-dg-cell>
      <clr-dg-cell>{{ getMaintainerString(chart.Chart.maintainers) | translate : getMaintainerTranslateInfo(chart.Chart.maintainers) }}</clr-dg-cell>
      <clr-dg-cell>{{ chart.Chart.created | date}}</clr-dg-cell>
  </clr-dg-row>
  <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="5" [clrDgTotalItems]="pagination.totalItems">
          <span *ngIf="pagination.totalItems">
              {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} {{'HELM_CHART.OF' | translate}}
          </span>
          {{pagination.totalItems}} {{'HELM_CHART.ITEMS'| translate}}
      </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>