<form [formGroup]="imageNameForm" class="clr-form clr-form-horizontal" (mouseleave)="leaveProjectInput()">
    <div class="clr-form-control">
        <label class="required clr-control-label">{{ 'PROJECT.NAME' | translate }}</label>
        <div class="clr-control-container"
             [class.clr-error]="noProjectInfo && (projectName.dirty || projectName.touched)">
            <div class="clr-input-wrapper">
                <input type="text"
                       id="project-name"
                       class="clr-input w-90"
                       (keyup)='validateProjectName()'
                       formControlName="projectName" autocomplete="off" />
                <clr-icon class="clr-validate-icon" shape="exclamation-circle"></clr-icon>
                <div class="select-box" [style.display]="selectedProjectList.length ? 'block' : 'none'">
                    <ul>
                        <li *ngFor="let project of selectedProjectList"
                            (click)="selectedProjectName(project?.name)">{{project?.name}}</li>
                    </ul>
                </div>
            </div>
            <clr-control-error *ngIf="noProjectInfo && (projectName.dirty || projectName.touched)"
                               class="tooltip-content">
                {{noProjectInfo | translate}}
            </clr-control-error>
        </div>
    </div>
    <div class="clr-form-control">
        <label class="required clr-control-label">{{ 'REPOSITORY.REPO_NAME' | translate }}</label>
        <div class="clr-control-container" [class.clr-error]="repoName.invalid && (repoName.dirty || repoName.touched)">
            <div class="clr-input-wrapper">
                <input type="text" id="repo-name" class="clr-input w-90" formControlName="repoName" autocomplete="off" />
                <clr-icon class="clr-validate-icon" shape="exclamation-circle"></clr-icon>
            </div>
            <clr-control-error *ngIf="repoName.invalid && (repoName.dirty || repoName.touched)" class="tooltip-content">
                {{ 'RETAG.TIP_REPO' | translate }}
            </clr-control-error>
        </div>
    </div>
</form>
