/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Repository } from '../models/repository';
@Injectable({
  providedIn: 'root',
})
class RepositoryService extends __BaseService {
  static readonly listRepositoriesPath = '/projects/{project_name}/repositories';
  static readonly getRepositoryPath = '/projects/{project_name}/repositories/{repository_name}';
  static readonly updateRepositoryPath = '/projects/{project_name}/repositories/{repository_name}';
  static readonly deleteRepositoryPath = '/projects/{project_name}/repositories/{repository_name}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List repositories of the specified project
   * @param params The `RepositoryService.ListRepositoriesParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `q`: Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
   *
   * - `page_size`: The size of per page
   *
   * - `page`: The page number
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  listRepositoriesResponse(params: RepositoryService.ListRepositoriesParams): __Observable<__StrictHttpResponse<Array<Repository>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.q != null) __params = __params.set('q', params.q.toString());
    if (params.pageSize != null) __params = __params.set('page_size', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/projects/${params.projectName}/repositories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Repository>>;
      })
    );
  }
  /**
   * List repositories of the specified project
   * @param params The `RepositoryService.ListRepositoriesParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `q`: Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
   *
   * - `page_size`: The size of per page
   *
   * - `page`: The page number
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  listRepositories(params: RepositoryService.ListRepositoriesParams): __Observable<Array<Repository>> {
    return this.listRepositoriesResponse(params).pipe(
      __map(_r => _r.body as Array<Repository>)
    );
  }

  /**
   * Get the repository specified by name
   * @param params The `RepositoryService.GetRepositoryParams` containing the following parameters:
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `project_name`: The name of the project
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  getRepositoryResponse(params: RepositoryService.GetRepositoryParams): __Observable<__StrictHttpResponse<Repository>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/projects/${params.projectName}/repositories/${params.repositoryName}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Repository>;
      })
    );
  }
  /**
   * Get the repository specified by name
   * @param params The `RepositoryService.GetRepositoryParams` containing the following parameters:
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `project_name`: The name of the project
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  getRepository(params: RepositoryService.GetRepositoryParams): __Observable<Repository> {
    return this.getRepositoryResponse(params).pipe(
      __map(_r => _r.body as Repository)
    );
  }

  /**
   * Update the repository specified by name
   * @param params The `RepositoryService.UpdateRepositoryParams` containing the following parameters:
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `repository`: The JSON object of repository.
   *
   * - `project_name`: The name of the project
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  updateRepositoryResponse(params: RepositoryService.UpdateRepositoryParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.repository;

    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/projects/${params.projectName}/repositories/${params.repositoryName}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update the repository specified by name
   * @param params The `RepositoryService.UpdateRepositoryParams` containing the following parameters:
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `repository`: The JSON object of repository.
   *
   * - `project_name`: The name of the project
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  updateRepository(params: RepositoryService.UpdateRepositoryParams): __Observable<null> {
    return this.updateRepositoryResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Delete the repository specified by name
   * @param params The `RepositoryService.DeleteRepositoryParams` containing the following parameters:
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `project_name`: The name of the project
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  deleteRepositoryResponse(params: RepositoryService.DeleteRepositoryParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/projects/${params.projectName}/repositories/${params.repositoryName}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete the repository specified by name
   * @param params The `RepositoryService.DeleteRepositoryParams` containing the following parameters:
   *
   * - `repository_name`: The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
   *
   * - `project_name`: The name of the project
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  deleteRepository(params: RepositoryService.DeleteRepositoryParams): __Observable<null> {
    return this.deleteRepositoryResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module RepositoryService {

  /**
   * Parameters for listRepositories
   */
  export interface ListRepositoriesParams {

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
     */
    q?: string;

    /**
     * The size of per page
     */
    pageSize?: number;

    /**
     * The page number
     */
    page?: number;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for getRepository
   */
  export interface GetRepositoryParams {

    /**
     * The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
     */
    repositoryName: string;

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for updateRepository
   */
  export interface UpdateRepositoryParams {

    /**
     * The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
     */
    repositoryName: string;

    /**
     * The JSON object of repository.
     */
    repository: Repository;

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for deleteRepository
   */
  export interface DeleteRepositoryParams {

    /**
     * The name of the repository. If it contains slash, encode it with URL encoding. e.g. a/b -> a%252Fb
     */
    repositoryName: string;

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }
}

export { RepositoryService }
