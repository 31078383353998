<div>
  <div class="breadcrumb">
    <a href="javascript:void(0)" (click)="gotoProjectList()"> {{ 'SIDE_NAV.PROJECTS'| translate}} </a>
    &lt;
    <a href="javascript:void(0)" (click)="gotoChartList()">{{ projectName }}</a>
  </div>
  <hbr-helm-chart-version
    [projectId]='projectId'
    [projectName]='projectName'
    [chartName]='chartName'
    [roleName]='roleName'
    [hasSignedIn]='hasSignedIn'
    (versionClickEvt)='onVersionClick($event)'
    (backEvt)='gotoChartList()'>
</hbr-helm-chart-version>
</div>
