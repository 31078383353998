<clr-modal
  [(clrModalOpen)]="opened"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <h3 class="modal-title">{{ title | translate }}</h3>
  <div class="modal-body">
    <inline-alert class="modal-title"></inline-alert>
    <form #instanceForm="ngForm" class="clr-form clr-form-horizontal">
      <!-- 1. provider -->
      <clr-select-container>
        <label class="required">{{
          'DISTRIBUTION.PROVIDER' | translate
        }}</label>
        <select class="width-280"
          clrSelect
          name="provider"
          id="provider"
          [(ngModel)]="model.vendor"
          required
        >
          <option class="display-none" value=""></option>
          <option
            *ngFor="let provider of providers"
            value="{{ provider.id }}"
            >{{ provider.name }}</option
          >
        </select>
        <clr-control-error>
          {{ 'TOOLTIP.ITEM_REQUIRED' | translate }}
        </clr-control-error>
      </clr-select-container>

      <!-- 2. name -->
      <div class="clr-form-control">
        <label class="required clr-control-label" for="name">{{'DISTRIBUTION.NAME' | translate}}</label>
        <div class="clr-control-container" [class.clr-error]="((nameNg.dirty || nameNg.touched) && nameNg.invalid) || isNameExisting">
          <div class="clr-input-wrapper">
            <input class="width-280 clr-input"
                   required
                   type="text"
                   id="name"
                   autocomplete="off"
                   [(ngModel)]="model.name"
                   name="name"
                   #nameNg="ngModel"
                   (input)="inputName()"
            />
            <clr-icon class="clr-validate-icon" shape="exclamation-circle"></clr-icon>
            <span class="spinner spinner-inline" [hidden]="!checkNameOnGoing"></span>
          </div>
          <clr-control-error *ngIf="((nameNg.dirty || nameNg.touched) && nameNg.invalid) || isNameExisting">
            <span *ngIf="!((nameNg.dirty || nameNg.touched) && nameNg.invalid) && isNameExisting">{{'SCANNER.NAME_EXISTS' | translate}}</span>
            <span *ngIf="(nameNg.dirty || nameNg.touched) && nameNg.invalid">{{ 'TOOLTIP.ITEM_REQUIRED' | translate }}</span>
          </clr-control-error>
        </div>
      </div>

      <!-- 3. description -->
      <clr-textarea-container>
        <label>{{ 'DISTRIBUTION.DESCRIPTION' | translate }}</label>
        <textarea
          clrTextarea
          type="text"
          id="description"
          class="width-280"
          row="3"
          [(ngModel)]="model.description"
          [ngModelOptions]="{ standalone: true }"
        ></textarea>
      </clr-textarea-container>
      <!-- 4. endpoint -->
      <div class="clr-form-control">
        <label class="required clr-control-label" for="name">{{'DISTRIBUTION.ENDPOINT' | translate}}</label>
        <div class="clr-control-container" [class.clr-error]="((endpointNg.dirty || endpointNg.touched) && endpointNg.invalid) || isEndpointExisting">
          <div class="clr-input-wrapper">
            <input class="width-280 clr-input"
                   required
                   pattern="^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(.*?)*$"
                   type="text"
                   id="endpoint"
                   placeholder="http(s)://192.168.1.1"
                   [(ngModel)]="model.endpoint"
                   name="endpoint"
                   #endpointNg="ngModel"
                   autocomplete="off"
                   (input)="inputEndpoint()"
            />
            <clr-icon class="clr-validate-icon" shape="exclamation-circle"></clr-icon>
            <span class="spinner spinner-inline" [hidden]="!checkEndpointOngoing"></span>
          </div>
          <clr-control-error *ngIf="((endpointNg.dirty || endpointNg.touched) && endpointNg.invalid) || isEndpointExisting">
            <span *ngIf="!((endpointNg.dirty || endpointNg.touched) && endpointNg.invalid) && isEndpointExisting">{{'SCANNER.ENDPOINT_EXISTS' | translate}}</span>
            <span *ngIf="(endpointNg.dirty || endpointNg.touched) && endpointNg.invalid">{{ 'TOOLTIP.ENDPOINT_FORMAT' | translate }}</span>
          </clr-control-error>
        </div>
      </div>
      <!-- auth mode -->
      <clr-radio-container clrInline>
        <label>{{ 'DISTRIBUTION.AUTH_MODE' | translate }}</label>
        <clr-radio-wrapper>
          <input
            clrRadio
            type="radio"
            name="auth_mode"
            id="none_mode"
            value="NONE"
            [(ngModel)]="model.auth_mode"
            (change)="authModeChange()"
            [ngModelOptions]="{ standalone: true }"
          />
          <label for="none_mode">NONE</label>
        </clr-radio-wrapper>
        <clr-radio-wrapper>
          <input
            clrRadio
            type="radio"
            name="auth_mode"
            id="basic_mode"
            value="BASIC"
            [(ngModel)]="model.auth_mode"
            (change)="authModeChange()"
            [ngModelOptions]="{ standalone: true }"
          />
          <label for="basic_mode">Basic</label>
        </clr-radio-wrapper>
        <clr-radio-wrapper>
          <input
            clrRadio
            type="radio"
            name="auth_mode"
            id="token_mode"
            value="OAUTH"
            [(ngModel)]="model.auth_mode"
            (change)="authModeChange()"
            [ngModelOptions]="{ standalone: true }"
          />
          <label for="token_mode">OAuth</label>
        </clr-radio-wrapper>
      </clr-radio-container>
      <!-- auth data -->
      <span *ngIf="model.auth_mode == 'BASIC'">
        <clr-input-container>
          <label class="required clr-control-label" for="auth_data_username">{{
            'DISTRIBUTION.USERNAME' | translate
          }}</label>
          <input
            class="width-280"
            clrInput
            required
            type="text"
            id="auth_data_username"
            [(ngModel)]="authData['username']"
            placeholder="{{
              'DISTRIBUTION.SETUP.USERNAME_PLACEHOLDER' | translate
            }}"
            name="auth_data_username"
            autocomplete="off"
          />
          <clr-control-error>
            {{ 'TOOLTIP.ITEM_REQUIRED' | translate }}
          </clr-control-error>
        </clr-input-container>
        <clr-input-container>
          <label class="required clr-control-label" for="auth_data_password">{{
            'DISTRIBUTION.PASSWORD' | translate
          }}</label>
          <input
            class="width-280"
            clrInput
            required
            type="password"
            id="auth_data_password"
            [(ngModel)]="authData['password']"
            placeholder="{{
              'DISTRIBUTION.SETUP.PASSWORD_PLACEHOLDER' | translate
            }}"
            name="auth_data_password"
            autocomplete="off"
          />
          <clr-control-error>
            {{ 'TOOLTIP.ITEM_REQUIRED' | translate }}
          </clr-control-error>
        </clr-input-container>
      </span>
      <span *ngIf="model.auth_mode == 'OAUTH'">
        <clr-input-container>
          <label class="required clr-control-label" for="auth_data_token">{{
            'DISTRIBUTION.TOKEN' | translate
          }}</label>
          <input
            class="width-280"
            clrInput
            required
            type="password"
            id="auth_data_token"
            [(ngModel)]="authData['token']"
            placeholder="{{
              'DISTRIBUTION.SETUP.TOKEN_PLACEHOLDER' | translate
            }}"
            name="auth_data_token"
            autocomplete="off"
          />
          <clr-control-error>
            {{ 'TOOLTIP.ITEM_REQUIRED' | translate }}
          </clr-control-error>
        </clr-input-container>
      </span>
      <span *ngIf="model.auth_mode == 'NONE'"></span>

      <!-- 5. enabled -->
      <div class="clr-form-control">
        <label class="clr-control-label">{{"SCANNER.OPTIONS" | translate}}</label>
        <div class="clr-control-container padding-top-3">
          <clr-checkbox-wrapper>
            <input
                    clrCheckbox
                    id="enabled"
                    name="enabled"
                    type="checkbox"
                    [(ngModel)]="model.enabled"
            />
            <label for="enabled"><span>{{ 'DISTRIBUTION.ENABLED' | translate }}</span></label>
          </clr-checkbox-wrapper>
          <clr-checkbox-wrapper>
            <input name="insecure" clrCheckbox
                   type="checkbox" id="insecure"
                   [(ngModel)]="model.insecure"
            >
            <label for="insecure">{{"SCANNER.SKIP" | translate}}
              <clr-tooltip>
                <clr-icon class="color-57"  clrTooltipTrigger shape="info-circle" size="24"></clr-icon>
                <clr-tooltip-content  clrPosition="top-left" clrSize="md" *clrIfOpen>
                  {{'P2P_PROVIDER.SKIP_CERT_VERIFY' | translate}}
                </clr-tooltip-content>
              </clr-tooltip>
            </label>
          </clr-checkbox-wrapper>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button id="button-test" type="button" [clrLoading]="checkBtnState" class="btn btn-outline" (click)="onTestEndpoint()" [disabled]="!isValid || onTesting || checkNameOnGoing || checkEndpointOngoing || isEndpointExisting || isNameExisting">{{'SCANNER.TEST_CONNECTION' | translate}}</button>
    <button type="button" class="btn btn-outline" (click)="cancel()">
      {{ 'BUTTON.CANCEL' | translate }}
    </button>
    <button
      id="instance-ok"
      [clrLoading]="saveBtnState"
      type="button"
      class="btn btn-primary"
      (click)="submit()"
      [disabled]="!isValid || !hasChangesForEdit() || checkNameOnGoing || checkEndpointOngoing"
    >
      {{ 'BUTTON.OK' | translate }}
    </button>
  </div>
</clr-modal>
