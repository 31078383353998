<clr-datagrid (clrDgRefresh)="refresh($event)">
    <clr-dg-column>{{'PROJECT.NAME' | translate}}</clr-dg-column>
    <clr-dg-column>{{'PROJECT.ACCESS_LEVEL' | translate}}</clr-dg-column>
    <clr-dg-column>{{'PROJECT.REPO_COUNT'| translate}}</clr-dg-column>
    <clr-dg-column>{{'PROJECT.CREATION_TIME' | translate}}</clr-dg-column>
    <clr-dg-row *clrDgItems="let p of projects" [clrDgItem]="p">
        <clr-dg-cell><a href="javascript:void(0)" (click)="goToLink(p.project_id)">{{p.name}}</a></clr-dg-cell>
        <clr-dg-cell>{{ (p.metadata.public === 'true' ? 'PROJECT.PUBLIC' : 'PROJECT.PRIVATE') | translate}}</clr-dg-cell>
        <clr-dg-cell>{{p.repo_count}}</clr-dg-cell>
        <clr-dg-cell>{{p.creation_time | date: 'short'}}</clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
        <span *ngIf="pagination.totalItems">{{pagination.firstItem + 1}} - {{pagination.lastItem +1 }} {{'PROJECT.OF' | translate}} </span> {{pagination.totalItems }} {{'PROJECT.ITEMS' | translate}}
        <clr-dg-pagination #pagination [clrDgPageSize]="5"></clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>