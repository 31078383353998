<div class="color-0 pt-19px font-size-13">
    <div class="row" class="label-config">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 quota-top">
            <div class="default-quota">
                <div>
                    <div class="default-quota-text">
                        <span class="width-10rem">{{'QUOTA.PROJECT_QUOTA_DEFAULT_DISK' | translate}}</span>
                        <span class="num-count">{{ quotaHardLimitValue?.storageLimit === -1?('QUOTA.UNLIMITED' | translate): getIntegerAndUnit(quotaHardLimitValue?.storageLimit, 0).partNumberHard}}
                            {{ quotaHardLimitValue?.storageLimit === -1?'':quotaHardLimitValue?.storageUnit }}</span>
                        <button id="open-edit" class="btn btn-link btn-sm  default-quota-edit-button"
                                (click)="editDefaultQuota(quotaHardLimitValue)">{{'QUOTA.EDIT' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <clr-datagrid [(clrDgSelected)]="selectedRow" [clrDgLoading]="loading" (clrDgRefresh)="getQuotaList($event)">
                <clr-dg-action-bar>
                    <div class="clr-row">
                        <div class="clr-col">
                            <button type="button" class="btn  btn-secondary" [disabled]="!(selectedRow && selectedRow.length === 1)" (click)="editQuota()">
                                {{'QUOTA.EDIT' | translate}}
                            </button>
                        </div>
                        <div class="clr-col-1">
                            <div class="action-head-pos">
                               <span class="refresh-btn" (click)="refresh()">
                                   <clr-icon shape="refresh"></clr-icon>
                               </span>
                            </div>
                        </div>
                    </div>
                </clr-dg-action-bar>
                <clr-dg-column>{{'QUOTA.PROJECT' | translate}}</clr-dg-column>
                <clr-dg-column>{{'QUOTA.OWNER' | translate}}</clr-dg-column>
                <clr-dg-column [clrDgSortBy]="storageComparator">{{'QUOTA.STORAGE' | translate }}</clr-dg-column>
                <clr-dg-placeholder>{{'QUOTA.PLACEHOLDER' | translate }}</clr-dg-placeholder>
                <clr-dg-row *ngFor="let quota of quotaList" [clrDgItem]='quota'>
                    <clr-dg-cell>
                        <a href="javascript:void(0)" (click)="goToLink(quota?.ref?.id)">{{quota?.ref?.name}}</a></clr-dg-cell>
                    <clr-dg-cell>{{quota?.ref?.owner_name}}</clr-dg-cell>
                    <clr-dg-cell>
                        <div class="progress-block progress-min-width">
                            <div class="progress success"
                                [class.danger]="quota.hard.storage!==-1?quota.used.storage/quota.hard.storage>quotaDangerCoefficient:false"
                                [class.warning]="quota.hard.storage!==-1?quota.used.storage/quota.hard.storage>=quotaWarningCoefficient&&quota.used.storage/quota.hard.storage<=quotaDangerCoefficient:false"
                                >
                                <progress value="{{quota.hard.storage===-1? 0 : quota.used.storage}}"
                                    max="{{quota.hard.storage}}" data-displayval="100%"></progress>
                            </div>
                            <label class="min-label-width">{{ quota?.hard?.storage ===-1 ? getSuitableUnit(quota?.used?.storage) :
                                (getIntegerAndUnit(quota?.hard?.storage, quota?.used?.storage).partNumberUsed + getIntegerAndUnit(quota?.hard?.storage, quota?.used?.storage).partCharacterUsed)}}
                                {{ 'QUOTA.OF' | translate }}
                                {{ (quota?.hard?.storage ===-1 ? 'QUOTA.UNLIMITED':
                                (getIntegerAndUnit(quota?.hard?.storage, quota?.used?.storage).partNumberHard + getIntegerAndUnit(quota?.hard?.storage, quota?.used?.storage).partCharacterHard)) | translate }}
                            </label>
                        </div>
                    </clr-dg-cell>
                </clr-dg-row>
                <clr-dg-footer>
                    <span>{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                        {{'DESTINATION.OF' | translate}}</span>
                    {{totalCount}} {{'SUMMARY.QUOTAS' | translate}}
                    <clr-dg-pagination #pagination [clrDgPageSize]="pageSize" [(clrDgPage)]="currentPage"
                        [clrDgTotalItems]="totalCount"></clr-dg-pagination>
                </clr-dg-footer>
            </clr-datagrid>
        </div>
    </div>
    <edit-project-quotas #editProjectQuotas (confirmAction)="confirmEdit($event)"></edit-project-quotas>
</div>
