<div class="operDiv" [@SlideInOutAnimation]="animationState" (mouseover)="mouseover()" (mouseleave)="mouseleave()" >
    <div class="position-relative w-100 h-100">
        <a class="toolBar" (click)="slideOut()">{{'OPERATION.EVENT_LOG' | translate}}<span *ngIf="getNewMessageCountStr()" class="badge badge-danger margin-left-5">{{getNewMessageCountStr()}}</span></a>
        <div class="side-form">
        <clr-icon shape="refresh" class="freshIcon" (click)="TabEvent()"></clr-icon>
        <h3 class="custom-h2 event-text">{{'OPERATION.LOCAL_EVENT' | translate}}</h3>
        <div class="tab-top">
            <clr-tabs>
                <clr-tab>
                    <button clrTabLink id="link1" (click)="TabEvent()">{{'OPERATION.ALL' | translate}}</button>
                    <clr-tab-content id="contentAll" *clrIfActive="true">
                        <div class="eventInfo" *ngFor="let list of resultLists">
                            <div class="iconsArea">
                                <i class="spinner spinner-inline spinner-pos" [hidden]="list.state != 'progressing'"></i>
                                <clr-icon [hidden]="list.state != 'success'" size="18" shape="success-standard" class="color-green"></clr-icon>
                                <clr-icon [hidden]="list.state != 'failure'" size="18"  shape="error-standard" class="color-red"></clr-icon>
                                <clr-icon [hidden]="list.state != 'interrupt'" size="18"  shape="unlink" class="color-orange"></clr-icon>
                            </div>
                            <div class="infoArea">
                                <label class="eventName" (click)="toggleTitle(spanErrorInfo)">{{list.name | translate}}</label>
                                <span class="eventTarget">{{list.data.name}}</span><span class="eventTime">{{list.timeDiff | translate}}</span>
                                <span #spanErrorInfo class="eventErrorInf hidden-info">{{list.data.errorInf}}</span>
                            </div>
                        </div>
                    </clr-tab-content>
                </clr-tab>
                <clr-tab>
                    <button clrTabLink (click)="TabEvent()">{{'OPERATION.RUNNING' | translate}}</button>
                    <clr-tab-content id="contentRun" *clrIfActive>
                        <div class="eventInfo" *ngFor="let list of runningLists">
                            <div class="iconsArea">
                                <i class="spinner spinner-inline spinner-pos" [hidden]="list.state != 'progressing'"></i>
                                <clr-icon [hidden]="list.state != 'success'" size="18" shape="success-standard" class="color-green"></clr-icon>
                                <clr-icon [hidden]="list.state != 'failure'" size="18"  shape="error-standard" class="color-red"></clr-icon>
                            </div>
                            <div class="infoArea">
                                <label class="eventName" (click)="toggleTitle(spanErrorInfo)">{{list.name | translate}}</label>
                                <span class="eventTarget">{{list.data.name}}</span><span class="eventTime">{{list.timeDiff | translate}}</span>
                                <span #spanErrorInfo class="eventErrorInf hidden-info">{{list.data.errorInf}}</span>
                            </div>
                        </div>
                    </clr-tab-content>
                </clr-tab>
                <clr-tab>
                    <button clrTabLink (click)="TabEvent()">{{'OPERATION.FAILED' | translate}}</button>
                    <clr-tab-content id="contentFailed" *clrIfActive>
                        <div class="eventInfo" *ngFor="let list of failLists">
                            <div class="iconsArea">
                                <i class="spinner spinner-inline spinner-pos" [hidden]="list.state != 'progressing'"></i>
                                <clr-icon [hidden]="list.state != 'success'" size="18" shape="success-standard" class="color-green"></clr-icon>
                                <clr-icon [hidden]="list.state != 'failure'" size="18"  shape="error-standard" class="color-red"></clr-icon>
                            </div>
                            <div class="infoArea">
                                <label class="eventName" (click)="toggleTitle(spanErrorInfo)">{{list.name | translate}}</label>
                                <span class="eventTarget">{{list.data.name}}</span><span class="eventTime">{{list.timeDiff | translate}}</span>
                                <span #spanErrorInfo class="eventErrorInf hidden-info">{{list.data.errorInf}}</span>
                            </div>
                        </div>
                    </clr-tab-content>
                </clr-tab>
            </clr-tabs>
        </div>
    </div>
    </div>
</div>

