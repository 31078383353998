<div class="bar-wrapper">
    <div *ngIf="queued" class="bar-state">
        <span class="label label-orange">{{'VULNERABILITY.STATE.QUEUED' | translate}}</span>
    </div>
    <div *ngIf="error" class="bar-state bar-state-error">
        <a  class="error-text" target="_blank" [href]="viewLog()">
            <clr-icon shape="error" class="is-error" size="24"></clr-icon>
            {{'VULNERABILITY.STATE.ERROR' | translate}}
        </a>
    </div>
    <div *ngIf="scanning" class="bar-state bar-state-chart">
        <div>{{'VULNERABILITY.STATE.SCANNING' | translate}}</div>
        <div class="progress loop loop-height"><progress></progress></div>
    </div>
    <div *ngIf="completed" class="bar-state bar-state-chart">
        <hbr-result-tip-histogram [scanner]="scanner" [vulnerabilitySummary]="summary"></hbr-result-tip-histogram>
    </div>
    <div *ngIf="otherStatus" class="bar-state">
        <span class="label not-scan">{{'VULNERABILITY.STATE.OTHER_STATUS' | translate}}</span>
    </div>
</div>
