<div class="row">
    <div>
        <h4 class="mt-1">{{'SCANNER.IMAGE_SCANNERS' | translate }}
            <clr-signpost>
                <clr-signpost-content *clrIfOpen>
                    <a class="doc" target="_blank"
                       href="{{scannerDocUrl}}">{{'SCANNER.VIEW_DOC' | translate }}</a>
                </clr-signpost-content>
            </clr-signpost>
        </h4>
        <clr-datagrid [clrDgLoading]="onGoing" [(clrDgSingleSelected)]="selectedRow">
            <clr-dg-action-bar>
                <div class="clr-row">
                    <div class="clr-col-7">
                        <button type="button" class="btn  btn-secondary" (click)="addNewScanner()">
                            <clr-icon shape="plus" size="16"></clr-icon>
                            {{'SCANNER.NEW_SCANNER' | translate}}
                        </button>
                        <button id="set-default"  [disabled]="!(selectedRow && !selectedRow.is_default && !selectedRow.disabled)"
                                class="btn  btn-secondary"
                                (click)="setAsDefault()">{{'SCANNER.SET_AS_DEFAULT' | translate}}</button>
                        <clr-dropdown  [clrCloseMenuOnItemClick]="false" class="btn  btn-link" clrDropdownTrigger>
                            <span id="action-scanner">{{'MEMBER.ACTION' | translate}}<clr-icon class="clr-icon" shape="caret down"></clr-icon></span>
                            <clr-dropdown-menu *clrIfOpen>
                                <button clrDropdownItem
                                        (click)="changeStat()"
                                        [disabled]="!(selectedRow && !selectedRow.is_default)">
                                    <span *ngIf="selectedRow && selectedRow.disabled">
                                        <clr-icon class="margin-top-2" size="16" shape="success-standard"></clr-icon>
                                        <span class="margin-left-10">{{'WEBHOOK.ENABLED_BUTTON' | translate}}</span>
                                    </span>
                                    <span *ngIf="!(selectedRow && selectedRow.disabled)">
                                        <clr-icon class="margin-top-2" size="16" shape="ban"></clr-icon>
                                        <span class="margin-left-10">{{'WEBHOOK.DISABLED_BUTTON' | translate}}</span>
                                    </span>
                                </button>
                                <button clrDropdownItem
                                        (click)="editScanner()"
                                        class="btn  btn-secondary" [disabled]="!selectedRow">
                                    <clr-icon class="margin-top-0" size="16" shape="pencil"></clr-icon>
                                    <span class="margin-left-10">{{'BUTTON.EDIT' | translate}}</span>
                                </button>
                                <button clrDropdownItem
                                        (click)="deleteScanners()"
                                        class="btn  btn-secondary" [disabled]="!selectedRow">
                                    <clr-icon class="margin-top-0" size="16" shape="times"></clr-icon>
                                    <span id="delete-scanner-action" class="margin-left-10">{{'BUTTON.DELETE' | translate}}</span>
                                </button>
                            </clr-dropdown-menu>
                        </clr-dropdown>
                    </div>
                    <div class="clr-col-5">
                        <div class="action-head-pos">
                            <span (click)="getScanners()" class="refresh-btn">
                                 <clr-icon shape="refresh" [hidden]="onGoing"></clr-icon>
                            </span>
                        </div>
                    </div>
                </div>
            </clr-dg-action-bar>
            <clr-dg-column class="width-240" [clrDgField]="'name'">{{'SCANNER.NAME' | translate}}</clr-dg-column>
            <clr-dg-column class="width-240" [clrDgField]="'url'">{{'SCANNER.ENDPOINT' | translate}}</clr-dg-column>
            <clr-dg-column>{{'SCANNER.HEALTH' | translate}}</clr-dg-column>
            <clr-dg-column>{{'SCANNER.ENABLED' | translate}}</clr-dg-column>
            <clr-dg-column>{{'SCANNER.AUTH' | translate}}</clr-dg-column>
            <clr-dg-placeholder>
                {{'SCANNER.NO_SCANNER' | translate}}
            </clr-dg-placeholder>
            <clr-dg-row *clrDgItems="let scanner of scanners" [clrDgItem]="scanner">
                <clr-dg-cell class="position-relative">
                    <span>{{scanner.name}}</span>
                    <span *ngIf="scanner.is_default" class="label label-info ml-1">{{'SCANNER.DEFAULT' | translate}}</span>
                </clr-dg-cell>
                <clr-dg-cell>{{scanner.url}}</clr-dg-cell>
                <clr-dg-cell class="position-relative">
                    <span *ngIf="scanner.loadingMetadata;else elseBlockLoading" class="spinner spinner-inline ml-2"></span>
                    <ng-template #elseBlockLoading>
                        <span *ngIf="scanner.metadata;else elseBlock" class="label label-success">{{'SCANNER.HEALTHY' | translate}}</span>
                        <ng-template #elseBlock>
                            <span class="label label-danger">{{'SCANNER.UNHEALTHY' | translate}}</span>
                        </ng-template>
                    </ng-template>
                </clr-dg-cell>
                <clr-dg-cell>
                    <div *ngIf="!scanner.disabled" class="icon-wrap">
                        <clr-icon shape="check-circle" size="20" class="is-success enabled-icon"></clr-icon>
                        <span class="margin-left-5px">{{'WEBHOOK.ENABLED' | translate}}</span>
                    </div>
                    <div *ngIf="scanner.disabled" class="icon-wrap">
                        <clr-icon shape="exclamation-triangle" size="20" class="is-warning"></clr-icon>
                        <span class="margin-left-5px">{{'WEBHOOK.DISABLED' | translate}}</span>
                    </div>
                </clr-dg-cell>
                <clr-dg-cell>{{scanner.auth?scanner.auth:'None'}}</clr-dg-cell>
                <scanner-metadata *clrIfExpanded [uid]="scanner.uuid"  ngProjectAs="clr-dg-row-detail"></scanner-metadata>
            </clr-dg-row>
            <clr-dg-footer>
                <span *ngIf="scanners?.length > 0">1 - {{scanners?.length}} {{'WEBHOOK.OF' | translate}} </span> {{scanners?.length}} {{'WEBHOOK.ITEMS' | translate}}
                <clr-dg-pagination [clrDgPageSize]="10"></clr-dg-pagination>
            </clr-dg-footer>
        </clr-datagrid>
    </div>
    <new-scanner-modal (notify)="addSuccess()"></new-scanner-modal>
</div>
