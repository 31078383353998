/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Project } from '../models/project';
import { ProjectReq } from '../models/project-req';
import { ProjectDeletable } from '../models/project-deletable';
import { ProjectSummary } from '../models/project-summary';
import { AuditLog } from '../models/audit-log';
@Injectable({
  providedIn: 'root',
})
class ProjectService extends __BaseService {
  static readonly listProjectsPath = '/projects';
  static readonly headProjectPath = '/projects';
  static readonly createProjectPath = '/projects';
  static readonly getProjectPath = '/projects/{project_id}';
  static readonly updateProjectPath = '/projects/{project_id}';
  static readonly deleteProjectPath = '/projects/{project_id}';
  static readonly getProjectDeletablePath = '/projects/{project_id}/_deletable';
  static readonly getProjectSummaryPath = '/projects/{project_id}/summary';
  static readonly getLogsPath = '/projects/{project_name}/logs';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * This endpoint returns projects created by Harbor.
   * @param params The `ProjectService.ListProjectsParams` containing the following parameters:
   *
   * - `public`: The project is public or private.
   *
   * - `page_size`: The size of per page
   *
   * - `page`: The page number
   *
   * - `owner`: The name of project owner.
   *
   * - `name`: The name of project.
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Return all matched projects.
   */
  listProjectsResponse(params: ProjectService.ListProjectsParams): __Observable<__StrictHttpResponse<Array<Project>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.public != null) __params = __params.set('public', params.public.toString());
    if (params.pageSize != null) __params = __params.set('page_size', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.owner != null) __params = __params.set('owner', params.owner.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/projects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Project>>;
      })
    );
  }
  /**
   * This endpoint returns projects created by Harbor.
   * @param params The `ProjectService.ListProjectsParams` containing the following parameters:
   *
   * - `public`: The project is public or private.
   *
   * - `page_size`: The size of per page
   *
   * - `page`: The page number
   *
   * - `owner`: The name of project owner.
   *
   * - `name`: The name of project.
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Return all matched projects.
   */
  listProjects(params: ProjectService.ListProjectsParams): __Observable<Array<Project>> {
    return this.listProjectsResponse(params).pipe(
      __map(_r => _r.body as Array<Project>)
    );
  }

  /**
   * This endpoint is used to check if the project name provided already exist.
   * @param params The `ProjectService.HeadProjectParams` containing the following parameters:
   *
   * - `project_name`: Project name for checking exists.
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  headProjectResponse(params: ProjectService.HeadProjectParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.projectName != null) __params = __params.set('project_name', params.projectName.toString());
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/projects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * This endpoint is used to check if the project name provided already exist.
   * @param params The `ProjectService.HeadProjectParams` containing the following parameters:
   *
   * - `project_name`: Project name for checking exists.
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  headProject(params: ProjectService.HeadProjectParams): __Observable<null> {
    return this.headProjectResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * This endpoint is for user to create a new project.
   * @param params The `ProjectService.CreateProjectParams` containing the following parameters:
   *
   * - `project`: New created project.
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  createProjectResponse(params: ProjectService.CreateProjectParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.project;
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/projects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * This endpoint is for user to create a new project.
   * @param params The `ProjectService.CreateProjectParams` containing the following parameters:
   *
   * - `project`: New created project.
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  createProject(params: ProjectService.CreateProjectParams): __Observable<null> {
    return this.createProjectResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * This endpoint returns specific project information by project ID.
   * @param params The `ProjectService.GetProjectParams` containing the following parameters:
   *
   * - `project_id`: The ID of the project
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Return matched project information.
   */
  getProjectResponse(params: ProjectService.GetProjectParams): __Observable<__StrictHttpResponse<Project>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/projects/${params.projectId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Project>;
      })
    );
  }
  /**
   * This endpoint returns specific project information by project ID.
   * @param params The `ProjectService.GetProjectParams` containing the following parameters:
   *
   * - `project_id`: The ID of the project
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Return matched project information.
   */
  getProject(params: ProjectService.GetProjectParams): __Observable<Project> {
    return this.getProjectResponse(params).pipe(
      __map(_r => _r.body as Project)
    );
  }

  /**
   * This endpoint is aimed to update the properties of a project.
   * @param params The `ProjectService.UpdateProjectParams` containing the following parameters:
   *
   * - `project_id`: The ID of the project
   *
   * - `project`: Updates of project.
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  updateProjectResponse(params: ProjectService.UpdateProjectParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.project;
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/projects/${params.projectId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * This endpoint is aimed to update the properties of a project.
   * @param params The `ProjectService.UpdateProjectParams` containing the following parameters:
   *
   * - `project_id`: The ID of the project
   *
   * - `project`: Updates of project.
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  updateProject(params: ProjectService.UpdateProjectParams): __Observable<null> {
    return this.updateProjectResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * This endpoint is aimed to delete project by project ID.
   * @param params The `ProjectService.DeleteProjectParams` containing the following parameters:
   *
   * - `project_id`: The ID of the project
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  deleteProjectResponse(params: ProjectService.DeleteProjectParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/projects/${params.projectId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * This endpoint is aimed to delete project by project ID.
   * @param params The `ProjectService.DeleteProjectParams` containing the following parameters:
   *
   * - `project_id`: The ID of the project
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  deleteProject(params: ProjectService.DeleteProjectParams): __Observable<null> {
    return this.deleteProjectResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the deletable status of the project
   * @param params The `ProjectService.GetProjectDeletableParams` containing the following parameters:
   *
   * - `project_id`: The ID of the project
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Return deletable status of the project.
   */
  getProjectDeletableResponse(params: ProjectService.GetProjectDeletableParams): __Observable<__StrictHttpResponse<ProjectDeletable>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/projects/${params.projectId}/_deletable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProjectDeletable>;
      })
    );
  }
  /**
   * Get the deletable status of the project
   * @param params The `ProjectService.GetProjectDeletableParams` containing the following parameters:
   *
   * - `project_id`: The ID of the project
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Return deletable status of the project.
   */
  getProjectDeletable(params: ProjectService.GetProjectDeletableParams): __Observable<ProjectDeletable> {
    return this.getProjectDeletableResponse(params).pipe(
      __map(_r => _r.body as ProjectDeletable)
    );
  }

  /**
   * Get summary of the project.
   * @param params The `ProjectService.GetProjectSummaryParams` containing the following parameters:
   *
   * - `project_id`: The ID of the project
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Get summary of the project successfully.
   */
  getProjectSummaryResponse(params: ProjectService.GetProjectSummaryParams): __Observable<__StrictHttpResponse<ProjectSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/projects/${params.projectId}/summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProjectSummary>;
      })
    );
  }
  /**
   * Get summary of the project.
   * @param params The `ProjectService.GetProjectSummaryParams` containing the following parameters:
   *
   * - `project_id`: The ID of the project
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Get summary of the project successfully.
   */
  getProjectSummary(params: ProjectService.GetProjectSummaryParams): __Observable<ProjectSummary> {
    return this.getProjectSummaryResponse(params).pipe(
      __map(_r => _r.body as ProjectSummary)
    );
  }

  /**
   * Get recent logs of the projects
   * @param params The `ProjectService.GetLogsParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `q`: Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
   *
   * - `page_size`: The size of per page
   *
   * - `page`: The page number
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  getLogsResponse(params: ProjectService.GetLogsParams): __Observable<__StrictHttpResponse<Array<AuditLog>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.q != null) __params = __params.set('q', params.q.toString());
    if (params.pageSize != null) __params = __params.set('page_size', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/projects/${params.projectName}/logs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AuditLog>>;
      })
    );
  }
  /**
   * Get recent logs of the projects
   * @param params The `ProjectService.GetLogsParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `q`: Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
   *
   * - `page_size`: The size of per page
   *
   * - `page`: The page number
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  getLogs(params: ProjectService.GetLogsParams): __Observable<Array<AuditLog>> {
    return this.getLogsResponse(params).pipe(
      __map(_r => _r.body as Array<AuditLog>)
    );
  }
}

module ProjectService {

  /**
   * Parameters for listProjects
   */
  export interface ListProjectsParams {

    /**
     * The project is public or private.
     */
    public?: boolean;

    /**
     * The size of per page
     */
    pageSize?: number;

    /**
     * The page number
     */
    page?: number;

    /**
     * The name of project owner.
     */
    owner?: string;

    /**
     * The name of project.
     */
    name?: string;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for headProject
   */
  export interface HeadProjectParams {

    /**
     * Project name for checking exists.
     */
    projectName: string;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for createProject
   */
  export interface CreateProjectParams {

    /**
     * New created project.
     */
    project: ProjectReq;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for getProject
   */
  export interface GetProjectParams {

    /**
     * The ID of the project
     */
    projectId: number;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for updateProject
   */
  export interface UpdateProjectParams {

    /**
     * The ID of the project
     */
    projectId: number;

    /**
     * Updates of project.
     */
    project: ProjectReq;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for deleteProject
   */
  export interface DeleteProjectParams {

    /**
     * The ID of the project
     */
    projectId: number;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for getProjectDeletable
   */
  export interface GetProjectDeletableParams {

    /**
     * The ID of the project
     */
    projectId: number;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for getProjectSummary
   */
  export interface GetProjectSummaryParams {

    /**
     * The ID of the project
     */
    projectId: number;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for getLogs
   */
  export interface GetLogsParams {

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
     */
    q?: string;

    /**
     * The size of per page
     */
    pageSize?: number;

    /**
     * The page number
     */
    page?: number;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }
}

export { ProjectService }
