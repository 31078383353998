<clr-datagrid (clrDgRefresh)="clrLoad($event)" [clrDgLoading]="loading" [(clrDgSelected)]="selectedRow">
    <clr-dg-action-bar>
        <button type="button" class="btn  btn-secondary" (click)="addNewProject()" *ngIf="projectCreationRestriction">
            <clr-icon shape="plus" size="16"></clr-icon>&nbsp;{{'PROJECT.NEW_PROJECT' | translate}}</button>
        <button id="delete-project" type="button" class="btn  btn-secondary" [disabled]="!canDelete"
            (click)="deleteProjects(selectedRow)">
            <clr-icon shape="times" size="16"></clr-icon>&nbsp;{{'PROJECT.DELETE' | translate}}</button>
    </clr-dg-action-bar>
    <clr-dg-column [clrDgField]="'name'">{{'PROJECT.NAME' | translate}}</clr-dg-column>
    <clr-dg-column [clrDgSortBy]="accessLevelComparator">{{'PROJECT.ACCESS_LEVEL' | translate}}</clr-dg-column>
    <clr-dg-column [clrDgSortBy]="roleComparator">{{'PROJECT.ROLE' | translate}}</clr-dg-column>
    <clr-dg-column [clrDgSortBy]="typeComparator">{{'PROJECT.TYPE' | translate}}</clr-dg-column>
    <clr-dg-column [clrDgSortBy]="repoCountComparator">{{'PROJECT.REPO_COUNT'| translate}}</clr-dg-column>
    <clr-dg-column *ngIf="withChartMuseum" [clrDgSortBy]="chartCountComparator">{{'PROJECT.CHART_COUNT'| translate}}</clr-dg-column>
    <clr-dg-column [clrDgSortBy]="timeComparator">{{'PROJECT.CREATION_TIME' | translate}}</clr-dg-column>
    <clr-dg-row *ngFor="let p of projects" [clrDgItem]="p">
        <clr-dg-cell>
            <a href="javascript:void(0)" (click)="goToLink(p.project_id)">{{p.name}}</a>
        </clr-dg-cell>
        <clr-dg-cell>{{ (p.metadata.public === 'true' ? 'PROJECT.PUBLIC' : 'PROJECT.PRIVATE') | translate}}</clr-dg-cell>
        <clr-dg-cell>{{ roleInfo[p.current_user_role_id]? (roleInfo[p.current_user_role_id] | translate): "-"}}</clr-dg-cell>
        <clr-dg-cell>{{projectTypeMap[p.registry_id ? 1 : 0] | translate}}</clr-dg-cell>
        <clr-dg-cell>{{p.repo_count}}</clr-dg-cell>
        <clr-dg-cell *ngIf="withChartMuseum">{{p.chart_count}}</clr-dg-cell>
        <clr-dg-cell>{{p.creation_time | date: 'short'}}</clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
        <span *ngIf="totalCount">{{pagination.firstItem + 1}} - {{pagination.lastItem +1 }} {{'PROJECT.OF' | translate}} </span> {{pagination.totalItems
        }} {{'PROJECT.ITEMS' | translate}}
        <clr-dg-pagination #pagination [clrDgPageSize]="pageSize" [(clrDgPage)]="currentPage" [clrDgTotalItems]="totalCount"></clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>
