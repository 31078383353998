<table class="table table-noborder table-style table-tag">
    <caption class="table-title tag-thead">
      <span class="tag-header-color">
        {{ 'REPLICATION.CRON_TITLE' | translate }}
      </span>
    </caption>
    <thead class="tag-thead">
        <tr>
            <th class="tag-header-color">{{ 'REPLICATION.FIELD_NAME' | translate }}</th>
            <th class="tag-header-color">{{ 'REPLICATION.MANDATORY' | translate }}</th>
            <th class="tag-header-color">{{ 'REPLICATION.ALLOWED_VALUES' | translate }}</th>
            <th class="tag-header-color">{{ 'REPLICATION.ALLOWED_CHARACTERS' | translate }}</th>
        </tr>
    </thead>
    <tbody class="tag-tbody">
        <tr class="tag-tr">
            <td class="tag-body-color">{{'REPLICATION.SECONDS' | translate}}</td>
            <td class="tag-body-color">{{'REPLICATION.YES' | translate}}</td>
            <td class="tag-body-color">0-59</td>
            <td class="tag-body-color">* / , -</td>
        </tr>
        <tr class="tag-tr">
            <td class="tag-body-color">{{'REPLICATION.MINUTES' | translate}}</td>
            <td class="tag-body-color">{{'REPLICATION.YES' | translate}}</td>
            <td class="tag-body-color">0-59</td>
            <td class="tag-body-color">* / , -</td>
        </tr>
        <tr class="tag-tr">
            <td class="tag-body-color">{{'REPLICATION.HOURS' | translate}}</td>
            <td class="tag-body-color">{{'REPLICATION.YES' | translate}}</td>
            <td class="tag-body-color">0-23</td>
            <td class="tag-body-color">* / , -</td>
        </tr>
        <tr class="tag-tr">
            <td class="tag-body-color">{{'REPLICATION.DAY_MONTH' | translate}}</td>
            <td class="tag-body-color">{{'REPLICATION.YES' | translate}}</td>
            <td class="tag-body-color">1-31</td>
            <td class="tag-body-color">* / , - ?</td>
        </tr>
        <tr class="tag-tr">
            <td class="tag-body-color">{{'REPLICATION.MONTH' | translate}}</td>
            <td class="tag-body-color">{{'REPLICATION.YES' | translate}}</td>
            <td class="tag-body-color">1-12 or JAN-DEC</td>
            <td class="tag-body-color">* / , -</td>
        </tr>
        <tr class="tag-tr">
            <td class="tag-body-color">{{'REPLICATION.DAY_WEEK' | translate}}</td>
            <td class="tag-body-color">{{'REPLICATION.YES' | translate}}</td>
            <td class="tag-body-color">0-6 or SUN-SAT</td>
            <td class="tag-body-color">* / , - ?</td>
        </tr>
    </tbody>
</table>