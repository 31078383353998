import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'p2p-provider',
  templateUrl: './p2p-provider.component.html',
  styleUrls: ['./p2p-provider.component.scss']
})
export class P2pProviderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
