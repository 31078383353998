<clr-modal [(clrModalOpen)]="opened">
    <h3 class="modal-title">{{ 'PROJECT_CONFIG.TOGGLE_PUBLIC_MODAL.TITLE' | translate }}</h3>
    <div class="modal-body">
        <form clrForm #form="ngForm">
            <div class="clr-form-control clr-row">
                <label class="clr-control-label" style="margin-top: 0;">{{ 'PROJECT_CONFIG.TOGGLE_PUBLIC_MODAL.PROJECT_NAME' | translate }}</label>
                <div class="clr-control-container clr-col-md-10 clr-col-12">
                    <div class="clr-control-label">
                        {{ formData.Name }}
                    </div>
                </div>
            </div>
            <clr-checkbox-container>
                <label>{{ 'PROJECT_CONFIG.REGISTRY' | translate }}</label>
                <clr-checkbox-wrapper>
                    <input type="checkbox" id="clr-checkbox-wrapper-public" clrCheckbox [(ngModel)]="formData.Public" name="public" />
                    <label>{{ 'PROJECT_CONFIG.PUBLIC_TOGGLE' | translate }}</label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>
            <clr-textarea-container>
                <label>{{ 'PROJECT_CONFIG.TOGGLE_PUBLIC_MODAL.REASON' | translate }}</label>
                <textarea style="width: 100%;" clrTextarea [(ngModel)]="formData.Reason" name="reason" required></textarea>
            </clr-textarea-container>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" [disabled]="!isValid || onGoing" (click)="submit()">{{ 'BUTTON.OK' | translate }}</button>
        <button type="button" class="btn btn-outline" [disabled]="onGoing" (click)="opened = false">{{ 'BUTTON.CANCEL' | translate }}</button>
    </div>
</clr-modal>