<div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 position-r">
        <div class="row flex-items-xs-between flex-items-xs-top projectPos">
            <h2 class="header-title">{{'PROJECT.PROJECTS' | translate}}</h2>
            <div>
                <statistics-panel></statistics-panel>
            </div>
        </div>
        <div class="row flex-items-xs-between rightPos">
            <div class="display-flex">
                <div class="clr-select-wrapper">
                    <select (change)="doFilterProjects()" [(ngModel)]="selecteType">
                        <option value="0" [selected]="currentFilteredType === 0">{{projectTypes[0] | translate}}</option>
                        <option value="1">{{projectTypes[1] | translate}}</option>
                        <option value="2">{{projectTypes[2] | translate}}</option>
                    </select>
                </div>
                <hbr-filter [withDivider]="true" filterPlaceholder='{{"PROJECT.FILTER_PLACEHOLDER" | translate}}'
                    [currentValue]="projectName"></hbr-filter>
                <span class="refresh-btn" (click)="refresh()">
                    <clr-icon shape="refresh"></clr-icon>
                </span>
            </div>
        </div>
        <create-project (create)="createProject($event)" [quotaObj]="quotaObj" [isSystemAdmin]="isSystemAdmin"></create-project>
        <list-project (addProject)="openModal()"></list-project>
    </div>
</div>
