<clr-modal [(clrModalOpen)]="addHttpAuthOpened" [clrModalStaticBackdrop]="staticBackdrop" [clrModalClosable]="closable">
    <h3 class="modal-title">{{'GROUP.NEW_MEMBER' | translate}}</h3>
    <inline-alert class="modal-title padding-0"></inline-alert>
    <div class="modal-body">
        <label>{{ 'GROUP.NEW_USER_INFO' | translate}}</label>
        <form #memberForm="ngForm" class="clr-form clr-form-horizontal">
            <div class="clr-form-control">
                <label class="required clr-control-label">{{'GROUP.GROUP' | translate}} {{'GROUP.NAME' | translate}}</label>
                <div class="clr-control-container">
                    <div class="clr-input-wrapper">
                        <input class="clr-input" type="text" id="member_name"  [(ngModel)]="member_group.group_name"
                               name="member_name"
                               size="20"
                               minlength="3"
                               #memberName="ngModel"
                               required autocomplete="off">
                        <clr-icon class="clr-validate-icon" shape="exclamation-circle"></clr-icon>
                        <span class="spinner spinner-inline" [hidden]="!checkOnGoing"></span>
                    </div>
                </div>
            </div>
                <div class="clr-form-control">
                    <label class="clr-control-label">{{'GROUP.ROLE' | translate}}</label>
                    <div class="clr-control-container">
                        <clr-radio-wrapper  *ngFor="let projectRoot of projectRoots">
                            <input clrRadio type="radio" name="member_role" id="{{'check_root_project_' + projectRoot.NAME}}" [value]="projectRoot.VALUE" [(ngModel)]="role_id">
                            <label for="{{'check_root_project_' + projectRoot.NAME}}">{{ projectRoot.LABEL | translate}}</label>
                        </clr-radio-wrapper>
                    </div>
                </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="onCancel()">{{'BUTTON.CANCEL' | translate}}</button>
        <button type="button" class="btn btn-primary" [disabled]="!isValid" (click)="onSubmit()">{{'BUTTON.OK' | translate}}</button>
    </div>
</clr-modal>