/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Metadata } from '../models/metadata';
import { Instance } from '../models/instance';
import { PreheatPolicy } from '../models/preheat-policy';
import { Execution } from '../models/execution';
import { Task } from '../models/task';
import { ProviderUnderProject } from '../models/provider-under-project';
@Injectable({
  providedIn: 'root',
})
class PreheatService extends __BaseService {
  static readonly ListProvidersPath = '/p2p/preheat/providers';
  static readonly PingInstancesPath = '/p2p/preheat/instances/ping';
  static readonly ListInstancesPath = '/p2p/preheat/instances';
  static readonly CreateInstancePath = '/p2p/preheat/instances';
  static readonly GetInstancePath = '/p2p/preheat/instances/{preheat_instance_name}';
  static readonly DeleteInstancePath = '/p2p/preheat/instances/{preheat_instance_name}';
  static readonly UpdateInstancePath = '/p2p/preheat/instances/{preheat_instance_name}';
  static readonly CreatePolicyPath = '/projects/{project_name}/preheat/policies';
  static readonly ListPoliciesPath = '/projects/{project_name}/preheat/policies';
  static readonly GetPolicyPath = '/projects/{project_name}/preheat/policies/{preheat_policy_name}';
  static readonly UpdatePolicyPath = '/projects/{project_name}/preheat/policies/{preheat_policy_name}';
  static readonly ManualPreheatPath = '/projects/{project_name}/preheat/policies/{preheat_policy_name}';
  static readonly DeletePolicyPath = '/projects/{project_name}/preheat/policies/{preheat_policy_name}';
  static readonly ListExecutionsPath = '/projects/{project_name}/preheat/policies/{preheat_policy_name}/executions';
  static readonly GetExecutionPath = '/projects/{project_name}/preheat/policies/{preheat_policy_name}/executions/{execution_id}';
  static readonly StopExecutionPath = '/projects/{project_name}/preheat/policies/{preheat_policy_name}/executions/{execution_id}';
  static readonly ListTasksPath = '/projects/{project_name}/preheat/policies/{preheat_policy_name}/executions/{execution_id}/tasks';
  static readonly GetPreheatLogPath = '/projects/{project_name}/preheat/policies/{preheat_policy_name}/executions/{execution_id}/tasks/{task_id}/logs';
  static readonly ListProvidersUnderProjectPath = '/projects/{project_name}/preheat/providers';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List P2P providers
   * @param X-Request-Id An unique ID for the request
   * @return Success
   */
  ListProvidersResponse(XRequestId?: string): __Observable<__StrictHttpResponse<Array<Metadata>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (XRequestId != null) __headers = __headers.set('X-Request-Id', XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/p2p/preheat/providers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Metadata>>;
      })
    );
  }
  /**
   * List P2P providers
   * @param X-Request-Id An unique ID for the request
   * @return Success
   */
  ListProviders(XRequestId?: string): __Observable<Array<Metadata>> {
    return this.ListProvidersResponse(XRequestId).pipe(
      __map(_r => _r.body as Array<Metadata>)
    );
  }

  /**
   * This endpoint checks status of a instance, the instance can be given by ID or Endpoint URL (together with credential)
   * @param params The `PreheatService.PingInstancesParams` containing the following parameters:
   *
   * - `instance`: The JSON object of instance.
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  PingInstancesResponse(params: PreheatService.PingInstancesParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.instance;
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/p2p/preheat/instances/ping`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * This endpoint checks status of a instance, the instance can be given by ID or Endpoint URL (together with credential)
   * @param params The `PreheatService.PingInstancesParams` containing the following parameters:
   *
   * - `instance`: The JSON object of instance.
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  PingInstances(params: PreheatService.PingInstancesParams): __Observable<null> {
    return this.PingInstancesResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List P2P provider instances
   * @param params The `PreheatService.ListInstancesParams` containing the following parameters:
   *
   * - `q`: Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
   *
   * - `page_size`: The size of per page
   *
   * - `page`: The page number
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  ListInstancesResponse(params: PreheatService.ListInstancesParams): __Observable<__StrictHttpResponse<Array<Instance>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.q != null) __params = __params.set('q', params.q.toString());
    if (params.pageSize != null) __params = __params.set('page_size', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/p2p/preheat/instances`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Instance>>;
      })
    );
  }
  /**
   * List P2P provider instances
   * @param params The `PreheatService.ListInstancesParams` containing the following parameters:
   *
   * - `q`: Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
   *
   * - `page_size`: The size of per page
   *
   * - `page`: The page number
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  ListInstances(params: PreheatService.ListInstancesParams): __Observable<Array<Instance>> {
    return this.ListInstancesResponse(params).pipe(
      __map(_r => _r.body as Array<Instance>)
    );
  }

  /**
   * Create p2p provider instances
   * @param params The `PreheatService.CreateInstanceParams` containing the following parameters:
   *
   * - `instance`: The JSON object of instance.
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  CreateInstanceResponse(params: PreheatService.CreateInstanceParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.instance;
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/p2p/preheat/instances`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Create p2p provider instances
   * @param params The `PreheatService.CreateInstanceParams` containing the following parameters:
   *
   * - `instance`: The JSON object of instance.
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  CreateInstance(params: PreheatService.CreateInstanceParams): __Observable<null> {
    return this.CreateInstanceResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get a P2P provider instance
   * @param params The `PreheatService.GetInstanceParams` containing the following parameters:
   *
   * - `preheat_instance_name`: Instance Name
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  GetInstanceResponse(params: PreheatService.GetInstanceParams): __Observable<__StrictHttpResponse<Instance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/p2p/preheat/instances/${params.preheatInstanceName}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Instance>;
      })
    );
  }
  /**
   * Get a P2P provider instance
   * @param params The `PreheatService.GetInstanceParams` containing the following parameters:
   *
   * - `preheat_instance_name`: Instance Name
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  GetInstance(params: PreheatService.GetInstanceParams): __Observable<Instance> {
    return this.GetInstanceResponse(params).pipe(
      __map(_r => _r.body as Instance)
    );
  }

  /**
   * Delete the specified P2P provider instance
   * @param params The `PreheatService.DeleteInstanceParams` containing the following parameters:
   *
   * - `preheat_instance_name`: Instance Name
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  DeleteInstanceResponse(params: PreheatService.DeleteInstanceParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/p2p/preheat/instances/${params.preheatInstanceName}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete the specified P2P provider instance
   * @param params The `PreheatService.DeleteInstanceParams` containing the following parameters:
   *
   * - `preheat_instance_name`: Instance Name
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  DeleteInstance(params: PreheatService.DeleteInstanceParams): __Observable<null> {
    return this.DeleteInstanceResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update the specified P2P provider instance
   * @param params The `PreheatService.UpdateInstanceParams` containing the following parameters:
   *
   * - `preheat_instance_name`: Instance Name
   *
   * - `instance`: The instance to update
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  UpdateInstanceResponse(params: PreheatService.UpdateInstanceParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.instance;
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/p2p/preheat/instances/${params.preheatInstanceName}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update the specified P2P provider instance
   * @param params The `PreheatService.UpdateInstanceParams` containing the following parameters:
   *
   * - `preheat_instance_name`: Instance Name
   *
   * - `instance`: The instance to update
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  UpdateInstance(params: PreheatService.UpdateInstanceParams): __Observable<null> {
    return this.UpdateInstanceResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Create a preheat policy under a project
   * @param params The `PreheatService.CreatePolicyParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `policy`: The policy schema info
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  CreatePolicyResponse(params: PreheatService.CreatePolicyParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.policy;
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/projects/${params.projectName}/preheat/policies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Create a preheat policy under a project
   * @param params The `PreheatService.CreatePolicyParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `policy`: The policy schema info
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  CreatePolicy(params: PreheatService.CreatePolicyParams): __Observable<null> {
    return this.CreatePolicyResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List preheat policies
   * @param params The `PreheatService.ListPoliciesParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `q`: Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
   *
   * - `page_size`: The size of per page
   *
   * - `page`: The page number
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return List preheat policies success
   */
  ListPoliciesResponse(params: PreheatService.ListPoliciesParams): __Observable<__StrictHttpResponse<Array<PreheatPolicy>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.q != null) __params = __params.set('q', params.q.toString());
    if (params.pageSize != null) __params = __params.set('page_size', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/projects/${params.projectName}/preheat/policies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PreheatPolicy>>;
      })
    );
  }
  /**
   * List preheat policies
   * @param params The `PreheatService.ListPoliciesParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `q`: Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
   *
   * - `page_size`: The size of per page
   *
   * - `page`: The page number
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return List preheat policies success
   */
  ListPolicies(params: PreheatService.ListPoliciesParams): __Observable<Array<PreheatPolicy>> {
    return this.ListPoliciesResponse(params).pipe(
      __map(_r => _r.body as Array<PreheatPolicy>)
    );
  }

  /**
   * Get a preheat policy
   * @param params The `PreheatService.GetPolicyParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `preheat_policy_name`: Preheat Policy Name
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Get a preheat policy success
   */
  GetPolicyResponse(params: PreheatService.GetPolicyParams): __Observable<__StrictHttpResponse<PreheatPolicy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/projects/${params.projectName}/preheat/policies/${params.preheatPolicyName}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PreheatPolicy>;
      })
    );
  }
  /**
   * Get a preheat policy
   * @param params The `PreheatService.GetPolicyParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `preheat_policy_name`: Preheat Policy Name
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Get a preheat policy success
   */
  GetPolicy(params: PreheatService.GetPolicyParams): __Observable<PreheatPolicy> {
    return this.GetPolicyResponse(params).pipe(
      __map(_r => _r.body as PreheatPolicy)
    );
  }

  /**
   * Update preheat policy
   * @param params The `PreheatService.UpdatePolicyParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `preheat_policy_name`: Preheat Policy Name
   *
   * - `policy`: The policy schema info
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  UpdatePolicyResponse(params: PreheatService.UpdatePolicyParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.policy;
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/projects/${params.projectName}/preheat/policies/${params.preheatPolicyName}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update preheat policy
   * @param params The `PreheatService.UpdatePolicyParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `preheat_policy_name`: Preheat Policy Name
   *
   * - `policy`: The policy schema info
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  UpdatePolicy(params: PreheatService.UpdatePolicyParams): __Observable<null> {
    return this.UpdatePolicyResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Manual preheat
   * @param params The `PreheatService.ManualPreheatParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `preheat_policy_name`: Preheat Policy Name
   *
   * - `policy`: The policy schema info
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  ManualPreheatResponse(params: PreheatService.ManualPreheatParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.policy;
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/projects/${params.projectName}/preheat/policies/${params.preheatPolicyName}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Manual preheat
   * @param params The `PreheatService.ManualPreheatParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `preheat_policy_name`: Preheat Policy Name
   *
   * - `policy`: The policy schema info
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  ManualPreheat(params: PreheatService.ManualPreheatParams): __Observable<null> {
    return this.ManualPreheatResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Delete a preheat policy
   * @param params The `PreheatService.DeletePolicyParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `preheat_policy_name`: Preheat Policy Name
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  DeletePolicyResponse(params: PreheatService.DeletePolicyParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/projects/${params.projectName}/preheat/policies/${params.preheatPolicyName}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a preheat policy
   * @param params The `PreheatService.DeletePolicyParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `preheat_policy_name`: Preheat Policy Name
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  DeletePolicy(params: PreheatService.DeletePolicyParams): __Observable<null> {
    return this.DeletePolicyResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List executions for the given policy
   * @param params The `PreheatService.ListExecutionsParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `preheat_policy_name`: Preheat Policy Name
   *
   * - `q`: Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
   *
   * - `page_size`: The size of per page
   *
   * - `page`: The page number
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return List executions success
   */
  ListExecutionsResponse(params: PreheatService.ListExecutionsParams): __Observable<__StrictHttpResponse<Array<Execution>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.q != null) __params = __params.set('q', params.q.toString());
    if (params.pageSize != null) __params = __params.set('page_size', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/projects/${params.projectName}/preheat/policies/${params.preheatPolicyName}/executions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Execution>>;
      })
    );
  }
  /**
   * List executions for the given policy
   * @param params The `PreheatService.ListExecutionsParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `preheat_policy_name`: Preheat Policy Name
   *
   * - `q`: Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
   *
   * - `page_size`: The size of per page
   *
   * - `page`: The page number
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return List executions success
   */
  ListExecutions(params: PreheatService.ListExecutionsParams): __Observable<Array<Execution>> {
    return this.ListExecutionsResponse(params).pipe(
      __map(_r => _r.body as Array<Execution>)
    );
  }

  /**
   * Get a execution detail by id
   * @param params The `PreheatService.GetExecutionParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `preheat_policy_name`: Preheat Policy Name
   *
   * - `execution_id`: Execution ID
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Get execution success
   */
  GetExecutionResponse(params: PreheatService.GetExecutionParams): __Observable<__StrictHttpResponse<Execution>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/projects/${params.projectName}/preheat/policies/${params.preheatPolicyName}/executions/${params.executionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Execution>;
      })
    );
  }
  /**
   * Get a execution detail by id
   * @param params The `PreheatService.GetExecutionParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `preheat_policy_name`: Preheat Policy Name
   *
   * - `execution_id`: Execution ID
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Get execution success
   */
  GetExecution(params: PreheatService.GetExecutionParams): __Observable<Execution> {
    return this.GetExecutionResponse(params).pipe(
      __map(_r => _r.body as Execution)
    );
  }

  /**
   * Stop a execution
   * @param params The `PreheatService.StopExecutionParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `preheat_policy_name`: Preheat Policy Name
   *
   * - `execution_id`: Execution ID
   *
   * - `execution`: The data of execution
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  StopExecutionResponse(params: PreheatService.StopExecutionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    __body = params.execution;
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/projects/${params.projectName}/preheat/policies/${params.preheatPolicyName}/executions/${params.executionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Stop a execution
   * @param params The `PreheatService.StopExecutionParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `preheat_policy_name`: Preheat Policy Name
   *
   * - `execution_id`: Execution ID
   *
   * - `execution`: The data of execution
   *
   * - `X-Request-Id`: An unique ID for the request
   */
  StopExecution(params: PreheatService.StopExecutionParams): __Observable<null> {
    return this.StopExecutionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List all the related tasks for the given execution
   * @param params The `PreheatService.ListTasksParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `preheat_policy_name`: Preheat Policy Name
   *
   * - `execution_id`: Execution ID
   *
   * - `q`: Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
   *
   * - `page_size`: The size of per page
   *
   * - `page`: The page number
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return List tasks success
   */
  ListTasksResponse(params: PreheatService.ListTasksParams): __Observable<__StrictHttpResponse<Array<Task>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    if (params.q != null) __params = __params.set('q', params.q.toString());
    if (params.pageSize != null) __params = __params.set('page_size', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/projects/${params.projectName}/preheat/policies/${params.preheatPolicyName}/executions/${params.executionId}/tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Task>>;
      })
    );
  }
  /**
   * List all the related tasks for the given execution
   * @param params The `PreheatService.ListTasksParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `preheat_policy_name`: Preheat Policy Name
   *
   * - `execution_id`: Execution ID
   *
   * - `q`: Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
   *
   * - `page_size`: The size of per page
   *
   * - `page`: The page number
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return List tasks success
   */
  ListTasks(params: PreheatService.ListTasksParams): __Observable<Array<Task>> {
    return this.ListTasksResponse(params).pipe(
      __map(_r => _r.body as Array<Task>)
    );
  }

  /**
   * Get the log text stream of the specified task for the given execution
   * @param params The `PreheatService.GetPreheatLogParams` containing the following parameters:
   *
   * - `task_id`: Task ID
   *
   * - `project_name`: The name of the project
   *
   * - `preheat_policy_name`: Preheat Policy Name
   *
   * - `execution_id`: Execution ID
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Get log success
   */
  GetPreheatLogResponse(params: PreheatService.GetPreheatLogParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;




    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/projects/${params.projectName}/preheat/policies/${params.preheatPolicyName}/executions/${params.executionId}/tasks/${params.taskId}/logs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Get the log text stream of the specified task for the given execution
   * @param params The `PreheatService.GetPreheatLogParams` containing the following parameters:
   *
   * - `task_id`: Task ID
   *
   * - `project_name`: The name of the project
   *
   * - `preheat_policy_name`: Preheat Policy Name
   *
   * - `execution_id`: Execution ID
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Get log success
   */
  GetPreheatLog(params: PreheatService.GetPreheatLogParams): __Observable<string> {
    return this.GetPreheatLogResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Get all providers at project level
   * @param params The `PreheatService.ListProvidersUnderProjectParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  ListProvidersUnderProjectResponse(params: PreheatService.ListProvidersUnderProjectParams): __Observable<__StrictHttpResponse<Array<ProviderUnderProject>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/projects/${params.projectName}/preheat/providers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProviderUnderProject>>;
      })
    );
  }
  /**
   * Get all providers at project level
   * @param params The `PreheatService.ListProvidersUnderProjectParams` containing the following parameters:
   *
   * - `project_name`: The name of the project
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  ListProvidersUnderProject(params: PreheatService.ListProvidersUnderProjectParams): __Observable<Array<ProviderUnderProject>> {
    return this.ListProvidersUnderProjectResponse(params).pipe(
      __map(_r => _r.body as Array<ProviderUnderProject>)
    );
  }
}

module PreheatService {

  /**
   * Parameters for PingInstances
   */
  export interface PingInstancesParams {

    /**
     * The JSON object of instance.
     */
    instance: Instance;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for ListInstances
   */
  export interface ListInstancesParams {

    /**
     * Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
     */
    q?: string;

    /**
     * The size of per page
     */
    pageSize?: number;

    /**
     * The page number
     */
    page?: number;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for CreateInstance
   */
  export interface CreateInstanceParams {

    /**
     * The JSON object of instance.
     */
    instance: Instance;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for GetInstance
   */
  export interface GetInstanceParams {

    /**
     * Instance Name
     */
    preheatInstanceName: string;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for DeleteInstance
   */
  export interface DeleteInstanceParams {

    /**
     * Instance Name
     */
    preheatInstanceName: string;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for UpdateInstance
   */
  export interface UpdateInstanceParams {

    /**
     * Instance Name
     */
    preheatInstanceName: string;

    /**
     * The instance to update
     */
    instance: Instance;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for CreatePolicy
   */
  export interface CreatePolicyParams {

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * The policy schema info
     */
    policy: PreheatPolicy;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for ListPolicies
   */
  export interface ListPoliciesParams {

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
     */
    q?: string;

    /**
     * The size of per page
     */
    pageSize?: number;

    /**
     * The page number
     */
    page?: number;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for GetPolicy
   */
  export interface GetPolicyParams {

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * Preheat Policy Name
     */
    preheatPolicyName: string;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for UpdatePolicy
   */
  export interface UpdatePolicyParams {

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * Preheat Policy Name
     */
    preheatPolicyName: string;

    /**
     * The policy schema info
     */
    policy: PreheatPolicy;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for ManualPreheat
   */
  export interface ManualPreheatParams {

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * Preheat Policy Name
     */
    preheatPolicyName: string;

    /**
     * The policy schema info
     */
    policy: PreheatPolicy;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for DeletePolicy
   */
  export interface DeletePolicyParams {

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * Preheat Policy Name
     */
    preheatPolicyName: string;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for ListExecutions
   */
  export interface ListExecutionsParams {

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * Preheat Policy Name
     */
    preheatPolicyName: string;

    /**
     * Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
     */
    q?: string;

    /**
     * The size of per page
     */
    pageSize?: number;

    /**
     * The page number
     */
    page?: number;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for GetExecution
   */
  export interface GetExecutionParams {

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * Preheat Policy Name
     */
    preheatPolicyName: string;

    /**
     * Execution ID
     */
    executionId: number;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for StopExecution
   */
  export interface StopExecutionParams {

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * Preheat Policy Name
     */
    preheatPolicyName: string;

    /**
     * Execution ID
     */
    executionId: number;

    /**
     * The data of execution
     */
    execution: Execution;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for ListTasks
   */
  export interface ListTasksParams {

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * Preheat Policy Name
     */
    preheatPolicyName: string;

    /**
     * Execution ID
     */
    executionId: number;

    /**
     * Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
     */
    q?: string;

    /**
     * The size of per page
     */
    pageSize?: number;

    /**
     * The page number
     */
    page?: number;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for GetPreheatLog
   */
  export interface GetPreheatLogParams {

    /**
     * Task ID
     */
    taskId: number;

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * Preheat Policy Name
     */
    preheatPolicyName: string;

    /**
     * Execution ID
     */
    executionId: number;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }

  /**
   * Parameters for ListProvidersUnderProject
   */
  export interface ListProvidersUnderProjectParams {

    /**
     * The name of the project
     */
    projectName: string;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }
}

export { PreheatService }
