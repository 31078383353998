<clr-main-container>
    <global-message [isAppLevel]="true"></global-message>
    <navigator (showDialogModalAction)="openModal($event)"></navigator>
    <search-result></search-result>
    <div class="login-wrapper" [ngStyle]="{'background-image': customLoginBgImg? 'url(/images/' + customLoginBgImg + ')': ''}">
        <form #signInForm="ngForm" class="login">
            <label class="title"> {{customAppTitle? customAppTitle:(appTitle | translate)}}
            </label>
            <a href="/c/oidc/login" class="login-oidc" *ngIf="isOidcLoginMode">
                <button type="button" id="log_oidc" class="btn btn-primary btn-block">
                    <span>{{'BUTTON.LOG_IN_OIDC' | translate }}</span>
                </button>
            </a>
            <div class="login-group">
                <clr-input-container>
                    <input clrInput class="username" type="text" required [(ngModel)]="signInCredential.principal" name="login_username" id="login_username"
                           placeholder='{{"PLACEHOLDER.SIGN_IN_NAME" | translate}}' #userNameInput='ngModel'>
                    <clr-control-error>{{ 'TOOLTIP.SIGN_IN_USERNAME' | translate }}</clr-control-error>
                </clr-input-container>
                <clr-input-container>
                    <input clrInput class="password" type="password" required [(ngModel)]="signInCredential.password" name="login_password" id="login_password"
                           placeholder='{{"PLACEHOLDER.SIGN_IN_PWD" | translate}}' #passwordInput="ngModel">
                    <clr-control-error>{{ 'TOOLTIP.SIGN_IN_PWD' | translate }}</clr-control-error>
                </clr-input-container>
                <div class="display-flex">
                    <clr-checkbox-wrapper>
                        <input clrCheckbox type="checkbox" id="rememberme" #rememberMeBox (click)="clickRememberMe($event)" [checked]="rememberMe">
                        <label class="reset-label" for="rememberme">{{ 'SIGN_IN.REMEMBER' | translate }}</label>
                    </clr-checkbox-wrapper>
                </div>
                <div [class.visibility-hidden]="!isError" class="error active">
                    <span *ngIf="isCoreServiceAvailable">{{ 'SIGN_IN.INVALID_MSG' | translate }}</span>
                    <span *ngIf="!isCoreServiceAvailable">{{ 'SIGN_IN.CORE_SERVICE_NOT_AVAILABLE' | translate }}</span>
                </div>
                <button [disabled]="isOnGoing || !isValid" type="submit" class="btn btn-primary" (click)="signIn()" id="log_in">{{ 'BUTTON.LOG_IN' | translate }}</button>
                <a href="javascript:void(0)" class="signup" (click)="signUp()" *ngIf="selfSignUp">{{ 'BUTTON.SIGN_UP_LINK' | translate }}</a>
            </div>
            <div>
                <a href="https://github.com/goharbor/harbor" target="_blank" class="more-info-link">{{ 'BUTTON.MORE_INFO' | translate }}</a>
            </div>
        </form>
        <div *ngIf="appConfig.show_popular_repo" id="pop_repo" class="popular-repo-wrapper">
            <top-repo class="repo-container"></top-repo>
        </div>
    </div>
</clr-main-container>
<sign-up #signupDialog (userCreation)="handleUserCreation($event)"></sign-up>
<about-dialog></about-dialog>
