<form #projectPolicyForm="ngForm" class="clr-form clr-form-horizontal">
    <section class="form-block" style="position: relative;">
        <clr-input-container>
            <label>{{ 'PROJECT_CONFIG.OWNERSHIP' | translate }}</label>
            <input clrInput type="text" [disabled]="!hasChangeConfigRole" [(ngModel)]="ownership" name="ownership" />
        </clr-input-container>
        <div id="clr-wrapper-public" class="clr-form-control">
            <label class="clr-control-label" style="line-height: 1.5rem;">{{ 'PROJECT_CONFIG.REGISTRY' | translate }}</label>
            <div class="w-100 clr-control-container">
                <div class="clr-control-label">
                    <span *ngIf="projectPolicy.Public">{{ 'PROJECT.PUBLIC' | translate }}</span>
                    <span *ngIf="!projectPolicy.Public">{{ 'PROJECT.PRIVATE' | translate }}</span>
                    <button type="button" class="btn btn-outline" (click)="openToggleModal()" style="margin-left: 1rem;"
                        [disabled]="!hasChangeConfigRole">{{'PROJECT_CONFIG.TOGGLE_PUBLIC' | translate}}</button>
                </div>
                <div class="config-subtext">
                    {{ 'PROJECT_CONFIG.PUBLIC_POLICY' | translate }}
                </div>
            </div>
        </div>
        <clr-checkbox-container *ngIf="withNotary && !isProxyCacheProject">
            <label><span *ngIf="withNotary && !isProxyCacheProject">{{ 'PROJECT_CONFIG.SECURITY' | translate }}</span></label>
            <clr-checkbox-wrapper *ngIf="withNotary && !isProxyCacheProject">
                <input type="checkbox" clrCheckbox [(ngModel)]="projectPolicy.ContentTrust" name="content-trust"
                    [disabled]="!hasChangeConfigRole" />
                <label>{{ 'PROJECT_CONFIG.CONTENT_TRUST_TOGGLE' | translate }}</label>
            </clr-checkbox-wrapper>
            <clr-control-helper class="config-subtext"> {{ 'PROJECT_CONFIG.CONTENT_TRUST_POLCIY' | translate }}
            </clr-control-helper>
        </clr-checkbox-container>
        <clr-checkbox-container id="prevent-vulenrability-image" class="margin-top-05">
            <label><span *ngIf="!(withNotary && !isProxyCacheProject)">{{ 'PROJECT_CONFIG.SECURITY' | translate }}</span></label>
            <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox [(ngModel)]="projectPolicy.PreventVulImg"
                    name="prevent-vulenrability-image-input" [disabled]="!hasChangeConfigRole" />
                <label>{{ 'PROJECT_CONFIG.PREVENT_VULNERABLE_TOGGLE' | translate }}</label>
            </clr-checkbox-wrapper>
            <clr-control-helper class="config-subtext">
                <div class="chk-explain">
                    <label class="config-subtext">
                        <div id="severity-blk">
                            <div>{{ 'PROJECT_CONFIG.PREVENT_VULNERABLE_1' | translate }}</div>
                            <div class="clr-select-wrapper">
                                <select id="severity" name="severity" class="clr-select"
                                    [(ngModel)]="projectPolicy.PreventVulImgSeverity"
                                    [disabled]="!projectPolicy.PreventVulImg">
                                    <option *ngFor='let s of severityOptions' [ngValue]="s.severity">
                                        {{ s.severityLevel | translate }}</option>
                                </select>
                            </div>
                            <div> {{ 'PROJECT_CONFIG.PREVENT_VULNERABLE_2' | translate }} </div>
                        </div>
                    </label>
                </div>
            </clr-control-helper>
        </clr-checkbox-container>
        <clr-checkbox-container>
            <label>{{ 'PROJECT_CONFIG.SCAN' | translate }}</label>
            <clr-checkbox-wrapper id="scan-image-on-push-wrapper">
                <input type="checkbox" clrCheckbox [(ngModel)]="projectPolicy.ScanImgOnPush"
                    [disabled]="!hasChangeConfigRole" name="scan-image-on-push" />
                <label>{{ 'PROJECT_CONFIG.AUTOSCAN_TOGGLE' | translate }}</label>
            </clr-checkbox-wrapper>
            <clr-control-helper class="config-subtext"> {{ 'PROJECT_CONFIG.AUTOSCAN_POLICY' | translate }}
            </clr-control-helper>
        </clr-checkbox-container>
        <div *ngIf="systemInfo" class="clr-form-control" [class.clr-form-control-disabled]="!hasChangeConfigRole">
            <label for="systemAllowlist" class="clr-control-label">{{'CVE_ALLOWLIST.CVE_ALLOWLIST'|translate}}</label>
            <div class="w-100 clr-control-container">
                <div class="config-subtext">
                    <div>
                        <span>{{'CVE_ALLOWLIST.PRO_ALLOWLIST_EXPLAIN'|translate}}</span>
                    </div>
                    <div>
                        <span>{{'CVE_ALLOWLIST.PRO_OR_SYS'|translate}}</span>
                    </div>
                    <div>
                        <span>{{'CVE_ALLOWLIST.MERGE_INTO'|translate}}</span>
                    </div>
                    <div *ngIf="hasExpired">
                        <span *ngIf="isUseSystemAllowlist()"
                            class="label label-warning">{{'CVE_ALLOWLIST.WARNING_SYS'|translate}}</span>
                        <span *ngIf="!isUseSystemAllowlist()"
                            class="label label-warning">{{'CVE_ALLOWLIST.WARNING_PRO'|translate}}</span>
                    </div>
                </div>
                <clr-radio-container clrInline>
                    <clr-radio-wrapper>
                        <input id="use-system" [attr.disabled]="!hasChangeConfigRole?'disabled':null" type="radio" clrRadio
                            name="systemAllowlistOrProjectAllowlist" required value="true"
                            [(ngModel)]="systemAllowlistOrProjectAllowlist" />
                        <label>{{'CVE_ALLOWLIST.SYS_ALLOWLIST'|translate}}</label>
                    </clr-radio-wrapper>
                    <clr-radio-wrapper>
                        <input id="use-project" [attr.disabled]="!hasChangeConfigRole?'disabled':null" type="radio" clrRadio
                            name="systemAllowlistOrProjectAllowlist" required value="false"
                            [(ngModel)]="systemAllowlistOrProjectAllowlist" />
                        <label>{{'CVE_ALLOWLIST.PRO_ALLOWLIST'|translate}}</label>
                    </clr-radio-wrapper>
                </clr-radio-container>
                <div class="clr-row width-90per">
                    <div class="clr-col position-relative col-flex-grow-0 ">
                        <div>
                            <button id="show-add-modal" [disabled]="isUseSystemAllowlist() || !hasChangeConfigRole"
                                (click)="showAddModal=!showAddModal"
                                class="btn btn-link">{{'CVE_ALLOWLIST.ADD'|translate}}</button>
                            <button id="add-system" [disabled]="isUseSystemAllowlist() || !hasChangeConfigRole"
                                (click)="addSystem()"
                                class="btn btn-link ml-1">{{'CVE_ALLOWLIST.ADD_SYSTEM'|translate}}</button>
                        </div>
                        <div class="add-modal add-modal-dark" *ngIf="showAddModal && !isUseSystemAllowlist()">
                            <clr-icon (click)="showAddModal=false" class="float-lg-right margin-top-4"
                                shape="window-close"></clr-icon>
                            <div>
                                <clr-textarea-container class="flex-direction-column">
                                    <label>{{'CVE_ALLOWLIST.ENTER'|translate}}</label>
                                    <textarea id="allowlist-textarea" class="w-100" clrTextarea [(ngModel)]="cveIds"
                                        name="cveIds"></textarea>
                                    <clr-control-helper>{{'CVE_ALLOWLIST.HELP'|translate}}</clr-control-helper>
                                </clr-textarea-container>
                            </div>
                            <div>
                                <button id="add-to-allowlist" [disabled]="isDisabled()"
                                    (click)="addToProjectAllowlist()"
                                    class="btn btn-link">{{'CVE_ALLOWLIST.ADD'|translate}}</button>
                            </div>
                        </div>
                        <ul class="allowlist-window" *ngIf="isUseSystemAllowlist()">
                            <li *ngIf="systemAllowlist?.items?.length<1" class="none">{{'CVE_ALLOWLIST.NONE'|translate}}
                            </li>
                            <li *ngFor="let item of systemAllowlist?.items">
                                <a href="javascript:void(0)" (click)="goToDetail(item.cve_id)">{{item.cve_id}}</a>
                            </li>
                        </ul>
                        <ul class="allowlist-window" *ngIf="!isUseSystemAllowlist()">
                            <li class="none" *ngIf="projectAllowlist?.items?.length<1">
                                {{'CVE_ALLOWLIST.NONE'|translate}}</li>
                            <li *ngFor="let item of projectAllowlist?.items;let i = index;">
                                <a href="javascript:void(0)" (click)="goToDetail(item.cve_id)">{{item.cve_id}}</a>
                                <a  class="float-lg-right" href="javascript:void(0)" (click)="deleteItem(i)">
                                    <clr-icon shape="times-circle"></clr-icon>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="clr-col padding-top-16 pl-2">
                        <div class="clr-row expire-data">
                            <label for="expires"
                                class="bottom-line bottom-line-project-config clr-col-3">{{'CVE_ALLOWLIST.EXPIRES_AT'|translate}}</label>
                            <div class="underline">
                                <input #dateSystemInput readonly type="date" [(clrDate)]="systemExpiresDate">
                                <input [disabled]="!hasChangeConfigRole" *ngIf="!isUseSystemAllowlist()" #dateInput
                                    placeholder="{{'CVE_ALLOWLIST.NEVER_EXPIRES'|translate}}" readonly type="date"
                                    [(clrDate)]="expiresDate" newFormLayout="true">
                                <input clrInput [disabled]="!hasChangeConfigRole" *ngIf="isUseSystemAllowlist()"
                                    placeholder="{{'CVE_ALLOWLIST.NEVER_EXPIRES'|translate}}" readonly type="text"
                                    value="{{systemExpiresDateString}}">
                            </div>
                        </div>
                        <div class="clr-row">
                            <label for="expires" class="clr-col-3"></label>
                            <clr-checkbox-wrapper>
                                <input [disabled]="isUseSystemAllowlist() || !hasChangeConfigRole"
                                    [checked]="neverExpires" [(ngModel)]="neverExpires" type="checkbox" clrCheckbox
                                    name="neverExpires" id="neverExpires" />
                                <label>
                                    {{'CVE_ALLOWLIST.NEVER_EXPIRES'|translate}}
                                </label>
                            </clr-checkbox-wrapper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button type="button" class="btn btn-primary" (click)="save()"
            [disabled]="((!isValid() || !hasAllChanges()) && !hasAllowlistChanged) || !hasChangeConfigRole">{{'BUTTON.SAVE'
            | translate}}</button>
        <button type="button" class="btn btn-outline" (click)="cancel()"
            [disabled]="((!isValid() || !hasAllChanges()) && !hasAllowlistChanged) || !hasChangeConfigRole">{{'BUTTON.CANCEL'
            | translate}}</button>
        <confirmation-dialog #cfgConfirmationDialog (confirmAction)="confirmCancel($event)"></confirmation-dialog>
        <div *ngIf="onGoing" style="position: absolute; background-color: rgba(0, 0, 0, 0.3); height: 100%; top: 0; width: 100%; display: flex; align-items: center; justify-content: center;">
            <div class="spinner"></div>
        </div>
    </section>
    <hbr-project-public-toggle-modal #toggleModal></hbr-project-public-toggle-modal>
</form>
