<div class="row robot-space">
  <div>
    <div class="row flex-items-xs-between rightPos">
      <div class="flex-xs-middle option-left">
      </div>
      <div class="flex-xs-middle option-right">
        <hbr-filter [withDivider]="true" filterPlaceholder='{{"ROBOT_ACCOUNT.FILTER_PLACEHOLDER" | translate}}'
          (filterEvt)="doSearch($event)" [currentValue]="searchRobot"></hbr-filter>
        <span class="refresh-btn" (click)="retrieve()">
          <clr-icon shape="refresh"></clr-icon>
        </span>
      </div>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <clr-dg-action-bar>
      <button class="btn  btn-secondary" [disabled]="!hasRobotCreatePermission" (click)="openAddRobotModal()">
        <span>
          <clr-icon shape="plus" size="16"></clr-icon>&nbsp;{{'ROBOT_ACCOUNT.NEW_ROBOT_ACCOUNT'
          | translate }}
        </span>
      </button>
      <clr-dropdown [clrCloseMenuOnItemClick]="false" class="btn
        btn-link" clrDropdownTrigger>
        <span>{{'MEMBER.ACTION' | translate}}<clr-icon shape="caret
            down"></clr-icon></span>
        <clr-dropdown-menu *clrIfOpen>
          <button clrDropdownItem [disabled]="!hasRobotUpdatePermission||!(selectedRow.length ==
            1)" (click)="changeAccountStatus(selectedRow)">
            <span *ngIf="selectedRow[0] && !selectedRow[0].disabled
              || selectedRow.length!==1">{{'ROBOT_ACCOUNT.DISABLE_ACCOUNT'
              | translate}}</span>
            <span *ngIf="selectedRow.length == 1 && selectedRow[0]
              && selectedRow[0].disabled">{{'ROBOT_ACCOUNT.ENABLE_ACCOUNT'
              | translate}}</span>
          </button>
          <div class="dropdown-divider"></div>
          <button clrDropdownItem (click)="openDeleteRobotsDialog(selectedRow)" [disabled]="!hasRobotDeletePermission || !selectedRow.length">{{'ROBOT_ACCOUNT.DELETE'
            | translate}}</button>
        </clr-dropdown-menu>
      </clr-dropdown>
    </clr-dg-action-bar>
    <clr-datagrid [(clrDgSelected)]="selectedRow" [clrDgLoading]="loading">
      <clr-dg-column>{{'ROBOT_ACCOUNT.NAME' | translate}}</clr-dg-column>
      <clr-dg-column>{{'ROBOT_ACCOUNT.ENABLED_STATE' | translate}}</clr-dg-column>
      <clr-dg-column>{{'ROBOT_ACCOUNT.DESCRIPTION' | translate}}</clr-dg-column>
      <clr-dg-column>{{'ROBOT_ACCOUNT.CREATETION' | translate}}</clr-dg-column>
      <clr-dg-column>{{'ROBOT_ACCOUNT.EXPIRATION' | translate}}</clr-dg-column>
      <clr-dg-row *clrDgItems="let r of robots" [clrDgItem]="r">
        <clr-dg-cell>{{r.name}}</clr-dg-cell>
        <clr-dg-cell [ngSwitch]="r.disabled">
          <clr-icon shape="check-circle" *ngSwitchCase="false" size="20" class="color-green"></clr-icon>
          <clr-icon shape="times-circle" *ngSwitchCase="true" size="16" class="color-red red-position"></clr-icon>
        </clr-dg-cell>
        <clr-dg-cell>{{r.description}}</clr-dg-cell>
        <clr-dg-cell>{{r.creation_time | date: 'short'}}</clr-dg-cell>
        <clr-dg-cell>{{r.expires_at === -1?("ROBOT_ACCOUNT.NEVER_EXPIRED" | translate):(r.expires_at * 1000 | date: 'short')}}</clr-dg-cell>
      </clr-dg-row>
      <clr-dg-footer>
        <span *ngIf="pagination.totalItems">{{pagination.firstItem + 1}}
          -
          {{pagination.lastItem +1 }} {{'ROBOT_ACCOUNT.OF' |
          translate}} </span>
        {{pagination.totalItems }} {{'ROBOT_ACCOUNT.ITEMS' | translate}}
        <clr-dg-pagination #pagination [clrDgPageSize]="15"></clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>
  </div>
  <add-robot [projectId]="projectId" [projectName]="projectName" (create)="createAccount($event)"></add-robot>
</div>
