<h2 class="custom-h2" sub-header-title>{{'SIDE_NAV.SYSTEM_MGMT.INTERROGATION_SERVICES' | translate}}</h2>
<nav class="mt-1">
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" routerLink="scanners" routerLinkActive="active">{{'SCANNER.SCANNERS' | translate }}</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="vulnerability" routerLinkActive="active">{{'CONFIG.VULNERABILITY' | translate }}</a>
        </li>
    </ul>
</nav>
<router-outlet></router-outlet>


