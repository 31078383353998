<clr-modal [(clrModalOpen)]="opened" [clrModalStaticBackdrop]="staticBackdrop" [clrModalClosable]="false">
    <h3 class="modal-title">{{'SIGN_UP.TITLE' | translate}}</h3>
    <inline-alert class="modal-title" (confirmEvt)="confirmCancel($event)"></inline-alert>
    <div class="modal-body body-format">
        <new-user-form isSelfRegistration="true" (valueChange)="formValueChange($event)"></new-user-form>
    </div>
    <div class="modal-footer">
        <span class="spinner spinner-inline loading-top" [hidden]="inProgress === false"> </span>
        <button type="button" class="btn btn-outline" id="close-btn" (click)="close()">{{'BUTTON.CANCEL' | translate}}</button>
        <button id="sign-up" type="button" class="btn btn-primary" [disabled]="!isValid || inProgress" (click)="create()">{{ 'BUTTON.SIGN_UP' | translate }}</button>
    </div>
</clr-modal>