<div class="arrow-block" *ngIf="!withAdmiral">
    <a class="pl-0" (click)="goBackPro()">{{'SIDE_NAV.PROJECTS'| translate}}</a>
    <span class="back-icon"><</span>
    <a (click)="goBackRep()">{{projectName}}</a>
    <span class="back-icon"><</span>
    <a (click)="goBack()">{{repositoryName}}</a>

    <span *ngFor="let digest of referArtifactNameArray;let i = index">
                    &lt;<a (click)="jumpDigest(i)" >{{digest | slice:0:15}}</a></span>
</div>

<div class="title-wrapper">
    <div class="title-block arrow-block" *ngIf="withAdmiral">
        <clr-icon class="rotate-90 arrow-back" shape="arrow" size="36" (click)="onBack()"></clr-icon>
    </div>
    <div class="title-block">
        <h2 class="custom-h2 center-align-items">
            <div class="artifact-icon clr-display-inline-block" *ngIf="artifact.icon">
                <img *ngIf="getIcon(artifact.icon)" class="artifact-icon" [title]="artifact.type"
                     [src]="getIcon(artifact.icon)" (error)="showDefaultIcon($event)" />
            </div>
            <span class="margin-left-10px">{{artifact?.digest | slice:0:15}}</span>
            <clr-icon size="25" *ngIf="artifact?.references && artifact?.references?.length" class="icon-folder margin-left-10px" shape="folder"></clr-icon>
        </h2>
    </div>
</div>
<ng-container *ngIf="!loading">
    <!-- tags -->
    <artifact-tag [artifactDetails]="artifact" [projectName]="projectName" [isProxyCacheProject]="isProxyCacheProject" [projectId]="projectId" [repositoryName]="repositoryName"
                  ></artifact-tag>

    <!-- Overview -->
    <artifact-common-properties [artifactDetails]="artifact"></artifact-common-properties>

    <!-- Additions -->
    <artifact-additions [artifact]="artifact"
            [projectName]="projectName"
            [projectId]="projectId"
            [repoName]="repositoryName"
            [digest]="artifactDigest"
                        [additionLinks]="artifact?.addition_links"></artifact-additions>
</ng-container>
<div *ngIf="loading" class="clr-row mt-3 center">
    <span class="spinner spinner-md"></span>
</div>

