<clr-modal [(clrModalOpen)]="addRuleOpened"
           [clrModalStaticBackdrop]="true" [clrModalClosable]="true" [clrModalSize]="'lg'">
    <h3 class="modal-title" *ngIf="isAdd">{{'TAG_RETENTION.ADD_TITLE' | translate}}</h3>
    <h3 class="modal-title" *ngIf="!isAdd">{{'TAG_RETENTION.EDIT_TITLE' | translate}}</h3>
    <div class="modal-body no-scrolling">
        <inline-alert class="modal-title"></inline-alert>
        <p class="color-97">{{'TAG_RETENTION.ADD_SUBTITLE' | translate}}</p>
        <div class="height-72">
            <div class="clr-row mt-1">
                <div class="clr-col-4">
                    <span>{{'TAG_RETENTION.IN_REPOSITORIES' | translate}}</span>
                </div>
                <div class="clr-col-2">
                    <div class="clr-select-wrapper w-100">
                        <select [(ngModel)]="repoSelect" class="clr-select w-100">
                             <option *ngFor="let d of metadata?.scope_selectors[0]?.decorations"
                                      value="{{d}}">{{getI18nKey(d)|translate}}</option>
                        </select>
                    </div>
                </div>
                <div class="clr-col-6">
                    <div class="w-100">
                        <input id="repos" required [(ngModel)]="repositories" class="clr-input w-100">
                    </div>
                </div>
            </div>
            <div class="clr-row">
                <div class="clr-col-4"></div>
                <div class="clr-col-8 margin-top-5px">
                    <span class="tootip">{{'TAG_RETENTION.REP_SEPARATOR' | translate}}</span>
                </div>
            </div>
        </div>
        <div class="height-72">
            <div class="clr-row">
                <div class="clr-col-4">
                    <div class="over-line"></div>
                    <label>{{'TAG_RETENTION.BY_WHAT' | translate}}</label>
                </div>
                <div class="clr-col-5">
                    <div class="over-line"></div>
                    <div class="clr-select-wrapper w-100">
                        <select id="template" [(ngModel)]="template" class="clr-select w-100">
                            <option class="display-none" value=""></option>
                            <option *ngFor="let t of metadata?.templates"
                                    value="{{t?.rule_template}}">{{getI18nKey(t?.action)|translate}}{{getI18nKey(t?.display_text)|translate}}</option>
                        </select>
                    </div>
                </div>
                <div class="clr-col-3">
                    <div class="over-line">
                        <span *ngIf="hasParam()">{{getI18nKey(unit)|translate}}</span>
                    </div>
                    <div class="w-100 disabled">
                        <input id="param" *ngIf="hasParam()" [(ngModel)]="num" class="clr-input w-100">
                    </div>
                </div>
            </div>
        </div>
        <div class="height-95">
            <div class="clr-row">
                <div class="clr-col-4">
                    <label>{{'TAG_RETENTION.TAGS' | translate}}</label>
                </div>
                <div class="clr-col-2">
                    <div class="clr-select-wrapper w-100">
                        <select [(ngModel)]="tagsSelect" class="clr-select w-100">
                            <option *ngFor="let d of metadata?.tag_selectors[0]?.decorations"
                                    value="{{d}}">{{getI18nKey(d)|translate}}</option>
                        </select>
                    </div>
                </div>
                <div class="clr-col-3">
                    <div class="w-100">
                        <input id="tags" required [(ngModel)]="tagsInput" class="clr-input w-100">
                    </div>
                </div>
                <div class="clr-col-3 p-0">
                    <div class="w-100 untagged">
                        <label for="untagged">{{'TAG_RETENTION.INCLUDE_UNTAGGED' | translate}}</label>
                        <input type="checkbox" [(ngModel)]="untagged" name="untagged" id="untagged" class="clr-input w-100" clrCheckbox />
                    </div>
                </div>
            </div>
            <div class="clr-row">
                <div class="clr-col-4"></div>
                <div class="clr-col-8 margin-top-5px">
                    <span class="tootip">{{'TAG_RETENTION.TAG_SEPARATOR' | translate}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="cancel()">{{'BUTTON.CANCEL' | translate}}</button>
        <button id="save-add" [disabled]="canNotAdd()" type="button" class="btn btn-primary" (click)="add()">
            <span *ngIf="isAdd">{{'BUTTON.ADD' | translate}}</span>
            <span *ngIf="!isAdd">{{'BUTTON.SAVE' | translate}}</span>
        </button>
    </div>
</clr-modal>
