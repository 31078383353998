import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from "rxjs/operators";
import { Observable, throwError as observableThrowError } from "rxjs";
import { CURRENT_BASE_HREF, HTTP_GET_OPTIONS } from "../../lib/utils/utils";

const notificationsEndpoint = CURRENT_BASE_HREF + "/notifications";

@Injectable({
  providedIn: 'root'
})
export class BackendMessageService {

  constructor(private http: HttpClient) { }

  getNotifications(): Observable<any> {
    return this.http.get(notificationsEndpoint, HTTP_GET_OPTIONS)
      .pipe(catchError(error => observableThrowError(error)));
  }
}
