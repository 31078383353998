/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AuditLog } from '../models/audit-log';
@Injectable({
  providedIn: 'root',
})
class AuditlogService extends __BaseService {
  static readonly listAuditLogsPath = '/audit-logs';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * This endpoint let user see the recent operation logs of the projects which he is member of
   * @param params The `AuditlogService.ListAuditLogsParams` containing the following parameters:
   *
   * - `q`: Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
   *
   * - `page_size`: The size of per page
   *
   * - `page`: The page number
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  listAuditLogsResponse(params: AuditlogService.ListAuditLogsParams): __Observable<__StrictHttpResponse<Array<AuditLog>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.q != null) __params = __params.set('q', params.q.toString());
    if (params.pageSize != null) __params = __params.set('page_size', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/audit-logs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AuditLog>>;
      })
    );
  }
  /**
   * This endpoint let user see the recent operation logs of the projects which he is member of
   * @param params The `AuditlogService.ListAuditLogsParams` containing the following parameters:
   *
   * - `q`: Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
   *
   * - `page_size`: The size of per page
   *
   * - `page`: The page number
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  listAuditLogs(params: AuditlogService.ListAuditLogsParams): __Observable<Array<AuditLog>> {
    return this.listAuditLogsResponse(params).pipe(
      __map(_r => _r.body as Array<AuditLog>)
    );
  }
}

module AuditlogService {

  /**
   * Parameters for listAuditLogs
   */
  export interface ListAuditLogsParams {

    /**
     * Query string to query resources. Supported query patterns are "exact match(k=v)", "fuzzy match(k=~v)", "range(k=[min~max])", "list with union releationship(k={v1 v2 v3})" and "list with intersetion relationship(k=(v1 v2 v3))". The value of range and list can be string(enclosed by " or '), integer or time(in format "2020-04-09 02:36:00"). All of these query patterns should be put in the query string "q=xxx" and splitted by ",". e.g. q=k1=v1,k2=~v2,k3=[min~max]
     */
    q?: string;

    /**
     * The size of per page
     */
    pageSize?: number;

    /**
     * The page number
     */
    page?: number;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }
}

export { AuditlogService }
