<div>
    <div class="flex-container">
        <div class="title-container">
            <div class="chart-name">
                {{chartNameWithVersion | translate}}
            </div>
            <div>
                {{roleName | translate}}
            </div>
        </div>
        <div>
            <button class="btn  btn-secondary"
            (click)="downloadChart()">{{'HELM_CHART.DOWNLOAD' | translate}}</button>
        </div>
    </div>
    <span *ngIf="loading" class="spinner spinner-lg detail-loading">
        Loading...
    </span>
    <div *ngIf="!loading && isChartExist">
        <clr-tabs>
            <clr-tab>
                <button clrTabLink id="summary-link">{{'HELM_CHART.SUMMARY' | translate}}</button>
                <clr-tab-content id="summary-content" *clrIfActive>
                    <hbr-chart-detail-summary
                    [summary]="chartDetail.metadata"
                    [chartName]="chartName"
                    [repoURL]="repoURL"
                    [projectName]="project.name"
                    [chartVersion]="chartVersion"
                    [security]="chartDetail.security"
                    [readme]="chartDetail.files['README.md']"
                    [labels]="chartDetail.labels"></hbr-chart-detail-summary>
                </clr-tab-content>
            </clr-tab>
            <clr-tab>
                <button clrTabLink id="depend-link">{{'HELM_CHART.DEPENDENCIES' | translate}}</button>
                <clr-tab-content id="depend-content">
                    <hbr-chart-detail-dependency [dependencies]='chartDetail.dependencies'></hbr-chart-detail-dependency>
                </clr-tab-content>
            </clr-tab>
            <clr-tab>
                <button clrTabLink id="value-link">{{'HELM_CHART.VALUES' | translate}}</button>
                <clr-tab-content id="value-content">
                    <hbr-chart-detail-value
                    [values]="chartDetail.values"
                    [yaml]="chartDetail.files['values.yaml']"></hbr-chart-detail-value>
                </clr-tab-content>
            </clr-tab>
        </clr-tabs>
    </div>
    <div *ngIf="!loading && !isChartExist">
        <h6>{{'HELM_CHART.NO_DETAIL' | translate }}</h6>
    </div>

</div>
