<div>
    <div class="row chart-tool">
        <div class="toolbar">
            <div class="row flex-items-xs-right option-right rightPos">
                <div class="flex-xs-middle">
                    <hbr-filter [withDivider]="true" filterPlaceholder="{{'HELM_CHART.FILTER_FOR_CHARTS' | translate}}" [currentValue]="lastFilteredChartName"
                        (filterEvt)="updateFilterValue($event)"></hbr-filter>
                    <span class="card-btn" (click)="showCard(true)" (mouseenter)="mouseEnter('card') " (mouseleave)="mouseLeave('card')">
                        <clr-icon [ngClass]="{'is-highlight': isCardView || isHovering('card') }" shape="view-cards"></clr-icon>
                    </span>
                    <span class="list-btn" (click)="showCard(false)" (mouseenter)="mouseEnter('list') " (mouseleave)="mouseLeave('list')">
                        <clr-icon [ngClass]="{'is-highlight': !isCardView || isHovering('list') }" shape="view-list"></clr-icon>
                    </span>
                    <span class="filter-divider"></span>
                    <span class="refresh-btn" (click)="refresh()">
                        <clr-icon shape="refresh"></clr-icon>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div *ngIf="!isCardView" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <clr-datagrid (clrDgRefresh)="refresh()" [clrDgLoading]="loading" [(clrDgSelected)]="selectedRows">
                <clr-dg-action-bar>
                    <button type="button" id="helm-chart-upload" class="btn  btn-secondary"
                        [disabled]="!hasUploadHelmChartsPermission" (click)="onChartUpload()">
                        <clr-icon shape="upload" size="16"></clr-icon>{{'HELM_CHART.UPLOAD' | translate}}
                    </button>
                    <button type="button" class="btn  btn-secondary" [disabled]="!hasDeleteHelmChartsPermission || selectedRows.length<1"
                        (click)="openChartDeleteModal()">
                        <clr-icon shape="trash" size="16"></clr-icon>{{'BUTTON.DELETE' | translate}}
                    </button>
                    <button type="button" class="btn  btn-secondary" [disabled]="!hasDownloadHelmChartsPermission ||selectedRows.length!==1"
                        (click)="downloadLatestVersion()">
                        <clr-icon shape="download" size="16"></clr-icon>{{'HELM_CHART.DOWNLOAD' | translate}}
                    </button>
                </clr-dg-action-bar>
                <clr-dg-column>{{'HELM_CHART.NAME' | translate}}</clr-dg-column>
                <clr-dg-column>{{'HELM_CHART.STATUS' | translate}}</clr-dg-column>
                <clr-dg-column>{{'HELM_CHART.CHARTVERSIONS' | translate}}</clr-dg-column>
                <clr-dg-column>{{'HELM_CHART.CREATED' | translate}}</clr-dg-column>
                <clr-dg-placeholder>{{'HELM_CHART.PLACEHOLDER' | translate }}</clr-dg-placeholder>
                <clr-dg-row *ngFor="let chart of charts" [clrDgItem]="chart">
                    <clr-dg-cell>
                        <span class="list-img">
                            <img class="size-24 margin-right-12" [src]="chart.icon ?chart.icon:chartDefaultIcon" (error)="getDefaultIcon(chart);" />
                        </span>
                        <a href="javascript:void(0)" (click)="onChartClick(chart)">{{ chart.name }}</a>
                    </clr-dg-cell>
                    <clr-dg-cell class="table-center">{{ getStatusString(chart) | translate }}</clr-dg-cell>
                    <clr-dg-cell class="table-center">{{ chart.total_versions }}</clr-dg-cell>
                    <clr-dg-cell class="table-center">{{ chart.created | date }}</clr-dg-cell>
                </clr-dg-row>
                <clr-dg-footer>
                    <clr-dg-pagination #pagination [clrDgPageSize]="pageSize" [clrDgTotalItems]="totalCount">
                        <span *ngIf="pagination.totalItems">
                            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} {{'HELM_CHART.OF' | translate}}
                        </span>
                        {{pagination.totalItems}} {{'HELM_CHART.ITEMS'| translate}}
                    </clr-dg-pagination>
                </clr-dg-footer>
            </clr-datagrid>
        </div>
    </div>
    <div *ngIf="isCardView" class="row card-container version-position">
        <div *ngFor="let item of charts;" class="chart-card">
            <a let i=index; class="card clickable" (click)="onChartClick(item)">
                <div class="card-header">
                    <div class="card-icon">
                        <img class="size-60" [src]="item.icon ?item.icon:chartDefaultIcon" (error)="getDefaultIcon(item);" />
                    </div>
                    <div class="card-title">{{item.name}}</div>
                </div>
                <div class="card-footer">
                    <div class="row flex-items-xs-between">
                        <div>
                            <span class="version-text">{{item.total_versions}}</span>
                            <label class="card-label" *ngIf="item.total_versions !== 1">{{'HELM_CHART.CHARTVERSIONS' | translate}}
                            </label>
                            <label class="card-label" *ngIf="item.total_versions === 1">{{'HELM_CHART.VERSION' | translate}}
                            </label>
                        </div>
                        <div>
                            <span class="label" [class.label-danger]="item.deprecated" [class.label-success]="!item.deprecated">{{getStatusString(item) | translate}}</span>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div *ngIf="loading" class="center-x">
            <span class="vertical-helper"></span>
            <span class="spinner"></span>
        </div>
    </div>
    <confirmation-dialog #confirmationDialog (confirmAction)="confirmDeletion($event)"></confirmation-dialog>
    <clr-modal [(clrModalOpen)]="isUploadModalOpen" [clrModalStaticBackdrop]="true" [clrModalClosable]="false">
        <h3 class="modal-title">{{'HELM_CHART.UPLOAD_TITLE' | translate | titlecase}}</h3>
        <div class="modal-body">
            <form #chartUploadForm="ngForm" clrForm enctype="multipart/form-data">
                <div class="clr-form-control">
                    <label class="filename-label clr-control-label clr-col-md-3"> {{'HELM_CHART.CHART_FILE' | translate}}</label>
                    <div class="clr-input-wrapper">
                        <input class="filename-input clr-input" type="text" placeholder="{{this.chartFile?.name || 'BUTTON.NO_FILE' | translate}}"
                            disabled>
                        <label for="chart" class="btn btn-secondary  file-browser-btn">{{'BUTTON.BROWSE' | translate}}
                        </label>
                        <input class="file-input" type="file" id="chart" name="chart" ngModel (change)="onChartFileChangeEvent($event)">
                    </div>
                </div>
                <div class="clr-form-control mb-10">
                    <label class="filename-label clr-control-label clr-col-md-3"> {{'HELM_CHART.CHART_PROV' | translate}} </label>
                    <div class="clr-input-wrapper">
                        <input class="filename-input clr-input" type="text" placeholder="{{this.provFile?.name || 'BUTTON.NO_FILE' | translate}}"
                            disabled>
                        <label for="prov" class="btn btn-secondary  file-browser-btn">{{'BUTTON.BROWSE' | translate}}
                        </label>
                        <input class="file-input" type="file" id="prov" name="prov" ngModel (change)="onProvFileChangeEvent($event)">
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button class="btn btn-secondary" [disabled]="isUploading" (click)="cancelUpload()">
                <span>{{'BUTTON.CANCEL' | translate}}</span>
            </button>
            <button type="submit" class="btn btn-primary" id="upload-chart" [disabled]="isUploading" (click)="upload()">
                <span>{{'HELM_CHART.UPLOAD' | translate}}</span>
                <span *ngIf="isUploading" class="spinner spinner-inline">
                    Loading...
                </span>
            </button>
        </div>
    </clr-modal>
</div>
