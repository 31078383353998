import { Injectable } from '@angular/core';
import {Observable, throwError as observableThrowError} from 'rxjs';
import { ScanApiRepository } from './scanAll.api.repository';
import { ErrorHandler } from '../../../utils/error-handler';
import {HttpClient} from "@angular/common/http";
import {ScanningMetrics} from "../config";
import {catchError, map} from "rxjs/operators";
import { CURRENT_BASE_HREF } from "../../../utils/utils";


@Injectable()
export class ScanAllRepoService {

    constructor(
        private http: HttpClient,
        private scanApiRepository: ScanApiRepository,
        private errorHandler: ErrorHandler) {
    }

    public manualScan(): Observable <any> {
        let param = {
            "schedule": {
                "type": "Manual"
            }
        };
        return this.scanApiRepository.postSchedule(param);
    }

    public getSchedule(): Observable <any> {
        return this.scanApiRepository.getSchedule();
    }

    public postSchedule(type, cron): Observable <any> {
        let param = {
            "schedule": {
                "type": type,
                "cron": cron,
            }
        };

        return this.scanApiRepository.postSchedule(param);
    }

    public putSchedule(type, cron): Observable <any> {
        let param = {
            "schedule": {
                "type": type,
                "cron": cron,
            }
        };

        return this.scanApiRepository.putSchedule(param);
    }
    getScheduleMetrics(): Observable<ScanningMetrics> {
        return this.http.get(CURRENT_BASE_HREF + '/scans/schedule/metrics')
            .pipe(catchError(error => observableThrowError(error)))
            .pipe(map(response => response as ScanningMetrics));
    }
    getManualMetrics(): Observable<ScanningMetrics> {
        return this.http.get(CURRENT_BASE_HREF + '/scans/all/metrics')
            .pipe(catchError(error => observableThrowError(error)))
            .pipe(map(response => response as ScanningMetrics));
    }
    getScanners(): Observable<any> {
        return this.http.get(CURRENT_BASE_HREF + '/scanners')
            .pipe(catchError(error => observableThrowError(error)));
    }
    getScannerMetadata(uid: string): Observable<any> {
        return this.http.get(`${ CURRENT_BASE_HREF }/scanners/${uid}/metadata`)
            .pipe(catchError(error => observableThrowError(error)));
    }
}
