<div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <h2 class="custom-h2 gc-title">{{'CONFIG.GC' | translate }}<span class="spinner spinner-inline ml-1 v-mid" [hidden]="!inProgress"></span></h2>
        <clr-tabs>
            <clr-tab *ngIf="hasAdminRole">
                <button id="config-gc" clrTabLink>{{'CONFIG.GC' | translate }}</button>
                <ng-template [(clrIfActive)]="gcActive">
                    <clr-tab-content id="gc" *ngIf="hasAdminRole">
                        <gc-config (loadingGcStatus)="getGcStatus($event)"></gc-config>
                    </clr-tab-content>
                </ng-template>
            </clr-tab>
            <clr-tab *ngIf="hasAdminRole">
                <button id="gc-log" clrTabLink>{{'CONFIG.HISTORY' | translate }}</button>
                <ng-template [(clrIfActive)]="historyActive">
                    <clr-tab-content id="history" *ngIf="hasAdminRole">
                        <gc-history></gc-history>
                    </clr-tab-content>
                </ng-template>
            </clr-tab>
        </clr-tabs>
    </div>
</div>
