<clr-modal [(clrModalOpen)]="opened" [clrModalStaticBackdrop]="true" [clrModalClosable]="false">
    <h3 class="modal-title">{{'USER.ADD_USER_TITLE' | translate}}</h3>
    <inline-alert class="modal-title" (confirmEvt)="confirmCancel($event)"></inline-alert>
    <div class="modal-body body-format">
        <new-user-form (valueChange)="formValueChange($event)"></new-user-form>
    </div>
    <div class="modal-footer">
        <span class="spinner spinner-inline loading-top" [hidden]="inProgress === false"> </span>
        <button type="button" class="btn btn-outline" (click)="close()">{{'BUTTON.CANCEL' | translate}}</button>
        <button type="button" id="save-button" class="btn btn-primary" [disabled]="!isValid || inProgress" (click)="create()">{{'BUTTON.OK' | translate}}</button>
    </div>
</clr-modal>
