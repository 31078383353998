/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Icon } from '../models/icon';
@Injectable({
  providedIn: 'root',
})
class IconService extends __BaseService {
  static readonly getIconPath = '/icons/{digest}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the artifact icon with the specified digest. As the original icon image is resized and encoded before returning, the parameter "digest" in the path doesn't match the hash of the returned content
   * @param params The `IconService.GetIconParams` containing the following parameters:
   *
   * - `digest`: The digest of the resource
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  getIconResponse(params: IconService.GetIconParams): __Observable<__StrictHttpResponse<Icon>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.XRequestId != null) __headers = __headers.set('X-Request-Id', params.XRequestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/icons/${params.digest}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Icon>;
      })
    );
  }
  /**
   * Get the artifact icon with the specified digest. As the original icon image is resized and encoded before returning, the parameter "digest" in the path doesn't match the hash of the returned content
   * @param params The `IconService.GetIconParams` containing the following parameters:
   *
   * - `digest`: The digest of the resource
   *
   * - `X-Request-Id`: An unique ID for the request
   *
   * @return Success
   */
  getIcon(params: IconService.GetIconParams): __Observable<Icon> {
    return this.getIconResponse(params).pipe(
      __map(_r => _r.body as Icon)
    );
  }
}

module IconService {

  /**
   * Parameters for getIcon
   */
  export interface GetIconParams {

    /**
     * The digest of the resource
     */
    digest: string;

    /**
     * An unique ID for the request
     */
    XRequestId?: string;
  }
}

export { IconService }
