<clr-modal [(clrModalOpen)]="addRuleOpened" [clrModalStaticBackdrop]="true" [clrModalClosable]="true" [clrModalSize]="'lg'">
    <h3 class="modal-title" *ngIf="isAdd">{{'IMMUTABLE_TAG.ADD_TITLE' | translate}}</h3>
    <h3 class="modal-title" *ngIf="!isAdd">{{'IMMUTABLE_TAG.EDIT_TITLE' | translate}}</h3>
    <div class="modal-body no-scrolling">
        <inline-alert class="modal-title"></inline-alert>
        <p class="color-97">{{'IMMUTABLE_TAG.ADD_SUBTITLE' | translate}}</p>
        <div class="height-72">
            <div class="clr-row mt-1">
                <div class="clr-col-4">
                    <span>{{'IMMUTABLE_TAG.IN_REPOSITORIES' | translate}}</span>
                </div>
                <div class="clr-col-3">
                    <div class="clr-select-wrapper w-100">
                        <select id="scope_selectors" [(ngModel)]="repoSelect" class="clr-select w-100">
                             <option *ngFor="let d of metadata?.scope_selectors[0]?.decorations"
                                      value="{{d}}">{{getI18nKey(d)|translate}}</option>
                        </select>
                    </div>
                </div>
                <div class="clr-col-5">
                    <div class="w-100">
                        <input required id="scope-input" [(ngModel)]="repositories" class="clr-input w-100">
                    </div>
                </div>
            </div>
            <div class="clr-row">
                <div class="clr-col-4"></div>
                <div class="clr-col-8">
                    <span>{{'IMMUTABLE_TAG.REP_SEPARATOR' | translate}}</span>
                </div>
            </div>
        </div>
        <div class="height-72">
            <div class="clr-row">
                <div class="clr-col-4">
                    <label>{{'IMMUTABLE_TAG.TAGS' | translate}}</label>
                </div>
                <div class="clr-col-3">
                    <div class="clr-select-wrapper w-100">
                        <select id="tag_selectors" [(ngModel)]="tagsSelect" class="clr-select w-100">
                            <option *ngFor="let d of metadata?.tag_selectors[0]?.decorations"
                                    value="{{d}}">{{getI18nKey(d)|translate}}</option>
                        </select>
                    </div>
                </div>
                <div class="clr-col-5">
                    <div class="w-100">
                        <input required id="tag-input" [(ngModel)]="tagsInput" class="clr-input w-100">
                    </div>
                </div>
            </div>
            <div class="clr-row">
                <div class="clr-col-4"></div>
                <div class="clr-col-8">
                    <span>{{'IMMUTABLE_TAG.TAG_SEPARATOR' | translate}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" id="close-btn" (click)="cancel()">{{'BUTTON.CANCEL' | translate}}</button>
        <button [disabled]="canNotAdd()" type="button" id="add-edit-btn" class="btn btn-primary" (click)="add()">
            <span *ngIf="isAdd">{{'BUTTON.ADD' | translate}}</span>
            <span *ngIf="!isAdd">{{'BUTTON.SAVE' | translate}}</span>
        </button>
    </div>
</clr-modal>