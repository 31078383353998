<clr-modal [(clrModalOpen)]="opened" [clrModalClosable]="false" [clrModalStaticBackdrop]="false">
    <div class="modal-body dialog-body">
        <div class="harbor-logo-black">
            <img [src]="'images/harbor-logo.svg'" class="harbor-icon">
        </div>
        <div class="content" tabindex="1">
            <div>{{customName?.name? customName?.name : ('APP_TITLE.HARBOR' | translate)}}</div>
            <div>
                <span class="p5 about-version">{{'ABOUT.VERSION' | translate}} {{version}}</span>
            </div>
            <div *ngIf="!customIntroduction">
                <p class="p5">{{'ABOUT.COPYRIGHT' | translate}}</p>
                <p class="p5">
                    <a href="/license" target="_blank">{{'ABOUT.OPEN_SOURCE_LICENSE' | translate}}</a>
                </p>
            </div>
            <div *ngIf="customIntroduction">
                <p class="p5">{{customIntroduction}}</p>
            </div>
        </div>
    </div>
    <div class="modal-footer margin-left-override">
        <button type="button" class="btn btn-primary" (click)="close()">{{'BUTTON.CLOSE' | translate}}</button>
    </div>
</clr-modal>