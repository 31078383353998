
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SERVICE_CONFIG, IServiceConfig } from "../../../entities/service.config";

export abstract class ScanApiRepository {
    abstract postSchedule(param): Observable<any>;

    abstract putSchedule(param): Observable<any>;

    abstract getSchedule(): Observable<any>;

}

@Injectable()
export class ScanApiDefaultRepository extends ScanApiRepository {
    constructor(
        private http: HttpClient,
        @Inject(SERVICE_CONFIG) private config: IServiceConfig
    ) {
        super();
    }

    public postSchedule(param): Observable<any> {
        return this.http.post(`${this.config.ScanAllEndpoint}/schedule`, param)
            .pipe(catchError(error => observableThrowError(error)));
    }

    public putSchedule(param): Observable<any> {
        return this.http.put(`${this.config.ScanAllEndpoint}/schedule`, param)
            .pipe(catchError(error => observableThrowError(error)));
    }

    public getSchedule(): Observable<any> {
        return this.http.get(`${this.config.ScanAllEndpoint}/schedule`)
            .pipe(catchError(error => observableThrowError(error)));
    }

}
