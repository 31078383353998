// Copyright Project Harbor Authors
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//    http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/*
{
  "user_id": 1,
  "username": "admin",
  "email": "",
  "password": "",
  "realname": "",
  "comment": "",
  "deleted": false,
  "role_name": "projectAdmin",
  "role_id": 1,
  "has_admin_role": false,
  "reset_uuid": "",
  "creation_time": "0001-01-01T00:00:00Z",
  "update_time": "0001-01-01T00:00:00Z"
}
*/

export class Member {
  id: number;
  project_id: number;
  entity_name: string;
  role_name: string;
  role_id: number;
  entity_id: number;
  entity_type: string;
}
