<div class="version-position">
    <div class="row flex-items-xs-between">
        <div class="col-xs-4">
            <div class="title-container">
                <div class="chart-name-span">
                    {{chartName | translate}}
                </div>
                <div>
                    {{roleName | translate}}
                </div>
            </div>
        </div>
    </div>

    <div class="row version-tool">
        <div class="toolbar">
            <div class="row flex-items-xs-right option-right rightPos">
                <div class="flex-xs-middle">
                    <hbr-filter [withDivider]="true" filterPlaceholder="{{'HELM_CHART.FILTER_FOR_CHARTS' | translate}}"
                    [currentValue]="lastFilteredVersionName"
                    (filterEvt)="updateFilterValue($event)"></hbr-filter>
                    <span class="card-btn" (click)="showCard(true)" (mouseenter)="mouseEnter('card') " (mouseleave)="mouseLeave('card')">
                        <clr-icon [ngClass]="{'is-highlight': isCardView || isHovering('card') }" shape="view-cards"></clr-icon>
                    </span>
                    <span class="list-btn" (click)="showCard(false)" (mouseenter)="mouseEnter('list') " (mouseleave)="mouseLeave('list')">
                        <clr-icon [ngClass]="{'is-highlight': !isCardView || isHovering('list') }" shape="view-list"></clr-icon>
                    </span>
                    <span class="filter-divider"></span>
                    <span class="refresh-btn" (click)="refresh()">
                        <clr-icon shape="refresh"></clr-icon>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div *ngIf="!isCardView" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <clr-datagrid (clrDgRefresh)="refresh()" [clrDgLoading]="loading" [(clrDgSelected)]="selectedRows">
                <clr-dg-action-bar>
                    <button type="button" class="btn  btn-secondary"
                        [disabled]="!(selectedRows.length===1) || !hasDownloadHelmChartVersionPermission"
                        (click)="versionDownload()">
                        <clr-icon shape="download" size="16"></clr-icon>&nbsp;{{'HELM_CHART.DOWNLOAD' | translate}}
                    </button>
                    <button type="button" class="btn  btn-secondary"
                        [disabled]="selectedRows.length<=0 || !hasDeleteHelmChartVersionPermission"
                        (click)="openVersionDeleteModal()">
                        <clr-icon shape="times" size="16"></clr-icon>&nbsp;{{'BUTTON.DELETE' | translate}}
                    </button>
                    <clr-dropdown>
                        <button type="button" class="btn  btn-secondary" clrDropdownTrigger
                            [disabled]="!(selectedRows.length===1)|| !hasAddRemoveHelmChartVersionPermission">
                            <clr-icon shape="plus" size="16"></clr-icon>{{'REPOSITORY.ADD_LABELS' | translate}}
                        </button>
                        <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                            <hbr-resource-label-marker
                                [labels]="labels"
                                [projectName]="projectName"
                                [resource]="selectedRows[0]"
                                [resourceType]="resourceType"
                                [addLabelHeaders]="addLabelHeaders"
                                (changeEvt)="onLabelChange(selectedRows[0])">
                            </hbr-resource-label-marker>
                        </clr-dropdown-menu>
                    </clr-dropdown>
                </clr-dg-action-bar>
                <clr-dg-column>{{'HELM_CHART.VERSION' | translate}}</clr-dg-column>
                <clr-dg-column>{{'HELM_CHART.STATUS' | translate }}</clr-dg-column>
                <clr-dg-column>{{'HELM_CHART.ENGINE' | translate }}</clr-dg-column>

                <clr-dg-column>{{'HELM_CHART.MAINTAINERS' | translate }}</clr-dg-column>
                <clr-dg-column>{{'HELM_CHART.CREATED' | translate }}</clr-dg-column>
                <clr-dg-column>
                    {{'REPOSITORY.LABELS' | translate}}
                    <clr-dg-filter [clrDgFilter]="labelFilter">
                        <hbr-chart-version-label-filter #labelFilter class="label-filter"
                        [labels]="labels"
                        [resourceType]="resourceType">
                        </hbr-chart-version-label-filter>
                    </clr-dg-filter>
                </clr-dg-column>
                <clr-dg-placeholder>{{'HELM_CHART.NO_VERSION_PLACEHOLDER' | translate }}</clr-dg-placeholder>
                <clr-dg-row *clrDgItems="let v of chartVersions" [clrDgItem]="v">
                    <clr-dg-cell>
                        <span class="list-img">
                            <img  [src]="v.icon ?v.icon:chartDefaultIcon"  (error)="getDefaultIcon(v);" />
                        </span>
                        <a href="javascript:void(0)" (click)="onVersionClick(v)">{{ v.version }}</a>
                    </clr-dg-cell>
                    <clr-dg-cell>{{ getStatusString(v) | translate }}</clr-dg-cell>
                    <clr-dg-cell>{{ v.engine }}</clr-dg-cell>
                    <clr-dg-cell>{{ getMaintainerString(v.maintainers) }}</clr-dg-cell>
                    <clr-dg-cell>{{ v.created | date}}</clr-dg-cell>
                    <clr-dg-cell>
                        <div>
                            <hbr-label-piece *ngIf="v.labels?.length" [label]="v.labels[0]" [labelWidth]="130"> </hbr-label-piece>
                            <hbr-resource-label-signpost *ngIf="v.labels?.length>1" [labels]="v.labels"></hbr-resource-label-signpost>
                        </div>
                    </clr-dg-cell>
                </clr-dg-row>
                <clr-dg-footer>
                    <clr-dg-pagination #pagination [clrDgPageSize]="pageSize" [clrDgTotalItems]="totalCount">
                        <span *ngIf="pagination.totalItems">
                            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} {{'HELM_CHART.OF' | translate}}
                        </span>
                        {{pagination.totalItems}} {{'HELM_CHART.ITEMS'| translate}}
                    </clr-dg-pagination>
                </clr-dg-footer>
            </clr-datagrid>
        </div>
    </div>
    <div *ngIf="isCardView" class="row card-container">
        <div *ngFor="let item of chartVersions;" class="col-lg-3 col-md-4 col-sm-6">
            <a let i=index; class="card clickable" (click)="onVersionClick(item)">
                <div class="card-header">
                    <div class="card-media-block">
                        <img  [src]="item.icon ?item.icon:chartDefaultIcon"  (error)="getDefaultIcon(item);" />
                        <div class="card-media-description">
                            <span class="card-media-title">{{item.name}}</span>
                            <a class="card-media-text">{{item.home}}</a>
                        </div>
                    </div>
                </div>
                <div class="card-block">
                    <div class="form-group">
                        <label class="card-label">{{'HELM_CHART.STATUS' | translate}}</label>
                        <div>{{getStatusString(item) | translate}}</div>
                    </div>
                    <div class="form-group">
                        <label class="card-label">{{'HELM_CHART.ENGINE' | translate}}</label>
                        <div>{{item.engine}}</div>
                    </div>
                    <div class="form-group">
                        <label class="card-label">{{'HELM_CHART.MAINTAINERS' | translate}}</label>
                        <div>{{getMaintainerString(item.maintainers)}}</div>
                    </div>
                    <div class="form-group">
                        <label class="card-label">{{'HELM_CHART.VERSION' | translate}}</label>
                        <div>{{item.version}}</div>
                    </div>
                </div>
                <div class="card-footer">
                    <clr-dropdown [clrCloseMenuOnItemClick]="false">
                        <button type="button" class="btn btn-link"
                        (click)="versionDownload($event, item)">{{'HELM_CHART.DOWNLOAD' | translate}}</button>
                        <button type="button" class="btn btn-link"
                        [disabled]="!hasDeleteHelmChartVersionPermission"
                        (click)="deleteVersionCard($event, item)">{{'BUTTON.DELETE' | translate}}</button>
                    </clr-dropdown>
                </div>
            </a>
        </div>
        <div *ngIf="loading" class="center-x">
            <span class="vertical-helper"></span>
            <span class="spinner"></span>
        </div>
    </div>
    <confirmation-dialog #confirmationDialog (confirmAction)="confirmDeletion($event)"></confirmation-dialog>
</div>
