<clr-datagrid (clrDgRefresh)="refresh($event)">
    <clr-dg-column>{{'REPOSITORY.NAME' | translate}}</clr-dg-column>
    <clr-dg-column>{{'REPOSITORY.ARTIFACTS_COUNT' | translate}}</clr-dg-column>
    <clr-dg-column>{{'REPOSITORY.PULL_COUNT' | translate}}</clr-dg-column>
    <clr-dg-row *clrDgItems="let r of repositories" [clrDgItem]='r'>
        <clr-dg-cell><a href="javascript:void(0)" (click)="gotoLink(projectId || r.project_id, r.name || r.repository_name)">{{r.name || r.repository_name}}</a></clr-dg-cell>
        <clr-dg-cell>{{r.artifact_count}}</clr-dg-cell>
        <clr-dg-cell>{{r.pull_count}}</clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
        <span *ngIf="pagination.totalItems">{{pagination.firstItem + 1}} - {{pagination.lastItem +1 }} {{'REPOSITORY.OF' | translate}} </span> {{pagination.totalItems }} {{'REPOSITORY.ITEMS' | translate}}
        <clr-dg-pagination #pagination [clrDgPageSize]="5"></clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>